import { FILTER_LINKS } from 'pages/widgets/MessagesWidget/consts';
import { settingsStore } from 'services/settings/SettingsStore';
import { MessagesFilter } from 'store/messageStore';

export const getStartOfDay = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
};

export const getEndOfDay = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setHours(23, 59, 59, 999);
  return date.toISOString();
};

export const buildDocumentQuery = (
  filter: MessagesFilter,
  linkedMessagesIdsFilter: string[] | null,
  inboxOutlookPath = 'INBOX',
  sentOutlookPath = 'Sent'
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filters: Record<string, any> = [
    {
      bool: {
        should: [{ term: { path: inboxOutlookPath } }, { term: { path: sentOutlookPath } }]
      }
    }
  ];

  const {
    showDone,
    recipientOrSender,
    emailSubject,
    emailBody,
    messageSentDate,
    withAttachment,
    links
  } = filter;

  if (!showDone) {
    filters.push({ bool: { must_not: [{ term: { flags: settingsStore.getFlags().DONE } }] } });
  }

  if (recipientOrSender) {
    if (recipientOrSender.includes('@') && recipientOrSender.includes('.')) {
      filters.push({
        bool: {
          should: [
            {
              term: {
                'to.address': recipientOrSender
              }
            },
            {
              term: {
                'from.address': recipientOrSender
              }
            }
          ]
        }
      });
    } else {
      filters.push({
        bool: {
          should: [
            {
              match_phrase: {
                'to.address': recipientOrSender
              }
            },
            {
              match_phrase: {
                'from.address': recipientOrSender
              }
            }
          ]
        }
      });
    }
  }

  if (emailSubject) {
    filters.push({ match_phrase: { subject: emailSubject } });
  }

  if (emailBody) {
    filters.push({ match_phrase: { 'text.plain': emailBody } });
  }

  if (messageSentDate?.[0] && messageSentDate?.[1]) {
    filters.push({
      range: {
        date: {
          gte: getStartOfDay(messageSentDate[0].format()),
          lte: getEndOfDay(messageSentDate[1].format())
        }
      }
    });
  }

  if (withAttachment) {
    filters.push({
      nested: {
        path: 'attachments',
        query: { bool: { must: [{ exists: { field: 'attachments.id' } }] } }
      }
    });
  }

  if (linkedMessagesIdsFilter?.length) {
    filters.push({ terms: { id: [...new Set(linkedMessagesIdsFilter)] } });
  }

  if (links) {
    if (filter.links === FILTER_LINKS.WITHOUT_LINKS) {
      filters.push({
        bool: {
          must_not: [{ term: { flags: settingsStore.getFlags().LINKED } }]
        }
      });
    }

    if (filter.links === FILTER_LINKS.WITH_LINKS) {
      filters.push({
        bool: {
          must: [{ term: { flags: settingsStore.getFlags().LINKED } }]
        }
      });
    }
  }

  return { documentQuery: { bool: { filter: filters } } };
};
