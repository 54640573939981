import React, { useMemo } from 'react';
import { RebateReportItem, useRebateReportStore } from 'store/rebateReportStore';
import { message, Table } from 'antd';
import { CONTROL_SIZE, MESSAGE_DURATION } from 'consts/common';
import { RebateReportInvoiceItem } from 'services/api/data-contracts';
import { getInvoiceItemColumns } from './getInvoiceItemColumns';
import { validateRebateItems } from './helpers/validateRebateItems';
import { unionBy } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInvoiceDrawerRouting } from 'routing';

interface IProps {
  items?: RebateReportInvoiceItem[];
  customerId?: number;
  customerName?: string;
  currency: string;
}

export const RebatesExpandableTable = ({
  items = [],
  customerId,
  customerName,
  currency
}: IProps) => {
  const { selectedItems, setSelectedItems, selectedRowKeys } = useRebateReportStore(
    ({ selectedItems, setSelectedItems }) => ({
      selectedItems,
      setSelectedItems,
      selectedRowKeys: selectedItems.map(({ id }) => id)
    })
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useInvoiceDrawerRouting();

  const dataSource = useMemo(
    () =>
      items.map(
        (item) =>
          ({
            ...item,
            key: item.id,
            currency,
            customerName,
            customerId
          }) as RebateReportItem
      ),
    [items]
  );
  const currentItemIds = useMemo(() => dataSource.map(({ id }) => id), [items]);

  const onSelectAll = (isSelected: boolean) => {
    if (!isSelected) {
      setSelectedItems(selectedItems.filter(({ id }) => !currentItemIds.includes(id)));
      return;
    }

    try {
      dataSource.forEach((item) => validateRebateItems(selectedItems, item));
      setSelectedItems(unionBy(selectedItems, dataSource, 'id'));
    } catch (error) {
      message.warning((error as Error).message, MESSAGE_DURATION);
    }
  };

  const onSelect = (currentItem: RebateReportItem, isSelected: boolean) => {
    if (!isSelected) {
      setSelectedItems(selectedItems.filter(({ id }) => id !== currentItem.id));
      return;
    }

    try {
      validateRebateItems(selectedItems, currentItem);
      setSelectedItems([...selectedItems, currentItem]);
    } catch (error) {
      message.warning((error as Error).message, MESSAGE_DURATION);
    }
  };

  const columns = useMemo(getInvoiceItemColumns, []);

  return (
    <Table
      key={`{customerId}-${currency}`}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      size={CONTROL_SIZE}
      rowSelection={{
        onSelect,
        onSelectAll,
        selectedRowKeys
      }}
      onRow={(record) => ({
        onClick: async (e) => {
          const isCheckbox = 'checked' in e.target;

          if (!isCheckbox) {
            navigate({
              pathname: `/report/rebate/invoice/${record.invoiceId}`,
              search: searchParams.toString()
            });
          }
        }
      })}
    />
  );
};
