import { MainReportFormat } from 'services/api/data-contracts';
import { InvoiceExportType } from '../../InvoiceExportPopover';
import { MIME_Type } from 'pages/consts';

export const getInvoiceReportAcceptHeader = (
  invoiceExportType: InvoiceExportType,
  mainReportFormat: MainReportFormat
) => {
  if (invoiceExportType === InvoiceExportType.Pdf) {
    return MIME_Type.Pdf;
  }

  switch (mainReportFormat) {
    case MainReportFormat.Docx:
      return MIME_Type.Docx;
    case MainReportFormat.Xlsx:
      return MIME_Type.Xlsx;
  }
};

const EXT_TO_MIME_TYPE_MAPPING = {
  jpeg: MIME_Type.Jpeg,
  jpg: MIME_Type.Jpeg,
  gif: MIME_Type.Gif,
  png: MIME_Type.Png,
  docx: MIME_Type.Docx,
  pdf: MIME_Type.Pdf,
  xlsx: MIME_Type.Xlsx
};

export type FileExtension = keyof typeof EXT_TO_MIME_TYPE_MAPPING;

export const getAcceptHeader = (fileExtension: FileExtension) => {
  return EXT_TO_MIME_TYPE_MAPPING[fileExtension];
};
