import React from 'react';
import { Checkbox, Flex, Row, Skeleton } from 'antd';
import styled from 'styled-components';
import { ArrowIcon } from './components/ArrowIcon/ArrowIcon';
import { Tag } from 'antd';
import { SubjectPreviewLine } from './components/SubjectPreviewLine/SubjectPreviewLine';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { RightOutlined } from '@ant-design/icons';
import { MessageView } from './components/MessageView/MessageView';
import { MessageListEntry } from 'services/emailEngineApi/data-contracts';
import { MessageType } from './consts';
import { ActionsDropdown } from './components/ActionsDropdown/ActionsDropdown';
import { useHoverMessage } from './helpers/useHoverMessage';
import { CONTROL_SIZE, EMAIL_LIST_DATE_TIME_FORMAT } from 'consts/common';
import { LABELS } from 'queries/messages/consts';
import { getUTCDate } from 'helpers';
import { settingsStore } from 'services/settings/SettingsStore';

interface SenderWrapper {
  $unseen: boolean;
}

interface DetailsWrapperProps {
  $isExpanded: boolean;
}

const SenderWrapper = styled('div')<SenderWrapper>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-basis: 140px;
  flex-grow: 0;
  font-weight: ${(props) => (props.$unseen ? '600' : 'inherit')};
`;

const DetailsWrapper = styled(Row)<DetailsWrapperProps>`
  width: 100%;
  max-height: ${({ $isExpanded }) => ($isExpanded ? 'fit-content' : 0)};
  transform: ${({ $isExpanded }) => ($isExpanded ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  transition: transform 0.2s ease-out;
  overflow: hidden;
  margin-bottom: ${({ $isExpanded }) => ($isExpanded ? '-8px' : 'auto')};
`;

const StyledFlex = styled(Flex)`
  position: relative;
`;

interface IProps {
  message: MessageListEntry;
  isExpanded: boolean;
  onExpand: (id?: string) => void;
  onSelect: (e: CheckboxChangeEvent) => void;
  selectedMessages: MessageListEntry[];
}

export const MessagesListItem = ({
  message,
  isExpanded,
  onExpand,
  selectedMessages,
  onSelect
}: IProps) => {
  const { isHovered, handleMouseEnter, handleMouseLeave, isDropdownOpen, handleClick } =
    useHoverMessage();

  const handleClickRow = (e: React.MouseEvent<HTMLDivElement>) => {
    const isCheckbox = 'checked' in e.target;
    const isDropdown = Boolean((e.target as Element).closest('.ant-dropdown-trigger'));

    if (!isCheckbox && !isDropdown) {
      onExpand(isExpanded ? undefined : message.id);
    }
  };

  const { id, from, to, date: createdAt, subject, text, attachments } = message;
  const senderEmail = from?.address;
  const firstReceiverEmail = to?.[0].address;

  const envelope = {
    id: id as string,
    subject: subject ?? '',
    messageText: text?.id ?? '',
    messagePreview: message.preview,
    attachments,
    unseen: message.unseen ?? false,
    flags: message.flags ?? []
  };

  const isChecked = !!selectedMessages.filter((message) => message.id === id).length;

  const messageFolder =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    message.labels?.includes(LABELS.INBOX) || (message as any).messageSpecialUse === LABELS.INBOX
      ? MessageType.INCOMING
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message.labels?.includes(LABELS.SENT) || (message as any).messageSpecialUse === LABELS.SENT
        ? MessageType.OUTGOING
        : null;

  if (!message.id) {
    return (
      <div data-testid="message-loader" style={{ width: '100%' }}>
        <Flex>
          <Skeleton.Input size={CONTROL_SIZE} style={{ width: 20, margin: '0 8px' }} />
          <Skeleton.Input size={CONTROL_SIZE} style={{ width: 200, marginRight: '8px' }} />
          <Skeleton.Input size={CONTROL_SIZE} style={{ width: 20 }} />
        </Flex>
      </div>
    );
  }

  return (
    <>
      <StyledFlex
        justify="space-between"
        gap={8}
        onClick={handleClickRow}
        style={{ padding: '0 12px', width: '100%', height: '24px' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-testid={message.id}>
        <Flex gap={8} align="center">
          <Checkbox
            value={id}
            checked={isChecked}
            onChange={onSelect}
            id={`message-${id}`}
            style={{ marginRight: '10px' }}
          />

          <ArrowIcon type={messageFolder} />

          <Tag color="processing">Mail</Tag>
        </Flex>

        <SenderWrapper $unseen={message.unseen ?? false}>
          {messageFolder === MessageType.INCOMING ? senderEmail : firstReceiverEmail}
        </SenderWrapper>

        <SubjectPreviewLine envelope={envelope} />

        <div>
          {isHovered ? (
            <Flex align="center" justify="end">
              <ActionsDropdown
                isOpen={isDropdownOpen}
                onOpenChange={handleClick}
                id={message.id}
                isMessageSeen={!message.unseen}
                isMessageDone={!!message.flags?.includes(settingsStore.getFlags().DONE)}
                flags={message.flags}
              />

              <RightOutlined
                style={{
                  verticalAlign: 'middle',
                  transform: `rotate(${isExpanded ? 90 : 0}deg)`,
                  transition: '0.2s ease-in-out all',
                  fontSize: '12px'
                }}
              />
            </Flex>
          ) : (
            <span style={{ color: 'rgba(0, 0, 0, 0.45)', whiteSpace: 'nowrap' }}>
              {getUTCDate(createdAt).format(EMAIL_LIST_DATE_TIME_FORMAT)}
            </span>
          )}
        </div>
      </StyledFlex>

      <DetailsWrapper $isExpanded={isExpanded} key={message.id}>
        {isExpanded && <MessageView subject={subject ?? ''} id={message.id} />}
      </DetailsWrapper>
    </>
  );
};
