import React from 'react';
import { Skeleton } from 'antd';
import { DropdownItemKeys } from '../consts';
import { CONTROL_SIZE } from 'consts/common';
import { CustomTag } from '../../../helpers/getColoredTags';
import { LinkAction } from '../../../helpers/useLinkAction';
import { AccountInfo } from 'store/messageStore';
import { Type } from 'services/emailEngineApi/data-contracts';

export const getDropdownItems = (
  linkAction: LinkAction,
  isMessageSeen: boolean,
  isMessageDone: boolean,
  accountInfo?: AccountInfo,
  coloredTags?: CustomTag[]
) => {
  const renderLoader = () => (
    <Skeleton.Input size={CONTROL_SIZE} style={{ width: 20, height: 22 }} />
  );

  const getLinkActionTitle = () => {
    switch (linkAction) {
      case 'unlink-selected':
        return 'Unlink selected';
      case 'unlink-all':
        return 'Unlink all';
      default:
        return 'Link selected';
    }
  };

  return [
    linkAction && {
      label: linkAction === 'loading' ? renderLoader() : getLinkActionTitle(),
      key: DropdownItemKeys.Link,
      disabled: linkAction === 'disabled'
    },
    {
      label: `Mark as ${isMessageSeen ? 'unread' : 'read'}`,
      key: DropdownItemKeys.Read
    },
    {
      label: `Move to ${isMessageDone ? 'Undone' : 'Done'}`,
      key: DropdownItemKeys.Done
    },
    accountInfo?.type === Type.Imap
      ? {
          label: 'Label or unlabel as...',
          key: DropdownItemKeys.LabelAs,
          children: coloredTags
        }
      : null
  ];
};
