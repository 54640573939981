import React, { useMemo } from 'react';
import { ConfigProvider, Empty, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { invoiceStatuses } from 'consts/invoice';
import { InvoiceStatus } from 'services/api/data-contracts';

interface IProps {
  onChange: (status: string[]) => void;
  values?: {
    invoiceStatuses: string[];
    isPosted?: boolean;
  };
}

export const InvoiceStatusMultipleInput = ({ onChange, values }: IProps) => {
  const options = useMemo(
    () =>
      [...invoiceStatuses.filter((status) => status !== InvoiceStatus.CNLD), ...['POSTED']].map(
        (status) => ({
          value: status,
          label: status
        })
      ),
    [invoiceStatuses]
  );

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Select
        size={CONTROL_SIZE}
        placeholder="Select statuses"
        style={{ width: '100%' }}
        options={options}
        optionFilterProp="label"
        mode="multiple"
        maxTagCount="responsive"
        listHeight={200}
        value={[
          ...(values?.invoiceStatuses ? values?.invoiceStatuses : []),
          ...(values?.isPosted ? ['POSTED'] : [])
        ]}
        onChange={onChange}
        allowClear
      />
    </ConfigProvider>
  );
};
