/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BulkProgramOrderServices,
  Error,
  InvoicingTerm,
  Order,
  OrderStatus,
  Program,
  ProgramById,
  ProgramCloneInput,
  ProgramInput,
  TotalSales,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Programs<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Create a new Program record
   *
   * @tags programs
   * @name AddProgram
   * @summary Create a new Program record
   * @request POST:/programs
   * @secure
   */
  addProgram = (data: ProgramInput, params: RequestParams = {}) =>
    this.http.request<Program, ValidationError | Error>({
      path: `/programs`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get Programs
   *
   * @tags programs
   * @name GetPrograms
   * @summary Get Programs
   * @request GET:/programs
   * @secure
   */
  getPrograms = (
    query?: {
      /**
       * The limit on number of items to return
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      limit?: number;
      /**
       * Filter by a list of customer ids separated by comma.
       * @example "1,2,3"
       */
      customerIds?: string;
      /**
       * Filter by a list of payer ids separated by comma.
       * @example "1,2,3"
       */
      payerIds?: string;
      /**
       * Filter by a list of location ids separated by comma.
       * @example "city_12,airport_17,country_70"
       */
      locationIds?: string;
      /**
       * Filter by a list of supplier ids separated by comma.
       * @example "1,2,3"
       */
      supplierIds?: string;
      /**
       * Filter by a list of provider ids separated by comma.
       * @example "1,2,3"
       */
      providerIds?: string;
      /** Filter by a list of orderNumbers separated by comma. Example - orderNumbers=51,12,22 etc. */
      orderNumbers?: string;
      /**
       * Filter by a list of program statuses separated by comma.
       * @example "ESTD, DONE"
       */
      programStatuses?: string;
      /**
       * Filter by a list of aircraft ids separated by comma.
       * @example "1,2,3"
       */
      aircraftIds?: string;
      /** Filter by startDatetime range, format as 'start,end'. Example - startDatetime=2023-10-26, 2023-10-27 */
      startDatetime?: string;
      /** Filter by endDatetime range, format as 'start,end'. Example - endDatetime=2023-10-26, 2023-10-27 */
      endDatetime?: string;
      /** Filter by a list of order types separated by comma. Example - types=GH, ADD */
      types?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        items?: (Program & {
          mainOrder?: Order;
          totalSales?: TotalSales;
        })[];
      },
      Error
    >({
      path: `/programs`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new Program record
   *
   * @tags programs
   * @name CloneProgram
   * @summary Make orders and flights clone for Program
   * @request POST:/programs/clone
   * @secure
   */
  cloneProgram = (data: ProgramCloneInput, params: RequestParams = {}) =>
    this.http.request<
      {
        createdOrderIds: number[];
      },
      ValidationError | Error
    >({
      path: `/programs/clone`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific Program record by id
   *
   * @tags programs
   * @name GetProgramById
   * @summary Get a specific Program record
   * @request GET:/programs/{programId}
   * @secure
   */
  getProgramById = (programId: number, params: RequestParams = {}) =>
    this.http.request<ProgramById, Error>({
      path: `/programs/${programId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Partial update Program entity
   *
   * @tags programs
   * @name UpdateProgram
   * @summary Update Program entity
   * @request PATCH:/programs/{programId}
   * @secure
   */
  updateProgram = (
    programId: number,
    data: {
      status?: OrderStatus;
      notes?: string | null;
      invoicingTerm?: InvoicingTerm;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        updatedOrdersCount?: number;
      },
      ValidationError | Error
    >({
      path: `/programs/${programId}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Bulk update program orderServices
   *
   * @tags programs
   * @name BulkUpdateOrderService
   * @summary Bulk update program orderServices
   * @request PATCH:/programs/{programId}/bulk/order-services
   * @secure
   */
  bulkUpdateOrderService = (
    programId: number,
    data: BulkProgramOrderServices,
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        updatedOrderServiceIdsCount?: number;
      },
      ValidationError | Error
    >({
      path: `/programs/${programId}/bulk/order-services`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
}
