import React, { useContext, useMemo } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { Button, message } from 'antd';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { DashboardContext, DashboardType } from '../../../../consts';
import { useMessageStore } from 'store/messageStore';
import { LinkActionTypes, LinkedEntityTypes } from 'services/api/data-contracts';
import { getSourceLinks } from '../ActionsDropdown/helpers/getSourceLinks';
import { map } from 'lodash';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { settingsStore } from 'services/settings/SettingsStore';

export const LinkButton = () => {
  const { type: dashboardType } = useContext(DashboardContext);

  const { selectedMessages, setSelectedMessages } = useMessageStore(
    ({ selectedMessages, setSelectedMessages }) => ({
      selectedMessages,
      setSelectedMessages
    })
  );
  const { selectedFlights } = useFlightsDashboardStore(({ selectedFlights }) => ({
    selectedFlights
  }));
  const { selectedDispatcherOrders } = useOrdersDashboardStore(({ selectedOrders }) => ({
    selectedDispatcherOrders: selectedOrders
  }));
  const { selectedSettlementOrderNumbers } = useSettlementDashboardStore(({ selectedOrders }) => ({
    selectedSettlementOrderNumbers: selectedOrders
  }));

  const handleCloseProgress = () => {
    setSelectedMessages([]);
    message.destroy('link-in-process');
  };

  const linkMutation = useUpdateMessageLinks({
    isMessagesInBulkFlagsUpdate: true,
    onSuccess: handleCloseProgress
  });

  const isSomethingSelectedForDispatcher = useMemo(
    () =>
      dashboardType === DashboardType.Dispatcher &&
      [...selectedFlights, ...selectedDispatcherOrders].length > 0,
    [dashboardType, selectedDispatcherOrders, selectedFlights]
  );
  const isSomethingSelectedForSettlement = useMemo(
    () => dashboardType === DashboardType.Settlement && selectedSettlementOrderNumbers.length > 0,
    [dashboardType, selectedSettlementOrderNumbers]
  );
  const isSomethingSelected = isSomethingSelectedForDispatcher || isSomethingSelectedForSettlement;

  const areAllSelectedMessagesLinked = useMemo(
    () =>
      selectedMessages.every((message) => message.flags?.includes(settingsStore.getFlags().LINKED)),
    [selectedMessages]
  );

  const handleLinkSelected = async () => {
    message.loading({ content: 'Linking selected messages...', key: 'link-in-process' });

    const selectedFlightIds = map(selectedFlights, 'id');
    const selectedOrderNumbers =
      dashboardType === DashboardType.Dispatcher
        ? map(selectedDispatcherOrders, 'number')
        : selectedSettlementOrderNumbers;

    await linkMutation.mutateAsync({
      action: LinkActionTypes.Link,
      source: getSourceLinks({
        flightIds: selectedFlightIds,
        orderNumbers: selectedOrderNumbers
      }),
      target: selectedMessages.map(({ id }) => ({
        entityType: LinkedEntityTypes.Message,
        entityId: id
      }))
    });
  };

  const handleUnlinkAll = async () => {
    message.loading({ content: 'Unlinking selected messages...', key: 'link-in-process' });

    await linkMutation.mutateAsync({
      action: LinkActionTypes.UnlinkAll,
      target: selectedMessages.map(({ id }) => ({
        entityType: LinkedEntityTypes.Message,
        entityId: id
      }))
    });
  };

  return (
    <Button
      size={CONTROL_SIZE}
      type="dashed"
      disabled={!isSomethingSelected && !areAllSelectedMessagesLinked}
      onClick={isSomethingSelected ? handleLinkSelected : handleUnlinkAll}
      loading={linkMutation.isLoading}>
      {isSomethingSelected ? 'Link selected' : 'Unlink all'}
    </Button>
  );
};
