import { create } from 'zustand';
import {
  InvoiceItemHydrated,
  OrderService,
  OrderType,
  Payment,
  SettlementInvoice,
  SettlementOrder
} from 'services/api/data-contracts';
import { devtools } from 'zustand/middleware';
import { orderBy } from 'lodash';
import { PAGE_SIZE_35 } from 'consts/common';

export enum RowState {
  SELECTED = 'selected',
  INDETERMINATE = 'indeterminate',
  EMPTY = 'empty'
}

interface ServiceRow {
  id: number;
  state: RowState;
}

export interface OrderRow {
  number: string;
  state: RowState;
  services: ServiceRow[];
}

export interface InvoiceFilter {
  invoiceNumbers?: string;
  invoiceStatuses?: string[];
  isPosted?: boolean;
}

export enum SettlementWidgetMode {
  Programs = 'Programs',
  Orders = 'Orders'
}

export type SettlementTableCreditNote = Payment & {
  totalByOrderService: number;
};

export interface InvoicesAndCreditNotesRow {
  selectedInvoices?: SettlementInvoice[];
  selectedCreditNotes?: SettlementTableCreditNote[];
  selectedRebates?: SettlementTableCreditNote[];
}

export const settlementViews = Object.values(SettlementWidgetMode);

type SettlementDashboardStore = {
  list: SettlementOrder[];
  setList: (response: { items: SettlementOrder[]; total: number }, page: number) => void;
  addToList: (items: SettlementOrder[], page: number) => void;
  updateService: (
    service: OrderService & {
      additionalServiceOrderId?: number;
      fuelOrderId?: number;
      groundHandlingOrderId?: number;
      permitOrderId?: number;
    }
  ) => void;
  updateInvoiceItem: (
    updatedInvoiceItems: InvoiceItemHydrated[],
    orderNumber: string,
    serviceId: number
  ) => void;
  total: number;

  /* upper level of Settlement Widget - order rows */
  orderRowsState: OrderRow[];
  setOrderRowsState: (orderRowsState: OrderRow[]) => void;

  /* nested level of Settlement Widget - invoice, credit note, rebate rows */
  billingsLevelRowsState: InvoicesAndCreditNotesRow;
  setBillingsLevelRowsState: (orderRowsState: InvoicesAndCreditNotesRow) => void;

  selectedOrders: string[];
  clearOrderRowsState: () => void;
  indeterminateOrders: string[];
  selectedServicesIds: number[];
  page: number;
  setPage: (page: number) => void;

  /* filter settlement dashboard by invoice number in main user filters popup - SettlementFilters */
  invoiceFilter: InvoiceFilter;
  setInvoiceFilter: (invoiceFilter: InvoiceFilter) => void;

  /* current tab for Settlement Widget */
  widgetMode: SettlementWidgetMode;
  setWidgetMode: (widgetMode?: SettlementWidgetMode) => void;
};

const getRowsInitialState = (orders: SettlementOrder[]) =>
  orders.map((order) => ({
    number: order.number,
    state: RowState.EMPTY,
    services: order.orderServices.map((service) => ({
      id: service?.id,
      state: RowState.EMPTY
    }))
  }));

export const useSettlementDashboardStore = create<SettlementDashboardStore>()(
  devtools((set, getState) => ({
    list: [],
    total: 0,
    setList: ({ items = [], total }, page) => {
      set({
        list: items.map((order) => ({
          ...order,
          totalSales: orderBy(order.totalSales, 'sale', 'desc')
        })),
        total,
        orderRowsState: page > 1 ? getState().orderRowsState : getRowsInitialState(items)
      });
    },
    addToList: (items, page) => {
      const list = [...getState().list.slice(0, page * PAGE_SIZE_35), ...items];
      const orderRowsState = [...getState().orderRowsState, ...getRowsInitialState(items)];

      set({
        list: list.map((order) => ({
          ...order,
          totalSales: orderBy(order.totalSales, 'sale', 'desc')
        })),
        orderRowsState
      });
    },
    updateService: (updatedService) => {
      const updatedList = getState().list;

      const order = updatedList.find((order) => {
        if (updatedService.additionalServiceOrderId) {
          return (
            order.id === updatedService.additionalServiceOrderId && order.type === OrderType.ADD
          );
        } else if (updatedService.groundHandlingOrderId) {
          return order.id === updatedService.groundHandlingOrderId && order.type === OrderType.GH;
        } else if (updatedService.fuelOrderId) {
          return order.id === updatedService.fuelOrderId && order.type === OrderType.FUEL;
        } else if (updatedService.permitOrderId) {
          return order.id === updatedService.permitOrderId && order.type === OrderType.PMT;
        }
      });

      if (!order) return;

      order.orderServices = order.orderServices.map((service) => {
        if (service.id === updatedService.id) {
          return { ...service, ...updatedService };
        }

        return service;
      });

      set({ list: updatedList });
    },
    updateInvoiceItem: (updatedInvoiceItems, orderNumber, serviceId) => {
      const updatedList = getState().list;

      const order = updatedList.find((order) => order.number === orderNumber);
      if (!order) return;

      order.orderServices.forEach((service) => {
        if (service.id === serviceId) {
          (service.invoices || []).forEach((invoice) => {
            invoice.invoiceItems = invoice.invoiceItems.map((item) => {
              if (item.id === updatedInvoiceItems[0].id) {
                return { ...item, ...updatedInvoiceItems[0] };
              }
              return item;
            });
          });
        }
      });

      set({ list: updatedList });
    },
    orderRowsState: [],
    setOrderRowsState: (orderRowsState: OrderRow[]) => {
      set({
        orderRowsState,
        selectedOrders: orderRowsState
          .filter((row) => row.state === RowState.SELECTED)
          .map((row) => row.number),
        indeterminateOrders: orderRowsState
          .filter((row) => row.state === RowState.INDETERMINATE)
          .map((row) => row.number),
        selectedServicesIds: orderRowsState
          .filter((row) => row.state === RowState.INDETERMINATE || row.state === RowState.SELECTED)
          .flatMap((row) => row.services)
          .filter((service) => service.state === RowState.SELECTED)
          .map((service) => service.id)
      });
    },
    clearOrderRowsState: () => {
      set({
        orderRowsState: getRowsInitialState(getState().list),
        selectedOrders: [],
        indeterminateOrders: [],
        selectedServicesIds: []
      });
    },
    selectedOrders: [],
    indeterminateOrders: [],
    selectedServicesIds: [],
    page: 1,
    setPage: (page) => {
      set({ page });
    },
    invoiceFilter: {},
    setInvoiceFilter: (invoiceFilter) => {
      set({ invoiceFilter });
    },
    widgetMode: SettlementWidgetMode.Orders,
    setWidgetMode: (widgetMode) => {
      set({ widgetMode });
    },
    billingsLevelRowsState: {},
    setBillingsLevelRowsState: (billingsLevelRowsState) => {
      set({ billingsLevelRowsState });
    }
  }))
);
