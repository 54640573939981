import React, { useState } from 'react';
import { Popconfirm } from 'antd';

interface IProps {
  isWarning?: boolean;
  onSubmit: () => Promise<void>;
  title: string;
}

export const CanceledConfirm = ({ isWarning, onSubmit, title }: IProps) => {
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const handleOpenChange = async (newOpen: boolean) => {
    if (!newOpen) {
      setConfirmOpen(newOpen);
      return;
    }

    if (isWarning) {
      setConfirmOpen(newOpen);
    } else {
      await onSubmit();
    }
  };

  const confirmTitle = (
    <span>
      Cancelled Invoice can&apos;t be changed and won&apos;t
      <br />
      be included in Sales and Balance calculations.
      <br />
      Are you sure to continue?
    </span>
  );

  return (
    <Popconfirm
      title={confirmTitle}
      onConfirm={onSubmit}
      onCancel={() => setConfirmOpen(false)}
      onOpenChange={handleOpenChange}
      open={isConfirmOpen}>
      <span>{title}</span>
    </Popconfirm>
  );
};
