import React from 'react';
import { TagOutlined } from '@ant-design/icons';
import { gray } from '@ant-design/colors';

export const FLAG = {
  SEEN: '\\Seen'
};

export enum MessageType {
  INCOMING = 'Incoming',
  OUTGOING = 'Outgoing'
}

export const FILTER_LABEL = {
  WITHOUT_LABELS: 'Without label',
  ALL_LABELS: 'All labels'
};

export const FILTER = {
  WITHOUT_LABELS: {
    key: 'without',
    label: 'Without label',
    color: gray[7],
    icon: <TagOutlined style={{ color: gray[7] }} />
  },
  ALL_LABELS: {
    key: 'all',
    label: 'All labels',
    color: gray[11],
    icon: <TagOutlined style={{ color: gray[11] }} />
  }
};

export const FILTER_LINKS = {
  WITH_LINKS: 'With links',
  WITHOUT_LINKS: 'Without links',
  ALL: 'Links presence: All'
};

export const FILTER_LINKS_OPTIONS = [
  FILTER_LINKS.WITH_LINKS,
  FILTER_LINKS.WITHOUT_LINKS,
  FILTER_LINKS.ALL
];

export const CONTENT_HEIGHT_FOR_REFETCH = 400;

export const SIGNATURE_DELIMITER = '---';

export const ATTACHMENT_NOTIFICATION = {
  IS_LOADING: 'Downloading of attachment in process...',
  IS_ERROR: 'Error in attachment download.',
  IS_LOADING_MULTIPLE_ATTACHMENTS: 'Downloading of attachments in process...',
  IS_ERROR_MULTIPLE_ATTACHMENTS: 'Error in attachments download.'
};

export const FILE_SIZE_LIMIT = 25 * 1024 * 1024;
