import React from 'react';
import { Links } from '../../../components/Links/Links';
import { Spin, Tabs } from 'antd';
import { InvoiceDetails } from './InvoiceDetails';
import styled from 'styled-components';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useGetInvoice } from 'queries/invoice';
import { useInvoiceStore } from 'store/invoiceStore';
import { LinkedEntityTypes } from 'services/api/data-contracts';
import { TabsProps } from 'antd/es/tabs';
import { CONTROL_SIZE } from 'consts/common';
import { DocumentDetails } from './DocumentDetails/DocumentsDetails';
import { DocumentsErrorBoundary } from 'pages/widgets/components/DocumentsDrawer/DocumentsErrorBoundary';

const SpinWrapper = styled('div')`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinksWrapper = styled('div')`
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
  margin: -12px -8px 8px;
  width: calc(100% + 16px);
  padding: 0 8px 4px;
`;

interface IProps {
  isLoading?: boolean;
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDrawerContent = ({ isLoading, setAddServiceDrawerOpen }: IProps) => {
  const { current, isInvoiceEditMode } = useInvoiceStore(({ current, isInvoiceEditMode }) => ({
    current,
    isInvoiceEditMode
  }));

  const { isFetching } = useGetInvoice(current?.id);
  const linksQuery = useGetLinks({ invoiceIds: current?.id?.toString() }, !!current?.id);

  const hasLinks = (linksQuery.data?.data.messages || []).length > 0;

  const detailsTab = {
    key: '1',
    label: 'Details',
    children:
      isLoading || isFetching ? (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      ) : (
        <InvoiceDetails setAddServiceDrawerOpen={setAddServiceDrawerOpen} />
      )
  };

  const documentsTab = !isInvoiceEditMode
    ? {
        key: '2',
        label: 'Documents',
        children: (
          <DocumentsErrorBoundary>
            <DocumentDetails />
          </DocumentsErrorBoundary>
        )
      }
    : null;

  const tabs: TabsProps['items'] = [detailsTab].concat(documentsTab ? [documentsTab] : []);

  if (isInvoiceEditMode) {
    return (
      <>
        {isLoading || isFetching ? (
          <SpinWrapper>
            <Spin />
          </SpinWrapper>
        ) : (
          <InvoiceDetails setAddServiceDrawerOpen={setAddServiceDrawerOpen} />
        )}
      </>
    );
  } else {
    return (
      <>
        {hasLinks && (
          <LinksWrapper>
            <Links
              targetEntityType={LinkedEntityTypes.Invoice}
              targetEntityId={current?.id}
              isLoading={linksQuery.isFetching}
              linkedMessages={linksQuery.data?.data.messages}
            />
          </LinksWrapper>
        )}
        <Tabs defaultActiveKey="1" items={tabs} style={{ marginBottom: 20 }} size={CONTROL_SIZE} />
      </>
    );
  }
};
