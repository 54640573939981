import React, { useContext, useEffect, useState } from 'react';
import {
  InvoiceItemBaseInput,
  InvoiceItemHydrated,
  InvoiceType,
  RebateType
} from 'services/api/data-contracts';
import { getInvoiceItemsColumns } from './columns';
import { Button, Empty, Spin, Table, Typography } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useInvoiceStore } from 'store/invoiceStore';
import { EditOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';
import { getAmountValue } from 'helpers';
import { useDrawerStore } from 'store/drawerStore';
import { useHighlightRow } from '../useHilightRow';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useUpdateInvoiceItems } from 'queries/invoiceItems';
import { DashboardContext } from 'pages/consts';
import { SpinWrapper, TableWrapper } from './InvoiceItemsView';

interface IProps {
  invoiceId: number;
  invoiceItems: InvoiceItemHydrated[];
  onSetEditMode: (isEditMode: boolean) => void;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<Readonly<RowProps>> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

type InvoiceItemsSortedByTables = (InvoiceItemHydrated & { key: number })[][];

export const SortableIssuedInvoiceItemsView = ({
  invoiceId,
  invoiceItems,
  onSetEditMode
}: IProps) => {
  const [dataSource, setDataSource] = useState<InvoiceItemsSortedByTables>();
  const [hasInvoiceItemsOrderChanged, setInvoiceItemsOrderChanged] = useState(false);

  const { isIssuedInvoice, setCurrentInvoiceItem } = useInvoiceStore(
    ({ current, setCurrentInvoiceItem }) => ({
      isIssuedInvoice: current?.type === InvoiceType.Issued,
      setCurrentInvoiceItem
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const highlightedId = useHighlightRow();

  const handleOpenItem = (invoiceItem: InvoiceItemHydrated) => {
    setCurrentInvoiceItem(invoiceItem);
    setDrawerOpen('calculator');
  };

  const columns = getInvoiceItemsColumns(handleOpenItem, isIssuedInvoice).filter(
    (col) => col.key !== 'outName'
  );

  useEffect(() => {
    const ownPriceInvoiceItems = invoiceItems.filter((item) => !!item.isOwnPrice);
    const agentFeeInvoiceItems = groupBy(
      invoiceItems.filter((item) => !item.isOwnPrice),
      (item) =>
        item.rebate?.type === RebateType.AgentFeeExtra && item.rebate.value
          ? `${item.agentFee}_${item.rebate.type}_${item.rebate.value}`
          : `${item.agentFee}`
    );

    const tables = [ownPriceInvoiceItems, ...Object.values(agentFeeInvoiceItems)]
      .filter((tableData) => tableData.length > 0)
      .map((subArray) => subArray.map((item) => ({ ...item, key: item.id })));

    setDataSource(tables);
  }, [invoiceItems]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1
      }
    })
  );

  const { type: dashboardType } = useContext(DashboardContext);

  const updateMutation = useUpdateInvoiceItems({ invoiceId, dashboardType });

  const handleSubmitItemsNewOrder = () => {
    let order = 0;
    const invoiceItems = dataSource?.flatMap(
      (table: Partial<InvoiceItemHydrated & { key: number }>[]) =>
        table.map((item: Partial<InvoiceItemHydrated & { key: number }>) => ({
          ...item,
          invoiceId,
          sortOrder: order++
        }))
    );

    updateMutation.mutate({
      id: invoiceId as number,
      data: invoiceItems as InvoiceItemBaseInput[]
    });
  };

  const onDragEnd =
    (tableIndex: number) =>
    ({ active, over }: DragEndEvent) => {
      if (dataSource && active.id !== over?.id) {
        // sets local sorted inside each table view
        setDataSource((state) => {
          const prevState = state as InvoiceItemsSortedByTables;
          const activeIndex = prevState[tableIndex].findIndex((i) => i.key === active.id);
          const overIndex = prevState[tableIndex].findIndex((i) => i.key === over?.id);

          prevState[tableIndex] = arrayMove(prevState[tableIndex], activeIndex, overIndex);

          return [...prevState];
        });

        setInvoiceItemsOrderChanged(true);
      }
    };

  if (!invoiceItems.length) {
    return (
      <Empty
        description="Invoice items have not been added yet"
        image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <Button size={CONTROL_SIZE} onClick={() => onSetEditMode(true)}>
          Add Invoice items
        </Button>
      </Empty>
    );
  }

  if (!dataSource) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  return (
    <>
      {dataSource?.map((tableData, idx) => {
        console.log('table data', tableData);
        /* 
          Each tableData is an array containing items filtered and grouped to this array
          by isOwnPrice or agent fee value.
          For example, tableData where all items with isOwnPrice: true
          or tableData where all items has agentFee as 10%
          */
        const firstInvoiceItemInGroup = tableData[0];
        const isOwnPrice = firstInvoiceItemInGroup.isOwnPrice;
        const agentFee = firstInvoiceItemInGroup.agentFee || 0;
        const isFirstTable = idx === 0;
        const isLastTable = idx === dataSource.length - 1;
        const totalAgentFee = tableData.reduce((acc, curr) => acc + (curr.agentFeeAmount || 0), 0);
        const rebateType = firstInvoiceItemInGroup.rebate?.type;
        const rebateValue = firstInvoiceItemInGroup.rebate?.value;

        /*
          Depending on tableData items set spesific title to table
          */

        let title = `${agentFee}% agent's fee`;

        if (rebateType === RebateType.AgentFeeExtra && rebateValue) {
          title = `${agentFee - rebateValue}% agent's fee + ${rebateValue}% rebate`;
        }

        return (
          <TableWrapper key={idx}>
            {!isOwnPrice && (
              <Typography.Title
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '22px',
                  marginTop: 24
                }}>
                {title}
              </Typography.Title>
            )}
            <DndContext
              sensors={sensors}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={onDragEnd(idx)}>
              <SortableContext
                items={tableData.map((i) => i.key)}
                strategy={verticalListSortingStrategy}>
                <Table
                  columns={columns}
                  dataSource={tableData}
                  components={{
                    body: { row: Row }
                  }}
                  rowKey="key"
                  size={CONTROL_SIZE}
                  pagination={false}
                  data-testid="invoice-items-view"
                  showHeader={isFirstTable}
                  rowClassName={(record) => (record.id === highlightedId ? 'highlighted' : '')}
                  summary={() => (
                    <Table.Summary fixed="bottom">
                      {!isOwnPrice && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} />
                          <Table.Summary.Cell index={1} colSpan={2}>
                            <span style={{ padding: '0 4px' }}>Agent fee</span>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell colSpan={7} index={0} align="right">
                            {getAmountValue(totalAgentFee)}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                      {isLastTable && (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={5} index={0}>
                            <Button
                              data-testid="edit-invoice-items-btn"
                              onClick={() => onSetEditMode(true)}
                              type="link"
                              size={CONTROL_SIZE}
                              icon={<EditOutlined />}>
                              Edit invoice items
                            </Button>
                          </Table.Summary.Cell>
                          {hasInvoiceItemsOrderChanged && (
                            <Table.Summary.Cell colSpan={6} index={1} align="end">
                              <Button
                                data-testid="save-order-invoice-items-btn"
                                size={CONTROL_SIZE}
                                type="primary"
                                onClick={handleSubmitItemsNewOrder}
                                loading={updateMutation.isLoading}>
                                Save items order
                              </Button>
                            </Table.Summary.Cell>
                          )}
                        </Table.Summary.Row>
                      )}
                    </Table.Summary>
                  )}
                />
              </SortableContext>
            </DndContext>
          </TableWrapper>
        );
      })}
    </>
  );
};
