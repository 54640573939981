import React, { useContext, useEffect, useState } from 'react';
import {
  InvoiceItemBaseInput,
  InvoiceItemHydrated,
  InvoiceType
} from 'services/api/data-contracts';
import { getInvoiceItemsColumns } from './columns';
import { Button, Empty, Spin, Table } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useInvoiceStore } from 'store/invoiceStore';
import { EditOutlined } from '@ant-design/icons';
import { useDrawerStore } from 'store/drawerStore';
import { useHighlightRow } from '../useHilightRow';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useUpdateInvoiceItems } from 'queries/invoiceItems';
import { DashboardContext } from 'pages/consts';
import { SpinWrapper, TableWrapper } from './InvoiceItemsView';

interface IProps {
  invoiceId: number;
  invoiceItems: InvoiceItemHydrated[];
  onSetEditMode: (isEditMode: boolean) => void;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<Readonly<RowProps>> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

type InvoiceItemsWithSortingKey = (InvoiceItemHydrated & { key: number })[];

export const SortableReceivedInvoiceItemsView = ({
  invoiceId,
  invoiceItems,
  onSetEditMode
}: IProps) => {
  const [dataSource, setDataSource] = useState<InvoiceItemsWithSortingKey>();
  const [hasInvoiceItemsOrderChanged, setInvoiceItemsOrderChanged] = useState(false);
  console.log('dataSource', dataSource);

  const { isIssuedInvoice, setCurrentInvoiceItem } = useInvoiceStore(
    ({ current, setCurrentInvoiceItem }) => ({
      isIssuedInvoice: current?.type === InvoiceType.Issued,
      setCurrentInvoiceItem
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  console.log('InvoiceItemsView');

  const highlightedId = useHighlightRow();

  const handleOpenItem = (invoiceItem: InvoiceItemHydrated) => {
    setCurrentInvoiceItem(invoiceItem);
    setDrawerOpen('calculator');
  };

  const columns = getInvoiceItemsColumns(handleOpenItem, isIssuedInvoice).filter((col) => col.key);

  useEffect(() => {
    setDataSource(invoiceItems.map((item) => ({ ...item, key: item.id })));
  }, [invoiceItems, isIssuedInvoice]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1
      }
    })
  );

  const { type: dashboardType } = useContext(DashboardContext);

  const updateMutation = useUpdateInvoiceItems({ invoiceId, dashboardType });

  const handleSubmitItemsNewOrder = () => {
    const invoiceItems = dataSource?.map((item, idx) => ({
      ...item,
      invoiceId,
      sortOrder: idx
    }));

    updateMutation.mutate({
      id: invoiceId as number,
      data: invoiceItems as InvoiceItemBaseInput[]
    });
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((state) => {
        const prevState = state as InvoiceItemsWithSortingKey;
        const activeIndex = prevState.findIndex((i) => i.key === active.id);
        const overIndex = prevState.findIndex((i) => i.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });

      setInvoiceItemsOrderChanged(true);
    }
  };

  if (!invoiceItems.length) {
    return (
      <Empty
        description="Invoice items have not been added yet"
        image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <Button size={CONTROL_SIZE} onClick={() => onSetEditMode(true)}>
          Add Invoice items
        </Button>
      </Empty>
    );
  }

  if (!dataSource) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  return (
    <TableWrapper>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource?.map((i) => i.key)}
          strategy={verticalListSortingStrategy}>
          <Table
            columns={columns}
            dataSource={dataSource}
            components={{
              body: { row: Row }
            }}
            size={CONTROL_SIZE}
            pagination={false}
            data-testid="invoice-items-view"
            rowClassName={(record) => (record.id === highlightedId ? 'highlighted' : '')}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={5} index={0}>
                    <Button
                      data-testid="edit-invoice-items-btn"
                      onClick={() => onSetEditMode(true)}
                      type="link"
                      size={CONTROL_SIZE}
                      icon={<EditOutlined />}>
                      Edit invoice items
                    </Button>
                  </Table.Summary.Cell>
                  {hasInvoiceItemsOrderChanged && (
                    <Table.Summary.Cell colSpan={6} index={1} align="end">
                      <Button
                        data-testid="save-order-invoice-items-btn"
                        size={CONTROL_SIZE}
                        type="primary"
                        onClick={handleSubmitItemsNewOrder}
                        loading={updateMutation.isLoading}>
                        Save items order
                      </Button>
                    </Table.Summary.Cell>
                  )}
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </SortableContext>
      </DndContext>
    </TableWrapper>
  );
};
