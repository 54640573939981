import React from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  CustomerStatus,
  OrderService,
  PaymentType,
  SupplierStatus
} from 'services/api/data-contracts';
import { Tooltip } from 'antd';
import { CustomerStatusTag, SupplierStatusTag, PaymentTypeIcon } from '../OrderServiceDrawer';
import { getAmountValue } from 'helpers';

export type OrderServiceColumns = OrderService & {
  isAvailableToLink: boolean;
  aircraftTailNumber: string;
};

export const getColumns = (): ColumnsType<OrderServiceColumns> => [
  {
    title: 'CusSt',
    dataIndex: 'customerStatus',
    key: 'customerStatus',
    width: 60,
    render: (value) => value && <CustomerStatusTag status={value as CustomerStatus} />
  },
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
    ellipsis: true,
    width: 140,
    render: (value) => (
      <Tooltip mouseEnterDelay={1} title={value?.name}>
        {value?.name}
      </Tooltip>
    )
  },
  {
    title: 'Order N',
    dataIndex: 'order_number',
    key: 'order_number',
    ellipsis: true,
    width: 80,
    render: (_, record) => (
      <Tooltip mouseEnterDelay={1} title={record.order.number}>
        {record.order.number}
      </Tooltip>
    )
  },
  {
    title: 'Aircraft',
    dataIndex: 'aircraftTailNumber',
    key: 'aircraftTailNumber',
    ellipsis: true,
    width: 80,
    render: (value) => (
      <Tooltip mouseEnterDelay={1} title={value}>
        {value}
      </Tooltip>
    )
  },
  {
    title: 'Sale',
    dataIndex: 'salesAmount',
    key: 'salesAmount',
    ellipsis: true,
    width: 86,
    render: (_, record) =>
      record.salesAmount && record.salesCurrency
        ? getAmountValue(record.salesAmount, 2, record.salesCurrency)
        : '-'
  },
  {
    title: 'Cost',
    dataIndex: 'costInSalesCurrency',
    key: 'costInSalesCurrency',
    ellipsis: true,
    width: 86,
    render: (_, record) => {
      const value =
        record.costInSalesCurrency && record.salesCurrency
          ? record.costInSalesCurrency
          : record.costAmount;

      if (!value) {
        return '-';
      }

      return getAmountValue(value, 2, record.salesCurrency || record.costCurrency);
    }
  },
  {
    title: 'Profit',
    dataIndex: 'profit',
    key: 'profit',
    ellipsis: true,
    width: 86,
    render: (_, record) =>
      record.profit ? getAmountValue(record.profit, 2, record.salesCurrency || undefined) : '-'
  },
  {
    title: 'SupSt',
    dataIndex: 'supplierStatus',
    key: 'supplierStatus',
    width: 60,
    render: (value) => value && <SupplierStatusTag status={value as SupplierStatus} />
  },
  {
    title: '$',
    dataIndex: 'paymentType',
    key: 'paymentType',
    width: 32,
    render: (value) => <PaymentTypeIcon paymentType={value as PaymentType} />,
    align: 'center'
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: 70,
    render: (value) => (
      <Tooltip mouseEnterDelay={1} title={value}>
        <span
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block',
            width: '54px'
          }}>
          {value}
        </span>
      </Tooltip>
    )
  }
];
