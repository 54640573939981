import { useMutation, useQueryClient } from '@tanstack/react-query';
import { messagesApi } from 'services/emailEngineApi';
import { MessageUpdate, Type } from 'services/emailEngineApi/data-contracts';
import { settingsStore } from 'services/settings/SettingsStore';
import { useMessageStore } from 'store/messageStore';
import { MESSAGES_UI_UPDATE_DELAY, MESSAGES_QUERY_KEY } from './consts';
import { message } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';

/**
 * @description should be used for bulk update of messages flags when linking (linksApi) is not required
 * makes an API request to search messages by range of uids and if messages found update their flags
 * @param {string} uid uids can be specified as range: '10:15' or sorted/unsorted list: '12,10,13,14,15'
 * `id` used for all other operations with messages, its value encodes `UID`, `UIDValidity` and `path` values
 * current hook will work only if combination of MAILBOX_FOLDER_PATH and uid is correct and matches relevant `id`
 * 
 * example: "path": "[Gmail]/All Mail", "uid": 618,
            "id": "AAAABQAAAmo" is encoded value of "uid": 618, `UIDValidity` and "path": "[Gmail]/All Mail"
            
 */

const updateItem = async (uid: string, update: MessageUpdate, path?: string) =>
  await messagesApi.putV1AccountAccountMessages(
    settingsStore.getMailboxSettings()?.mailbox_name || '',
    { path },
    { search: { uid }, update }
  );

export const useUpdateMessagesFlagsInBulk = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const mailboxConfig = settingsStore.getMailboxSettings();

  const {
    list,
    messagesFilter,
    linkedMessagesIdsFilter,
    allMailGmailPath,
    inboxOutlookPath,
    sentOutlookPath,
    accountInfo
  } = useMessageStore(
    ({
      list,
      messagesFilter,
      linkedMessagesIdsFilter,
      allMailGmailPath,
      inboxOutlookPath,
      sentOutlookPath,
      accountInfo
    }) => ({
      list,
      messagesFilter,
      linkedMessagesIdsFilter,
      allMailGmailPath,
      inboxOutlookPath,
      sentOutlookPath,
      accountInfo
    })
  );

  const path =
    accountInfo?.type === Type.Outlook ? [inboxOutlookPath, sentOutlookPath] : [allMailGmailPath];

  return useMutation({
    mutationFn: async (data: { messageIds: string[]; update: MessageUpdate }) => {
      const { messageIds, update } = data;

      const uids = messageIds
        .map((messageId) => {
          const message = list.find((message) => message.id === messageId);
          if (!message) {
            console.error(`No message found with id ${messageId}`);
          } else {
            return message.uid;
          }
        })
        .join(',');

      return await Promise.all(path.map((singlePath) => updateItem(uids, update, singlePath)));
    },
    onSuccess: async (response) => {
      if (!response) {
        message.error('Failed to update messages flags.', MESSAGE_DURATION);
      }

      if (response[0].data) {
        setTimeout(async () => {
          await queryClient.invalidateQueries({
            queryKey: [MESSAGES_QUERY_KEY, messagesFilter, mailboxConfig, linkedMessagesIdsFilter]
          });

          onSuccess && onSuccess();
        }, MESSAGES_UI_UPDATE_DELAY);
      }
    }
  });
};
