import React, { useState } from 'react';
import { paymentsApi } from 'services/api';
import {
  MainReportFormat,
  PaymentTemplatesListResponse,
  TransactionType
} from 'services/api/data-contracts';
import { useGetPaymentTemplates } from 'queries/payment';
import { ExportByTemplatePopover } from '../../ExportByTemplatePopover/ExportByTemplatePopover';
import { useMessageStore } from 'store/messageStore';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { BalanceReportFormat } from 'pages/widgets/BalanceWidget/components/ExportPopover';
import { InvoiceExportType } from 'pages/widgets/InvoicesWidget/components/InvoiceDetails/InvoiceExportPopover';
import { ExportFormat } from '../../DocumentsDrawer/HeaderButtons';
import { getFileExtension } from 'pages/widgets/MessagesWidget/components/AttachmentsPreview/utils';
import { MIME_Type } from 'pages/consts';

interface IProps {
  paymentId: number;
  isLoading: boolean;
  transactionType?: TransactionType;
}

export const ExportPopover = ({ isLoading, paymentId, transactionType }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [format, setFormat] = useState<BalanceReportFormat | ExportFormat | InvoiceExportType>(
    BalanceReportFormat.Pdf
  );
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);
  const [templateName, setTemplateName] = useState<string | undefined>(undefined);

  const { setNewMessageMode, setCurrentMessage } = useMessageStore();

  const templatesQueryResult = useGetPaymentTemplates(transactionType, isOpen);

  const fetchReport = async () => {
    const mainReportFormat = getFileExtension(templateName as string) as MainReportFormat;
    const response = await paymentsApi.generatePaymentReport(
      paymentId,
      {
        templateId: templateId as string,
        isPdf: format === ExportFormat.Pdf,
        mainReportFormat
      },
      {
        format: 'arraybuffer',
        headers: {
          Accept: format === ExportFormat.Pdf ? MIME_Type.Pdf : MIME_Type.Docx
        }
      }
    );

    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });
    const contentDisposition = response.headers['content-disposition'] || '';
    const match = contentDisposition.match(/filename="(.+)"/);
    const filename = match ? match[1] : 'report.pdf';

    return { blob, filename, contentType };
  };

  const handleAttachToMessage = (filename: string, contentType: string) => {
    setNewMessageMode('withPaymentAttachment');
    setCurrentMessage({
      subject: `Attached ${filename}`,
      attachments: [{ filename, contentType }]
    });
  };

  return (
    <ExportByTemplatePopover
      entityName={transactionType === TransactionType.CreditNote ? 'credit note' : 'payment'}
      templatesQueryResult={
        templatesQueryResult as UseQueryResult<
          AxiosResponse<PaymentTemplatesListResponse, unknown>,
          Error
        >
      }
      isOpen={isOpen}
      onSetOpen={setOpen}
      templateId={templateId}
      onSetTemplateId={setTemplateId}
      onSetTemplateName={setTemplateName}
      format={format}
      onSetFormat={setFormat}
      isEntityLoading={isLoading}
      onFetchReport={fetchReport}
      onAttachToMessage={handleAttachToMessage}
    />
  );
};
