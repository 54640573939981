import React, { useContext, useMemo, useState } from 'react';
import { OrderService } from 'services/api/data-contracts';
import { Button, Drawer, Flex, Form, Space } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useOrderStore } from 'store/orderStore';
import { useCreateOrderService, useUpdateOrderService } from 'queries/orderServices';
import styled from 'styled-components';
import { OrderServiceForm } from './OrderServiceForm';
import { DashboardContext, DashboardType } from '../../../consts';
import { ProfileOutlined } from '@ant-design/icons';
import { CancelButtonWithConfirm } from 'components/Drawer';
import { useCloseOnEsc, useCtrlEnterShortcut } from 'helpers';
import { useMessageStore } from 'store/messageStore';
import { useDrawerStore } from 'store/drawerStore';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 8px;
  }

  .ant-drawer-body {
    padding: 24px 8px 8px;
  }
`;

interface IProps {
  isFullScreenMode?: boolean;
}

export const OrderServiceDrawer = ({ isFullScreenMode }: IProps) => {
  const [form] = Form.useForm<OrderService>();
  const customerStatus = Form.useWatch('customerStatus', form);

  const [isTouched, setTouched] = useState(form.isFieldsTouched());

  const { type: dashboardType } = useContext(DashboardContext);

  const { service, setService } = useOrderStore(({ service, setService }) => ({
    service,
    setService
  }));
  const { newMessageMode } = useMessageStore(({ newMessageMode }) => ({ newMessageMode }));
  const { isServiceDrawerOpen, setDrawerClosed } = useDrawerStore(
    ({ setDrawerClosed, openDrawers }) => ({
      isServiceDrawerOpen: openDrawers.includes('orderService'),
      setDrawerClosed
    })
  );

  const orderType = service && 'order' in service ? service.order?.type : service?.orderType;
  const orderId = service && 'order' in service ? service.order?.id : service?.orderId;

  const handleCancel = () => {
    form.resetFields();
    setService(undefined);
    setDrawerClosed('orderService');
  };

  useCtrlEnterShortcut(() => handleSubmit(), !newMessageMode);
  useCloseOnEsc(handleCancel, !isTouched);

  const createMutation = useCreateOrderService(orderType, orderId, handleCancel);
  const updateMutation = useUpdateOrderService(dashboardType, handleCancel);

  const handleSubmit = () =>
    form
      .validateFields()
      .then(() => {
        const data = form.getFieldsValue();

        if (!service?.id) {
          createMutation.mutate({ ...service, ...data });
        } else {
          updateMutation.mutate({ ...service, ...data });
        }
      })
      .catch((err) => err);

  const isLoading = createMutation.isLoading || updateMutation.isLoading;
  const isRenderInContainer = !isFullScreenMode && dashboardType !== DashboardType.Rebate;

  const headerButtons = useMemo(
    () => (
      <Space>
        <CancelButtonWithConfirm
          onConfirm={handleCancel}
          isLoading={isLoading}
          isFormTouched={isTouched}
        />

        <Button size={CONTROL_SIZE} type="primary" onClick={handleSubmit} loading={isLoading}>
          {service?.id ? 'Save' : 'Create' + ' and close'}
        </Button>
      </Space>
    ),
    [service, isLoading, customerStatus, isTouched]
  );

  return (
    <StyledDrawer
      title={
        <Flex gap={8}>
          <ProfileOutlined style={{ color: '#00000073' }} />
          Service {service?.id ? 'editing' : 'creation'}
        </Flex>
      }
      open={isServiceDrawerOpen}
      closable={false}
      width={480}
      extra={headerButtons}
      {...(isRenderInContainer ? { getContainer: false } : {})}>
      <OrderServiceForm form={form} isTouched={isTouched} setTouched={setTouched} />
    </StyledDrawer>
  );
};
