import React from 'react';
import { Order, OrderStatus, OrderType, Program, TotalSales } from 'services/api/data-contracts';
import { ColumnsType } from 'antd/es/table';
import { Flex, Tooltip } from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'consts/common';
import { getUTCDate } from 'helpers';
import styled from 'styled-components';
import { OrderStatusTag } from 'pages/widgets/DispatcherOrdersWidget/components/OrderStatusTag';

interface DecoratedText {
  $isCancelled: boolean;
}

const DecoratedText = styled('span')<DecoratedText>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: ${(props) => (props.$isCancelled ? 'line-through' : 'none')};
  color: ${(props) => (props.$isCancelled ? '#00000073' : 'inherit')};
`;

export const getDispatcherColumns = (): ColumnsType<
  Program & {
    mainOrder?: Order;
    totalSales?: TotalSales;
  }
> => [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    render: (value) => <OrderStatusTag status={value} />,
    width: 52
  },
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 35,
    render: (_, record) => (
      <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
        {`${record.mainOrder?.type[0]}p`}
      </DecoratedText>
    ),
    align: 'center'
  },
  {
    title: 'N',
    dataIndex: 'flightNumbers',
    key: 'flightNumbers',
    render: (_, record) => {
      if (!record.mainOrder) return '-';

      const allFlights =
        record.mainOrder.type === OrderType.PMT
          ? record.mainOrder.flights || []
          : [record.mainOrder.arrivalFlight, record.mainOrder.departureFlight];

      return allFlights
        .map((flight) => flight?.flightNumber)
        .filter(Boolean)
        .map((flightNumber, index) => (
          <DecoratedText
            key={index}
            $isCancelled={record.status === OrderStatus.CNLD}
            style={{ display: 'block' }}>
            {flightNumber}
          </DecoratedText>
        ));
    },
    ellipsis: true
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (_, record) => {
      const location = record.mainOrder?.location;

      if (!location) return '-';

      return (
        <Tooltip mouseEnterDelay={1} title={location.searchName}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {'code' in location ? location.code : location.name}
          </DecoratedText>
        </Tooltip>
      );
    },
    ellipsis: true
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (_, record) => {
      const customer = record.mainOrder?.customer;

      if (!customer) return '-';

      return (
        <Tooltip mouseEnterDelay={1} title={customer.name}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {customer.name}
          </DecoratedText>
        </Tooltip>
      );
    },
    ellipsis: true
  },
  {
    title: 'Period',
    dataIndex: 'period',
    key: 'period',
    width: 190,
    render: (_, record) => {
      const startDate = getUTCDate(record.startDatetime);
      const endDate = getUTCDate(record.endDatetime);

      const dateRange = startDate.format(DATE_FORMAT) + ' - ' + endDate.format(DATE_FORMAT);
      const timeRange = startDate.format(TIME_FORMAT) + ' - ' + endDate.format(TIME_FORMAT);

      return (
        <Flex vertical>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {dateRange}
          </DecoratedText>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {timeRange}
          </DecoratedText>
        </Flex>
      );
    }
  }
];
