import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { invoicesApi } from 'services/api';
import { Invoice } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';
import { SETTLEMENT_WIDGET_QUERY_KEY } from 'queries/settlement/consts';
import { MESSAGE_DURATION } from 'consts/common';
import { BALANCE_WIDGET_QUERY_KEY } from 'queries/balance/consts';
import { useBalanceReportStore } from 'store/balanceReportStore';
import { REBATE_REPORT_QUERY_KEY } from 'queries/rebate/consts';
import { useRebateReportStore } from 'store/rebateReportStore';

const updateItem = async (data: Invoice) => await invoicesApi.updateInvoice(data.id, data);

export const useUpdateInvoice = (
  onSuccess?: () => void,
  isSettlementDashboard = false,
  isBalanceDashboard = false,
  isRebateDashboard = false
) => {
  const { setCurrent } = useInvoiceStore(({ setCurrent }) => ({ setCurrent }));
  const { balanceFilter } = useBalanceReportStore(({ filter }) => ({
    balanceFilter: filter
  }));
  const { rebateFilter } = useRebateReportStore(({ filter }) => ({
    rebateFilter: filter
  }));

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Invoice) => await updateItem(data),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        setCurrent(response.data);

        message.success(
          `${ENTITY_NAME} "${response.data.invoiceNumber}" has been successfully updated`,
          MESSAGE_DURATION
        );

        isSettlementDashboard && (await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]));
        isBalanceDashboard &&
          (await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY, balanceFilter]));
        isRebateDashboard &&
          (await queryClient.refetchQueries([REBATE_REPORT_QUERY_KEY, rebateFilter]));
        onSuccess && onSuccess();
      }
    }
  });
};
