import { Drawer } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardContext, DashboardType } from '../../../../consts';
import { useDrawerStore } from 'store/drawerStore';
import { DetailsErrorBoundary } from 'components/ErrorBoundary/DetailsErrorBoundary';
import { ProgramDrawerContent } from './ProgramDrawerContent/ProgramDrawerContent';
import { ProgramDrawerTitle } from './ProgramDrawerTitle';
import { HeaderButtons } from './HeaderButtons/HeaderButtons';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    padding: 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

export const ProgramDrawer = () => {
  const { entityId } = useParams();

  const { type: dashboardType } = useContext(DashboardContext);

  const { isProgramDrawerOpen } = useDrawerStore(({ openDrawers }) => ({
    isProgramDrawerOpen: openDrawers.includes('flightProgram')
  }));

  const isRenderInContainer =
    dashboardType === DashboardType.Dispatcher || dashboardType === DashboardType.Settlement;

  return (
    <StyledDrawer
      open={isProgramDrawerOpen}
      title={<ProgramDrawerTitle />}
      closable={false}
      width="100%"
      className="flight-program-drawer"
      extra={<HeaderButtons />}
      {...(isRenderInContainer ? { getContainer: false } : {})}>
      <DetailsErrorBoundary
        message="Please check the provided URL or select the flight program in widget"
        key={entityId}>
        <ProgramDrawerContent />
      </DetailsErrorBoundary>
    </StyledDrawer>
  );
};
