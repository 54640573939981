import React, { useState } from 'react';
import { ConfigProvider, Empty, List } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import VirtualList from 'rc-virtual-list';
import { SettlementOrdersListItem } from './SettlementOrderListItem';
import { useGetSettlementWidget } from 'queries/settlement';
import { blue } from '@ant-design/colors';
import { OrderService } from 'services/api/data-contracts';
import { useDrawerStore } from 'store/drawerStore';
import { useOrderStore } from 'store/orderStore';
import { StyledList } from 'pages/widgets/DispatcherOrdersWidget/DispatcherOrdersWidget';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { SCROLL_DISCREPANCY_THRESHOLD } from 'pages/consts';
import { useDashboardStore } from 'store/dashboardStore';
import { useMessageStore } from 'store/messageStore';
import { map } from 'lodash';

interface IProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  containerHeight: number;
  isFullScreenMode: boolean;
}

export const OrdersView = ({ footer, header, containerHeight, isFullScreenMode }: IProps) => {
  const [expandedOrderId, setExpandedOrderId] = useState<string | undefined>(undefined);

  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({
    setDrawerOpen
  }));

  const { setService } = useOrderStore(({ setService }) => ({
    setService
  }));

  const { list, selectedOrders, page, setPage, total } = useSettlementDashboardStore(
    ({ list, selectedOrders, page, setPage, total }) => ({
      list,
      selectedOrders,
      page,
      setPage,
      total
    })
  );
  const { linkFilters } = useDashboardStore(({ linkFilters }) => ({ linkFilters }));
  const { messageIds } = useMessageStore(({ selectedMessages }) => ({
    messageIds: map(selectedMessages, 'id')
  }));
  const isFilterByMessagesActive = linkFilters.includes('message-order') && messageIds.length;

  const handleOpenServiceDetails = (service: OrderService) => {
    setService(service);
    setDrawerOpen('orderService');
  };

  const { isFetching } = useGetSettlementWidget();

  const handleScroll = ({
    currentTarget: { scrollHeight, scrollTop }
  }: React.UIEvent<HTMLElement, UIEvent>) => {
    const isOnBottom =
      Math.abs(scrollHeight - scrollTop - containerHeight) < SCROLL_DISCREPANCY_THRESHOLD;
    const allDataLoaded = list.length >= total;

    if (isOnBottom && !allDataLoaded && !isFetching) {
      setPage(page + 1);
    }
  };

  if (!list.length) {
    return (
      <ConfigProvider
        renderEmpty={() => (
          <Empty description="Orders not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}>
        <StyledList
          dataSource={list}
          size={CONTROL_SIZE}
          header={header}
          footer={footer}
          bordered
          loading={isFetching}
          data-testid="list"
        />
      </ConfigProvider>
    );
  }

  return (
    <StyledList
      size={CONTROL_SIZE}
      bordered
      header={header}
      footer={footer}
      loading={!list.length || isFetching}>
      <VirtualList
        data={list}
        height={containerHeight}
        itemHeight={82}
        itemKey="number"
        onScroll={handleScroll}>
        {(order) => (
          <List.Item
            key={order.number}
            style={{
              backgroundColor: selectedOrders.find((number) => number === order.number)
                ? blue[0]
                : isFilterByMessagesActive && order.messageId
                  ? '#d9f7be'
                  : 'inherit',
              padding: 0
            }}>
            <SettlementOrdersListItem
              order={order}
              isExpanded={expandedOrderId === order.number}
              onExpand={setExpandedOrderId}
              onOpenService={handleOpenServiceDetails}
              isFullScreenMode={isFullScreenMode}
            />
          </List.Item>
        )}
      </VirtualList>
    </StyledList>
  );
};
