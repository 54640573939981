import React, { useEffect, useState } from 'react';
import { Grid, Flex } from 'antd';
import { DashboardContext, DashboardType } from '../consts';
import { SettlementFilters } from './filters/SettlementFilters';
import { MessagesWidget } from '../widgets/MessagesWidget/MessagesWidget';
import { SettlementOrdersWidget } from '../widgets/SettlementOrdersWidget/SettlementOrdersWidget';
import { settingsStore } from 'services/settings/SettingsStore';
import { AddNewButton } from './AddNewButton';
import { useDrawerStore } from 'store/drawerStore';
import { useNavigate } from 'react-router-dom';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { SettlementOrdersWidgetErrorBoundary } from './SettlementOrdersWidgetErrorBoundary';
import { useGetMailAccountInfo } from 'queries/messages/useGetMailAccountInfo';

const dashboardType = {
  type: DashboardType.Settlement
};

const { useBreakpoint } = Grid;

export const SettlementDashboard = () => {
  useGetMailAccountInfo();

  const [isFullScreenMode, setFullScreenMode] = useState(false);

  const { closeAllDrawers, isNoDrawersOpen } = useDrawerStore(
    ({ closeAllDrawers, openDrawers }) => ({
      closeAllDrawers,
      isNoDrawersOpen: openDrawers.length === 0
    })
  );
  const { setOpenOrder } = useOrdersDashboardStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (isNoDrawersOpen) {
      navigate(`/dashboard/settlement${window.location.search}`);
    }
  }, [isNoDrawersOpen]);

  useEffect(
    () => () => {
      closeAllDrawers();

      setOpenOrder(undefined);
    },
    []
  );

  useEffect(() => {
    settingsStore.setCurrentDashboard(DashboardType.Settlement);
  }, []);

  const screens = useBreakpoint();

  return (
    <DashboardContext.Provider value={dashboardType}>
      <SettlementFilters />

      <Flex style={{ height: 'calc(100vh - 128px)' }} gap={screens.xxl ? 20 : 12}>
        {!isFullScreenMode && (
          <div
            style={{
              flexBasis: 576,
              minWidth: 576,
              flexGrow: 1.3,
              overflow: 'hidden',
              position: 'relative',
              borderRadius: '8px'
            }}>
            <MessagesWidget />
          </div>
        )}

        <div
          style={{
            flexBasis: 754,
            minWidth: 754,
            flexGrow: 1,
            position: 'relative'
          }}>
          <SettlementOrdersWidgetErrorBoundary>
            <SettlementOrdersWidget
              onSetFullScreenMode={setFullScreenMode}
              isFullScreenMode={isFullScreenMode}
            />
          </SettlementOrdersWidgetErrorBoundary>
        </div>
      </Flex>

      <AddNewButton />
    </DashboardContext.Provider>
  );
};
