import React from 'react';
import { Button, Flex, notification, Typography } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';
import { AxiosError, isAxiosError } from 'axios';
import { handleError } from 'helpers';
import { DownloadOutlined } from '@ant-design/icons';

type IProps = {
  children: React.ReactElement;
};

type IState = {
  hasError: boolean;
  error?: AxiosError;
  isOpen: boolean;
};

export class InvoiceExportPopoverErrorBoundary extends React.Component<IProps, IState> {
  static handledErrors = new Set();

  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      isOpen: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    if (InvoiceExportPopoverErrorBoundary.handledErrors.has(error) || !isAxiosError(error)) return;

    if (error.response?.status === 401) {
      return handleError(error);
    }

    return {
      hasError: true,
      error: error.response?.data,
      isOpen: true
    };
  }

  componentDidCatch(error: Error) {
    InvoiceExportPopoverErrorBoundary.handledErrors.add(error);
  }

  render() {
    if (this.state.hasError) {
      const currentHref = window.location.href;

      const onClose = () => {
        window.location.href = currentHref;
      };

      notification.error({
        message: 'The document generation service is not available',
        description: (
          <Flex vertical gap={24}>
            <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
              Please send the error details to the application administrator and try again later
            </Typography.Text>

            {this.state.error && (
              <Typography.Text
                type="secondary"
                copyable={{ tooltips: ['Copy to clipboard', 'Copied'] }}
                style={{ whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(this.state.error).split(',').join(`,\n`)}
              </Typography.Text>
            )}
          </Flex>
        ),
        onClose: onClose,
        placement: 'topLeft',
        key: 'errorNotification',
        duration: MESSAGE_DURATION
      });

      return (
        <Button
          icon={<DownloadOutlined />}
          onClick={onClose}
          style={{ border: 'none', boxShadow: 'none', alignSelf: 'center', color: '#1890ff' }}>
          Generate new file
        </Button>
      );
    }

    return this.props.children;
  }
}
