import React, { useState } from 'react';
import { ConfigProvider, Empty, notification, Table } from 'antd';
import {
  InvoiceHydrated,
  LinkActionTypes,
  LinkedEntityTypes,
  Payment,
  SettlementInvoice,
  TransactionType
} from 'services/api/data-contracts';
import { getColumns, isInvoice } from './columns';
import { CONTROL_SIZE } from 'consts/common';
import { InvoiceItemsExpandableTable } from '../InvoiceItemsExpandableTable/InvoiceItemsExpandableTable';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';
import {
  SettlementTableCreditNote,
  useSettlementDashboardStore
} from 'store/settlementDashboardStore';
import { useDrawerStore } from 'store/drawerStore';
import { useNavigate } from 'react-router-dom';
import { validateLinkingStatus } from './helpers/validateLinkingStatus';
import { LINK_ACTIONS, LINK_STATUS } from './helpers/constants';
import { useGetLinks } from 'queries/links/useGetLinks';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';
import { useMessageStore } from 'store/messageStore';
import { useDashboardStore } from 'store/dashboardStore';
import { map } from 'lodash';

const StyledTable = styled(
  Table<
    | SettlementInvoice
    | (Payment & {
        totalByOrderService: number;
      })
  >
)`
  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 10px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .unposted {
    color: rgba(0, 0, 0, 0.45);
  }

  .linked {
    background-color: #d9f7be;
  }
`;

interface IProps {
  invoices: SettlementInvoice[];
  isFullScreenMode: boolean;
  orderNumber: string;
  serviceId: number;
}

export const InvoicesExpandableTable = ({
  invoices,
  isFullScreenMode,
  orderNumber,
  serviceId
}: IProps) => {
  const [currentEntity, setCurrentEntity] = useState<null | { id: string; isCreditNote: boolean }>(
    null
  );
  const [api, contextHolder] = notification.useNotification();
  const { setInvoice } = useInvoiceStore(({ setCurrent }) => ({
    setInvoice: setCurrent
  }));
  const { setPayment } = usePaymentStore(({ setCurrent }) => ({ setPayment: setCurrent }));
  const { clearOrderRowsState, setBillingsLevelRowsState, billingsLevelRowsState } =
    useSettlementDashboardStore(
      ({ clearOrderRowsState, setBillingsLevelRowsState, billingsLevelRowsState }) => ({
        clearOrderRowsState,
        setBillingsLevelRowsState,
        billingsLevelRowsState
      })
    );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));
  const { selectedMessages } = useMessageStore(({ selectedMessages }) => ({
    selectedMessages
  }));
  const { linkFilters } = useDashboardStore(({ linkFilters }) => ({ linkFilters }));
  const { messageIds } = useMessageStore(({ selectedMessages }) => ({
    messageIds: map(selectedMessages, 'id')
  }));
  const isFilterByMessagesActive = linkFilters.includes('message-order') && messageIds.length;

  const navigate = useNavigate();

  const { data: invoiceLinks, isFetching } = useGetLinks(
    currentEntity?.isCreditNote
      ? { creditNoteIds: currentEntity?.id }
      : { invoiceIds: currentEntity?.id },
    selectedMessages.length > 0 && !!currentEntity?.id
  );
  const linkMutation = useUpdateMessageLinks();

  const handleOpenItem = (id: number, type: 'invoice' | 'payment') => {
    if (type === 'invoice') {
      setInvoice({ id } as unknown as InvoiceHydrated);
    } else {
      setPayment({ id });
    }

    setDrawerOpen(type);
    navigate(`/dashboard/settlement/${type}/${id}${window.location.search}`);

    // Reset selected services
    clearOrderRowsState();
  };

  const handleLinkInvoice = async (
    entity:
      | SettlementInvoice
      | (Payment & {
          totalByOrderService: number;
        }),
    status: string
  ) => {
    try {
      validateLinkingStatus(status);

      const isCreditNote = (entity as Payment)?.transactionType === TransactionType.CreditNote;
      // const isRebate = (entity as Payment)?.transactionType === TransactionType.Rebate;

      if (entity.id && LINK_ACTIONS.includes(status)) {
        await linkMutation.mutateAsync({
          action:
            status === LINK_STATUS.LINK_INVOICE || status === LINK_STATUS.LINK_CREDIT_NOTE
              ? LinkActionTypes.Link
              : LinkActionTypes.Unlink,
          target: selectedMessages.map(({ id }) => ({
            entityType: LinkedEntityTypes.Message,
            entityId: id
          })),
          source: [
            {
              entityType: isCreditNote ? LinkedEntityTypes.CreditNote : LinkedEntityTypes.Invoice,
              entityId: entity.id
            }
          ]
        });
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        api.warning({
          message: 'Link validation',
          description: e.message,
          placement: 'topLeft',
          duration: 10,
          style: {
            width: 320
          }
        });
      }
    }
  };

  const dataSource = invoices.flatMap((invoice) => {
    const creditNotes =
      invoice.creditNotes.map(
        ({ creditNote, totalByOrderService }) =>
          ({
            ...creditNote,
            totalByOrderService,
            key: creditNote.id
          }) as Payment & {
            totalByOrderService: number;
          }
      ) || [];

    return [{ ...invoice, key: invoice?.id }, ...creditNotes];
  });

  const columns = getColumns(
    handleOpenItem,
    handleLinkInvoice,
    setCurrentEntity,
    {
      data: invoiceLinks?.data,
      isFetching
    },
    selectedMessages
  );

  const handleSelect = (
    entity: SettlementInvoice | SettlementTableCreditNote,
    isSelected: boolean
  ) => {
    const updateSelectedEntity = <T extends SettlementInvoice | SettlementTableCreditNote>(
      name: string,
      entities: T[]
    ) => {
      const updatedEntities = isSelected
        ? [...entities, entity]
        : entities.filter(({ id }) => id !== entity.id);

      setBillingsLevelRowsState({
        ...billingsLevelRowsState,
        [name]: updatedEntities
      });
    };

    if (isInvoice(entity)) {
      updateSelectedEntity('selectedInvoices', billingsLevelRowsState.selectedInvoices || []);
    } else {
      const type = (entity as SettlementTableCreditNote).transactionType;

      if (type === TransactionType.CreditNote) {
        updateSelectedEntity(
          'selectedCreditNotes',
          billingsLevelRowsState.selectedCreditNotes || []
        );
      } else if (type === TransactionType.Rebate) {
        updateSelectedEntity('selectedRebates', billingsLevelRowsState.selectedRebates || []);
      }
    }
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Invoices not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      {contextHolder}
      <StyledTable
        dataSource={dataSource}
        columns={columns}
        size={CONTROL_SIZE}
        pagination={false}
        rowSelection={{
          onSelect: handleSelect,
          selectedRowKeys: [
            ...(billingsLevelRowsState.selectedInvoices?.map(({ id }) => id) || []),
            ...(billingsLevelRowsState.selectedCreditNotes?.map(({ id }) => id) || []),
            ...(billingsLevelRowsState.selectedRebates?.map(({ id }) => id) || [])
          ],
          hideSelectAll: true
        }}
        rowClassName={(row) => {
          const isPosted = 'isPosted' in row ? row.isPosted : (row as Payment).posted;
          const isLinked = isFilterByMessagesActive && 'messageId' in row && row.messageId;

          return [...(isPosted ? [] : ['unposted']), ...(isLinked ? ['linked'] : [])].join(' ');
        }}
        expandable={{
          expandedRowRender: (record) => {
            const invoice = record as SettlementInvoice;

            const invoiceItems = (invoice.invoiceItems || []).map((item) => ({
              ...item,
              isPosted: invoice.isPosted,
              type: invoice.type,
              invoiceNumber: invoice.invoiceNumber,
              currency: invoice.currency,
              contractId: invoice.contractId
            }));

            return (
              <InvoiceItemsExpandableTable
                invoiceItems={invoiceItems}
                isFullScreenMode={isFullScreenMode}
                orderNumber={orderNumber}
                serviceId={serviceId}
              />
            );
          },
          rowExpandable: (record) => 'invoiceItems' in record && record.invoiceItems.length > 0,
          columnWidth: 32
        }}
      />
    </ConfigProvider>
  );
};
