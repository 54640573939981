import { useQuery } from '@tanstack/react-query';
import { linksApi } from 'services/api';
import { NON_EXISTENT_MESSAGE_ID, QUERY_KEY } from './consts';
import { useMessageStore } from 'store/messageStore';
import { useDashboardStore } from 'store/dashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useContext } from 'react';
import { DashboardContext, DashboardType } from 'pages/consts';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';

export interface IFilter {
  orderNumbers?: string;
  flightIds?: string;
  invoiceIds?: string;
  messageIds?: string;
  programIds?: string;
  creditNoteIds?: string;
}

export const fetchLinks = async (filter: IFilter) => await linksApi.getEntityLinks(filter);

export const useGetLinks = (filter: IFilter, enabled: boolean) => {
  const { type: dashboardType } = useContext(DashboardContext);
  const isSettlementDashboard = dashboardType === DashboardType.Settlement;

  const { selectedFlights } = useFlightsDashboardStore();

  const { selectedOrders } = useOrdersDashboardStore();

  const { selectedPrograms } = useFlightProgramStore();

  const { selectedInvoices, selectedCreditNotes, selectedSettlementOrders } =
    useSettlementDashboardStore(({ billingsLevelRowsState, selectedOrders }) => ({
      selectedInvoices: billingsLevelRowsState?.selectedInvoices || [],
      selectedCreditNotes: billingsLevelRowsState?.selectedCreditNotes || [],
      selectedSettlementOrders: selectedOrders
    }));

  const { setList, setLinkedMessagesIdsFilter } = useMessageStore();

  const { isFilterByFlights, isFilterByOrders, isFilterByPrograms, isFilterBySettlementEntities } =
    useDashboardStore(({ linkFilters }) => ({
      isFilterByFlights: linkFilters.includes('flight-message') && selectedFlights.length > 0,
      isFilterByOrders: linkFilters.includes('order-message') && selectedOrders.length > 0,
      isFilterByPrograms: linkFilters.includes('program-message') && selectedPrograms.length > 0,
      isFilterBySettlementEntities:
        isSettlementDashboard &&
        linkFilters.includes('order-message') &&
        (selectedInvoices.length > 0 ||
          selectedCreditNotes.length > 0 ||
          selectedSettlementOrders.length > 0)
    }));

  return useQuery({
    queryKey: [QUERY_KEY, filter],
    queryFn: () => fetchLinks(filter),
    onSuccess: (response) => {
      if (
        response?.data &&
        (isFilterByFlights ||
          isFilterByOrders ||
          isFilterByPrograms ||
          isFilterBySettlementEntities) &&
        !filter.messageIds?.length
      ) {
        if (response.data.messages?.length) {
          setLinkedMessagesIdsFilter(response.data.messages.map((link) => link.messageId));
        } else {
          setList([]);
          /* Setting unrelevant id is required due to continues auto refetch of messages by refetchInterval.
          Related bug: https://jiraeu.epam.com/browse/SNNAIR-2456
          Manual disabling of useGetGmailMessages breaks the logic of declarative query approach.
          */
          setLinkedMessagesIdsFilter([NON_EXISTENT_MESSAGE_ID]);
        }
      }
    },
    enabled
  });
};
