import React, { useState } from 'react';
import { Button, Flex, message, Popover, Tooltip, Typography } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { settingsStore } from 'services/settings/SettingsStore';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const VersionPopover = () => {
  const [isOpen, setOpen] = useState(false);

  const apiVersion = settingsStore.getApiVersion();

  const versionData: Record<string, string> = {
    'Web application version': process.env.REACT_APP_GIT_TAG || 'Not defined',
    'Web application commit': process.env.REACT_APP_GIT_SHA || 'Not defined',
    'API version': apiVersion?.gitTag || 'Not defined',
    'API commit': apiVersion?.gitSha || 'Not defined',
    'Alfresco version': apiVersion?.alfrescoApiVersion || 'Not defined'
  };

  const popoverContent = (
    <div data-testid="version-popover">
      {Object.keys(versionData).map((key) => (
        <Typography key={key}>
          {key}: {versionData[key]}
        </Typography>
      ))}

      <Flex justify="end" style={{ marginTop: 8 }}>
        <CopyToClipboard
          text={JSON.stringify(versionData)}
          onCopy={async () => {
            setOpen(false);
            await message.success('Application version has been copied');
          }}>
          <Button size={CONTROL_SIZE} type="primary" icon={<CopyOutlined />}>
            Copy to clipboard
          </Button>
        </CopyToClipboard>
      </Flex>
    </div>
  );

  return (
    <Tooltip mouseEnterDelay={1} title="Application version">
      <Popover
        mouseEnterDelay={1}
        trigger="click"
        title="Application version"
        content={popoverContent}
        open={isOpen}
        onOpenChange={(newState) => setOpen(newState)}>
        <Button
          shape="circle"
          icon={<QuestionCircleOutlined style={{ color: '#fff' }} />}
          type="text"
          size={CONTROL_SIZE}
        />
      </Popover>
    </Tooltip>
  );
};
