import { DATE_FORMAT_SHORT_WITH_UNDERSCORE } from 'consts/common';
import { getUTCDate } from 'helpers';
import { getFileExtension } from 'pages/widgets/MessagesWidget/components/AttachmentsPreview/utils';
import { Documents } from '../columns';

export const getInvoiceFilename = (record: Documents[number], counterpartyName?: string) => {
  const date = getUTCDate(record.createdAt)?.format(DATE_FORMAT_SHORT_WITH_UNDERSCORE);
  const extension = getFileExtension(record.filename);
  const name = [record.invoiceNumber, date, counterpartyName].filter((i) => !!i).join('_');
  return [name, extension].join('.');
};
