import React, { useMemo, useState } from 'react';
import { Flex, Input, Select, SelectProps, Space, Switch, Tag, Tooltip, Typography } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { MessagesFilter, useMessageStore } from 'store/messageStore';
import { RangeValue } from 'store/helpers/types';
import {
  FILTER,
  FILTER_LABEL,
  FILTER_LINKS,
  FILTER_LINKS_OPTIONS
} from '../../widgets/MessagesWidget/consts';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { useMailboxConfig } from '../../widgets/MessagesWidget/helpers/useMailboxConfig';
import { getColoredTags } from '../../widgets/MessagesWidget/helpers/getColoredTags';
import { Type } from 'services/emailEngineApi/data-contracts';

interface IProps {
  onChange: (name: string, value: string | RangeValue | boolean | string[]) => void;
  filter: MessagesFilter;
}

const { Option, OptGroup } = Select;

type TagRender = SelectProps['tagRender'];

export const MessageFilters = ({ onChange, filter }: IProps) => {
  const [mode, setMode] = useState('emailSubject');

  const { customFlags } = useMailboxConfig();
  const { accountInfo } = useMessageStore(({ accountInfo }) => ({
    accountInfo
  }));
  const coloredTags = getColoredTags(customFlags);

  const handleSelectFilterMode = (value: string) => {
    setMode(value);
    if (value === 'emailSubject') {
      updateEmailBody('');
    } else {
      updateEmailSubject('');
    }
  };

  const updateEmailSubject = (value: string) => {
    onChange('emailSubject', value);
  };

  const updateEmailBody = (value: string) => {
    onChange('emailBody', value);
  };

  const updateField = mode === 'emailSubject' ? updateEmailSubject : updateEmailBody;

  const selectAfter = useMemo(
    () => (
      <Select
        defaultValue="subject"
        onChange={handleSelectFilterMode}
        dropdownStyle={{ width: '80px' }}
        options={[
          { value: 'emailSubject', label: 'Subject' },
          { value: 'emailBody', label: 'Body' }
        ]}
      />
    ),
    [mode]
  );

  const FILTER_TAGS = [FILTER.WITHOUT_LABELS, ...coloredTags, FILTER.ALL_LABELS];

  const tagRender: TagRender = (props) => {
    // eslint-disable-next-line react/prop-types
    const { value, closable, onClose } = props;
    const color = FILTER_TAGS.find((item) => item.label === value)?.color;

    return (
      <Tag
        color={color}
        closable={closable}
        onClose={onClose}
        style={{
          margin: '2px 3px',
          height: '16px',
          lineHeight: '14px',
          border: '1px solid transparent',
          borderRadius: '2px'
        }}>
        {value}
      </Tag>
    );
  };

  return (
    <Flex wrap="wrap">
      <Flex gap={20} justify="space-between" style={{ width: '100%', marginBottom: '12px' }}>
        {accountInfo && accountInfo.type === Type.Imap && (
          <div style={{ flexBasis: '220px' }}>
            <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Labels">
              <Select
                size={CONTROL_SIZE}
                placeholder="Labels presence: All"
                data-testid="message-tag-select"
                style={{ width: '100%' }}
                tagRender={tagRender}
                options={FILTER_TAGS.map(({ label, icon }) => ({
                  value: label,
                  label: (
                    <Space>
                      {icon}
                      {label}
                    </Space>
                  )
                }))}
                optionFilterProp="label"
                mode="multiple"
                maxTagCount="responsive"
                listHeight={200}
                value={filter.flags}
                onChange={(value) => {
                  let result = [...value];

                  const hasAllLabelsTag = value.includes(FILTER_LABEL.ALL_LABELS);
                  const hasWithoutLabelsTag = value.includes(FILTER_LABEL.WITHOUT_LABELS);

                  if (hasAllLabelsTag && hasWithoutLabelsTag) {
                    if (
                      value.indexOf(FILTER_LABEL.ALL_LABELS) <
                      value.indexOf(FILTER_LABEL.WITHOUT_LABELS)
                    ) {
                      result = [FILTER_LABEL.WITHOUT_LABELS];
                    } else {
                      result = value.filter((label) => label !== FILTER_LABEL.WITHOUT_LABELS);
                    }
                  }

                  if (result.includes(FILTER_LABEL.ALL_LABELS)) {
                    result = [FILTER_LABEL.ALL_LABELS, ...Object.keys(customFlags)];
                  }

                  onChange('flags', result);
                }}
                allowClear
              />
            </Tooltip>
          </div>
        )}

        <div style={{ flex: 1 }}>
          <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Recipient or sender">
            <Input
              size={CONTROL_SIZE}
              placeholder="Recipient or sender"
              name="recipientOrSender"
              onChange={({ target: { name, value } }) => onChange(name, value)}
              value={filter.recipientOrSender}
              allowClear
              style={{ width: '100%' }}
              autoComplete="off"
            />
          </Tooltip>
        </div>

        <div style={{ flexBasis: '220px' }}>
          <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Sent date">
            <StyledDateRangePicker
              placeholder={['Sent from', 'To']}
              value={filter.messageSentDate}
              name="messageSentDate"
              onChange={(value) => {
                onChange('messageSentDate', value);
              }}
              format={DATE_FORMAT}
              onSetDate={(value) => {
                onChange('messageSentDate', value);
              }}
            />
          </Tooltip>
        </div>

        <div style={{ flex: 1 }}>
          <Tooltip
            mouseEnterDelay={1}
            trigger={['focus', 'hover']}
            title={mode === 'emailSubject' ? 'Subject' : 'Body text'}>
            <Input
              size={CONTROL_SIZE}
              addonAfter={selectAfter}
              placeholder="Search by subject or text"
              value={mode === 'emailSubject' ? filter.emailSubject : filter.emailBody}
              onChange={(e) => updateField(e.target.value)}
              allowClear
              autoComplete="off"
            />
          </Tooltip>
        </div>
      </Flex>

      <Flex gap={20}>
        <Select
          size={CONTROL_SIZE}
          placeholder="Links presence: All"
          data-testid="links-select"
          style={{ width: 220 }}
          optionFilterProp="label"
          listHeight={200}
          value={filter.links ?? FILTER_LINKS.ALL}
          onChange={(value) => onChange('links', value)}>
          <OptGroup label="Links presence">
            {FILTER_LINKS_OPTIONS.map((status) => (
              <Option
                key={status}
                value={status}
                style={{
                  backgroundColor: status === 'With links' ? '#D9F7BE' : 'inherit'
                }}
                label={status}>
                {status}
              </Option>
            ))}
          </OptGroup>
        </Select>

        <Flex gap={8} justify="flex-end" align="center">
          <Switch
            size={CONTROL_SIZE}
            value={!!filter.withAttachment}
            onChange={(checked) => onChange('withAttachment', checked ? 'withAttachment' : false)}
          />
          <Typography.Text>with attachment</Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
