import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Flex, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT_SHORT } from 'consts/common';
import { getUTCDate } from 'helpers';
import { convertBytesToReadableSize } from 'pages/widgets/MessagesWidget/components/NewMessage/helpers/convertBytesToReadableSize';
import { dateSorter } from 'pages/Dictionaries/utils/tableSorters';
import { formatRelativeTime } from 'helpers/dataRendering/formatRelativeTime';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { InvoiceReportsListResponse } from 'services/api/data-contracts';
import { getInvoiceFilename } from './helpers/getInvoiceFilename';

export type DocumentItem = UserDocument | AutoGeneratedDocument;

export interface BaseDocument {
  fileId: string;
  fileSize: number;
  invoiceId: number;
  filename: string;
  invoiceNumber: string;
  currency: string;
  total: number;
  createdAt: string;
}

export type UserDocument = BaseDocument & {
  isUserFile: true;
};

export type AutoGeneratedDocument = BaseDocument & {
  isUserFile: false;
};

export type Documents = InvoiceReportsListResponse['items'];

export const getColumns = (
  onDownload: (file: DocumentItem) => void,
  onDeleteUserFile: (file: DocumentItem) => void,
  counterpartyName?: string
): ColumnsType<Documents[number]> => [
  {
    title: 'Name for customer',
    dataIndex: ['name'],
    key: 'name',
    render: (_, record) => {
      const filename = getInvoiceFilename(record, counterpartyName);

      return (
        <Tooltip mouseEnterDelay={1} title={filename}>
          <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {filename}
          </div>
        </Tooltip>
      );
    },
    width: 200
  },
  {
    title: 'Original name',
    dataIndex: ['name'],
    key: 'name',
    render: (_, record) => {
      if (record.isUserFile) {
        return (
          <Tooltip mouseEnterDelay={1} title={record.filename}>
            <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {record.filename}
            </div>
          </Tooltip>
        );
      }

      return <Flex justify="center">-</Flex>;
    },
    width: 200
  },
  {
    title: 'Date',
    dataIndex: ['createdAt'],
    key: 'createdAt',
    render: (_, record) => {
      return record.createdAt ? getUTCDate(record.createdAt)?.format(DATE_FORMAT_SHORT) : ' - ';
    },
    width: 80
  },
  {
    title: 'Size',
    dataIndex: ['fileSize'],
    render: (_, record) => convertBytesToReadableSize(record.fileSize),
    width: 80
  },
  {
    title: 'Added',
    dataIndex: ['createdAt'],
    sorter: (a, b) => dateSorter(a.createdAt || '', b.createdAt || ''),
    render: (value, record) => (
      <Flex justify="flex-end" style={{ overflow: 'hidden' }}>
        <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {record.createdAt ? formatRelativeTime(record.createdAt) : ' - '}
        </div>

        <div id="actions">
          <Tooltip title="Download file">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              data-testid="download-btn"
              icon={<DownloadOutlined />}
              onClick={() => onDownload(record)}
            />
          </Tooltip>
          {record.isUserFile && (
            <Tooltip title="Delete file">
              <Button
                size={CONTROL_SIZE}
                shape="circle"
                icon={<DeleteOutlined />}
                data-testid="delete-btn"
                onClick={() => onDeleteUserFile(record)}
              />
            </Tooltip>
          )}
        </div>
      </Flex>
    ),
    align: 'end',
    ellipsis: true,
    width: 120
  }
];
