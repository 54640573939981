import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Form, message } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { transformForRequest } from './helpers/transformForRequest';
import { useCreateMessage } from 'queries/messages/useCreateMessage';
import { getBase64 } from './helpers/getBase64';
import { MessageListEntry } from 'services/emailEngineApi/data-contracts';
import { ACTION, FORM_FIELD_NAMES } from './constants';
import { LinkedEntity, NewMessageMode, useMessageStore } from 'store/messageStore';
import { getTitle } from './helpers/getTitle';
import { getInitialFormValues } from './helpers/getInitialFormValues';
import { AnsweredMessage } from './components/AnsweredMessage/AnsweredMessage';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { StyledDrawer, StyledEmailBody, StyledForm, StyledTextArea } from './NewMessage.styles';
import { HeaderButtons } from './components/HeaderButtons';
import { EmailHeader } from './components/EmailHeader';
import { AttachmentUpload } from './components/AttachmentUpload';
import { getBase64Size } from './helpers/getBase64Size';
import {
  LinkedEntityTypes,
  LinkEntityItem,
  RecipientTagType,
  TransactionType
} from 'services/api/data-contracts';
import { useGetCounterpartyContacts } from 'queries/counterparty';
import { useOrderStore } from 'store/orderStore';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';
import { settingsStore } from 'services/settings/SettingsStore';

const newMessageModes = [
  ACTION.NEW,
  ACTION.FROM_ORDER,
  ACTION.WITH_INVOICE_ATTACHMENT,
  ACTION.WITH_PAYMENT_ATTACHMENT
];

export type Attachment = {
  uid: string;
  name: string;
  contentType: string;
  size?: number;
  content?: string;
  reference?: string;
  status?: UploadFileStatus;
};

interface IProps {
  newMessageMode: NewMessageMode;
  onClose: (value: boolean) => void;
}

export const NewMessage = ({ newMessageMode, onClose }: IProps) => {
  const [files, setFiles] = useState<(Attachment & LinkedEntity)[]>([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [uploadKey, setUploadKey] = useState(1);
  const [optimisticUpdatedMessage, setOptimisticUpdatedMessage] = useState<MessageListEntry | null>(
    null
  );
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { orderCustomerId } = useOrderStore(({ current }) => ({
    orderCustomerId: current?.customerId
  }));

  const { invoicePayerId } = useInvoiceStore(({ current }) => ({
    invoicePayerId: current?.payerId
  }));

  const { isCreditNote, creditNotePayerId } = usePaymentStore(({ current }) => ({
    creditNotePayerId: current?.payerId,
    isCreditNote: current?.transactionType === TransactionType.CreditNote
  }));

  const { isLoading: isLoadingContacts, data: counterpartyContacts } = useGetCounterpartyContacts(
    {
      counterpartyId: (newMessageMode === ACTION.FROM_ORDER
        ? orderCustomerId
        : invoicePayerId || creditNotePayerId) as number,
      recipientTag:
        newMessageMode === ACTION.FROM_ORDER
          ? RecipientTagType.OPSAddress
          : RecipientTagType.BillingAddress
    },
    (newMessageMode === ACTION.FROM_ORDER && !!orderCustomerId) ||
      (newMessageMode === ACTION.WITH_INVOICE_ATTACHMENT &&
        (!!invoicePayerId || (isCreditNote && !!creditNotePayerId)))
  );

  const {
    currentMessage,
    setCurrentMessage,
    setNewMessageMode,
    emailAddressOptions,
    optimisticUpdateMessage,
    setPendingEntitiesToLinkFromTemplate,
    setAttachmentsData,
    attachmentsData,
    accountInfo
  } = useMessageStore(
    ({
      currentMessage,
      setCurrentMessage,
      setNewMessageMode,
      emailAddressOptions,
      optimisticUpdateMessage,
      setPendingEntitiesToLinkFromTemplate,
      setAttachmentsData,
      attachmentsData,
      accountInfo
    }) => ({
      currentMessage,
      setCurrentMessage,
      setNewMessageMode,
      emailAddressOptions,
      optimisticUpdateMessage,
      setPendingEntitiesToLinkFromTemplate,
      setAttachmentsData,
      attachmentsData,
      accountInfo
    })
  );

  useEffect(() => {
    form.setFieldsValue(getInitialFormValues(newMessageMode, currentMessage));
  }, [newMessageMode, currentMessage, form]);

  useEffect(() => {
    const values = form.getFieldsValue(['to', 'subject']);
    const requiredFields = ['to', 'subject'];
    const allRequiredFieldsFilled = requiredFields.every((key) => values[key]);
    const hasFileUploadError = files.some((file) => file.status === 'error');
    setIsSubmitDisabled(!allRequiredFieldsFilled || hasFileUploadError);
  }, [form, files]);

  useEffect(() => {
    if (optimisticUpdateMessage) {
      setOptimisticUpdatedMessage(optimisticUpdateMessage);
    }
  }, [optimisticUpdateMessage]);

  useEffect(() => {
    if (
      newMessageMode === ACTION.FORWARD &&
      currentMessage?.attachments &&
      currentMessage?.attachments?.length > 0
    ) {
      const existingFiles = currentMessage.attachments.map((file, idx) => ({
        uid: file.id || `file-${idx}`,
        name: file.filename || 'file',
        contentType: file.contentType || 'application/octet-stream',
        reference: file.id,
        status: 'done' as UploadFileStatus
      }));

      setFiles(existingFiles);
    }
  }, [newMessageMode]);

  useEffect(() => {
    const setReportAttachment = async () => {
      if (
        [ACTION.WITH_INVOICE_ATTACHMENT, ACTION.WITH_PAYMENT_ATTACHMENT].includes(
          newMessageMode as string
        ) &&
        attachmentsData
      ) {
        const existingFiles = [];

        for (const [idx, file] of attachmentsData.entries()) {
          const base64 = await getBase64(file.blob);
          const sizeInBytes = getBase64Size(base64);
          existingFiles.push({
            uid: `file-${idx}`,
            name: file.filename || 'file',
            contentType: file.contentType || 'application/octet-stream',
            content: base64,
            status: 'done' as UploadFileStatus,
            size: sizeInBytes,
            entityType: file.entityType,
            entityId: file.entityId
          });
        }

        setFiles(existingFiles);
      }
    };

    setReportAttachment();
  }, [newMessageMode, currentMessage, attachmentsData, setFiles]);

  const toFormFieldName = useMemo(() => FORM_FIELD_NAMES.to, []);

  useEffect(() => {
    if (counterpartyContacts?.data?.items.length) {
      form.setFieldsValue({
        [toFormFieldName]: counterpartyContacts?.data?.items?.map(
          ({ emailAddress }) => emailAddress
        )
      });
      setIsSubmitDisabled(false);
    }
  }, [counterpartyContacts, form]);

  const showInitialMessage =
    !!currentMessage &&
    [ACTION.REPLY, ACTION.FORWARD, ACTION.REPLY_ALL].includes(newMessageMode as string);

  const onValuesChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (changedValues: Record<string, any>) => {
      const changedFieldNames = Object.keys(changedValues);

      try {
        await form.validateFields(changedFieldNames);
        const values = form.getFieldsValue();
        const requiredFields = ['to', 'subject'];
        const allRequiredFieldsFilled = requiredFields.every((key) => values[key]);
        setIsSubmitDisabled(!allRequiredFieldsFilled);
      } catch {
        setIsSubmitDisabled(true);
      }
    },
    [form]
  );

  const handleCloseNewMessageDrawer = () => {
    form.resetFields();
    setFiles([]);
    setNewMessageMode(false);
    setUploadKey((prevKey) => prevKey + 1);
    onClose(false);
    setAttachmentsData([]);
    setCurrentMessage(undefined);
    setPendingEntitiesToLinkFromTemplate([]);
  };

  const hasLinks =
    currentMessage?.flags?.includes(settingsStore.getFlags().LINKED) ||
    (optimisticUpdatedMessage?.flags?.includes(settingsStore.getFlags().LINKED) &&
      optimisticUpdatedMessage?.id === currentMessage?.id);
  const isReplyActionWithPendingLinks =
    hasLinks && [ACTION.REPLY, ACTION.REPLY_ALL].includes(newMessageMode as string);
  const shouldAddLinks =
    isReplyActionWithPendingLinks ||
    [ACTION.FROM_ORDER, ACTION.WITH_INVOICE_ATTACHMENT].includes(newMessageMode as string);

  const createMutation = useCreateMessage(shouldAddLinks, () => setOptimisticUpdatedMessage(null));

  const handleSendNewMessage = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    const values = form.getFieldsValue();
    const data = transformForRequest(values, files, newMessageMode, currentMessage);

    try {
      if (newMessageMode === ACTION.WITH_INVOICE_ATTACHMENT) {
        const invoices = files
          ? files
              .map((item) => {
                if (item.entityType === LinkedEntityTypes.Invoice && item.entityId) {
                  return {
                    entityType: item.entityType,
                    entityId: item.entityId
                  };
                }
              })
              .filter(Boolean)
          : [];

        setPendingEntitiesToLinkFromTemplate(invoices as LinkEntityItem[]);
      }
      await createMutation.mutateAsync({
        ...data,
        from: {
          name: accountInfo?.email,
          address: accountInfo?.email as string
        }
      });
      messageApi.success('Message was successfully sent');
      handleCloseNewMessageDrawer();
    } catch (error) {
      console.error('Failed to create message:', error);
    }
  };

  const title = useMemo(() => getTitle(newMessageMode), [newMessageMode]);

  return (
    <>
      {contextHolder}

      <StyledDrawer
        title={title}
        placement="top"
        closable={false}
        open={!!newMessageMode}
        key="top"
        getContainer={false}
        mask={false}
        style={{
          position: 'relative',
          zIndex: 90,
          borderRadius: '8px',
          border: '1px solid #d9d9d9'
        }}
        extra={
          <HeaderButtons
            onSubmit={handleSendNewMessage}
            onClose={handleCloseNewMessageDrawer}
            isSubmitDisabled={isSubmitDisabled}
            isLoading={createMutation.isLoading}
          />
        }>
        <Flex vertical style={{ justifyContent: 'space-between', height: '100%' }}>
          <StyledForm
            form={form}
            onValuesChange={onValuesChange}
            requiredMark={false}
            autoComplete="off"
            validateTrigger="onBlur"
            initialValues={() => getInitialFormValues(newMessageMode, currentMessage)}
            size={CONTROL_SIZE}>
            <EmailHeader
              emailAddressOptions={emailAddressOptions}
              isLoadingContacts={isLoadingContacts}
              prefilledAddressOptions={
                counterpartyContacts?.data?.items.map(({ emailAddress }) => emailAddress) || []
              }>
              <AttachmentUpload files={files} setFiles={setFiles} uploadKey={uploadKey} />
            </EmailHeader>

            <StyledEmailBody
              name={FORM_FIELD_NAMES.body}
              style={{ flexGrow: 1, marginTop: '16px' }}
              data-testid="email_body">
              <StyledTextArea autoSize />
            </StyledEmailBody>
          </StyledForm>

          {!newMessageModes.includes(String(newMessageMode)) && showInitialMessage && (
            <AnsweredMessage
              message={currentMessage}
              mode={newMessageMode as 'reply' | 'forward' | 'replyAll'}
            />
          )}
        </Flex>
      </StyledDrawer>
    </>
  );
};
