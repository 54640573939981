import React, { useContext, useMemo } from 'react';
import { Flex, Table } from 'antd';
import { Order, Program, TotalSales } from 'services/api/data-contracts';
import { CONTROL_SIZE, DEFAULT_LIMIT } from 'consts/common';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDrawerStore } from 'store/drawerStore';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { DASHBOARD_TOP_PADDING, DashboardContext, DashboardType } from 'pages/consts';
import { useColumns } from './useColumns';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';

const StyledTable = styled(Table<Program>)`
  .ant-table-thead .ant-table-cell {
    padding: 8px 4px !important;
  }

  .ant-table-placeholder > .ant-table-cell {
    border-bottom: none;
  }

  .table-row {
    cursor: pointer;
  }

  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 10px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }
`;

const TABLE_HEADER_HEIGHT = 39;

interface IProps {
  headerHeight: number;
  isLoading?: boolean;
}

export const ProgramsTable = ({ headerHeight, isLoading }: IProps) => {
  const { setOpenProgramId, list, selectedPrograms, setSelectedPrograms, setCurrentToUpdate } =
    useFlightProgramStore();
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));
  const { setOrdersFilter } = useOrdersDashboardStore();
  const { setWidgetMode } = useSettlementDashboardStore(({ setWidgetMode }) => ({
    setWidgetMode
  }));

  const { type: dashboardType } = useContext(DashboardContext);

  const isSettlementDashboard = useMemo(
    () => dashboardType === DashboardType.Settlement,
    [dashboardType]
  );

  const navigate = useNavigate();

  const tableHeight = useMemo(
    () => window.innerHeight - DASHBOARD_TOP_PADDING - headerHeight - TABLE_HEADER_HEIGHT,
    [window.innerHeight, headerHeight]
  );

  const handleEdit = (
    record: Program & {
      mainOrder?: Order;
      totalSales?: TotalSales;
    }
  ) => {
    setCurrentToUpdate({
      id: record.id,
      status: record.status,
      invoicingTerm: record.invoicingTerm,
      notes: record.notes
    });
    setDrawerOpen('editFlightProgram');
  };

  const dataSource = useMemo(
    () => list.map((program) => ({ ...program, key: program.id })),
    [list]
  );
  const columns = useColumns(handleEdit, setOrdersFilter, setWidgetMode);

  return (
    <StyledTable
      dataSource={dataSource}
      columns={columns}
      size={CONTROL_SIZE}
      loading={isLoading}
      data-testid={isLoading ? 'table-loading' : 'table'}
      pagination={false}
      scroll={{ y: tableHeight }}
      rowClassName="table-row"
      rowSelection={{
        onSelect: (program, isSelected) => {
          setSelectedPrograms(
            isSelected
              ? [...selectedPrograms, program]
              : selectedPrograms.filter(({ id }) => id !== program.id)
          );
        },
        selectedRowKeys: selectedPrograms.map(({ id }) => id),
        hideSelectAll: true
      }}
      onRow={(record) => ({
        onClick: (e) => {
          const isCheckbox = 'checked' in e.target;
          const isButton = (e.target as HTMLElement).closest('button');

          if (!isCheckbox && !isButton) {
            navigate(
              `/dashboard/${isSettlementDashboard ? 'settlement' : 'dispatcher'}/flightProgram/${record.id}${window.location.search}`
            );
            setOpenProgramId(record.id);
            setDrawerOpen('flightProgram');
          }
        }
      })}
      summary={(orders) => {
        if (orders.length === DEFAULT_LIMIT) {
          return (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={9}>
                  <Flex
                    align="center"
                    justify="center"
                    style={{ height: 100, width: '100%', textAlign: 'center', color: '#00000073' }}>
                    {DEFAULT_LIMIT} programs are displayed. <br />
                    Change the filters to find the one you are looking for.
                  </Flex>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }
      }}
    />
  );
};
