import React from 'react';
import { Order, OrderStatus, OrderType, Program, TotalSales } from 'services/api/data-contracts';
import { ColumnsType } from 'antd/es/table';
import { Button, Flex, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT, TIME_FORMAT } from 'consts/common';
import { getAmountValue, getUTCDate } from 'helpers';
import styled from 'styled-components';
import { OrderStatusTag } from 'pages/widgets/DispatcherOrdersWidget/components/OrderStatusTag';
import { red } from '@ant-design/colors';
import { EditOutlined, ExportOutlined } from '@ant-design/icons';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { SettlementWidgetMode } from 'store/settlementDashboardStore';

interface DecoratedText {
  $isCancelled: boolean;
}

const DecoratedText = styled('div')<DecoratedText>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: ${(props) => (props.$isCancelled ? 'line-through' : 'none')};
  color: ${(props) => (props.$isCancelled ? '#00000073' : 'inherit')};

  & div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
  }
`;

export const getSettlementColumns = (
  onEditProgram: (
    program: Program & {
      mainOrder?: Order;
      totalSales?: TotalSales;
    }
  ) => void,
  onSetOrdersFilter: (ordersFilter?: OrdersFilter) => void,
  onSetWidgetMode: (widgetMode?: SettlementWidgetMode) => void
): ColumnsType<
  Program & {
    mainOrder?: Order;
    totalSales?: TotalSales;
  }
> => [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    render: (value) => <OrderStatusTag status={value} />,
    width: 52
  },
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 35,
    render: (_, record) => (
      <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
        {`${record.mainOrder?.type[0]}p`}
      </DecoratedText>
    ),
    align: 'center'
  },
  {
    title: 'N',
    dataIndex: 'flightNumbers',
    key: 'flightNumbers',
    render: (_, record) => {
      if (!record.mainOrder) return '-';

      const allFlights =
        record.mainOrder.type === OrderType.PMT
          ? record.mainOrder.flights || []
          : [record.mainOrder.arrivalFlight, record.mainOrder.departureFlight];

      return allFlights
        .map((flight) => flight?.flightNumber)
        .filter(Boolean)
        .map((flightNumber, index) => (
          <DecoratedText
            key={index}
            $isCancelled={record.status === OrderStatus.CNLD}
            style={{ display: 'block' }}>
            {flightNumber}
          </DecoratedText>
        ));
    },
    ellipsis: true
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (_, record) => {
      const location = record.mainOrder?.location;

      if (!location) return '-';

      return (
        <Tooltip mouseEnterDelay={1} title={location.searchName}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {'code' in location ? location.code : location.name}
          </DecoratedText>
        </Tooltip>
      );
    },
    ellipsis: true
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (_, record) => {
      const customer = record.mainOrder?.customer;

      if (!customer) return '-';

      return (
        <Tooltip mouseEnterDelay={1} title={customer.name}>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {customer.name}
          </DecoratedText>
        </Tooltip>
      );
    },
    ellipsis: true
  },
  {
    title: 'Period',
    dataIndex: 'period',
    key: 'period',
    width: 190,
    render: (_, record) => {
      const startDate = getUTCDate(record.startDatetime);
      const endDate = getUTCDate(record.endDatetime);

      const dateRange = startDate.format(DATE_FORMAT) + ' - ' + endDate.format(DATE_FORMAT);
      const timeRange = startDate.format(TIME_FORMAT) + ' - ' + endDate.format(TIME_FORMAT);

      return (
        <Flex vertical>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {dateRange}
          </DecoratedText>
          <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
            {timeRange}
          </DecoratedText>
        </Flex>
      );
    }
  },
  {
    title: 'Cur',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) => {
      if (!record.totalSales || !record.totalSales.length) {
        return ' - ';
      }

      return (
        <div>
          {record.totalSales.map((item, index) => (
            <Tooltip key={index} mouseEnterDelay={1} title={item.currency}>
              <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
                {item.currency || ' - '}
              </DecoratedText>
            </Tooltip>
          ))}
        </div>
      );
    },
    ellipsis: true,
    width: 45
  },
  {
    title: 'Sale',
    dataIndex: 'sale',
    key: 'sale',
    render: (_, record) => {
      if (!record.totalSales || !record.totalSales.length) {
        return ' - ';
      }

      return (
        <>
          {record.totalSales.map((item, index) => (
            <Tooltip key={index} mouseEnterDelay={1} title={getAmountValue(item.sale)}>
              <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
                {getAmountValue(item.sale) || ' - '}{' '}
              </DecoratedText>
            </Tooltip>
          ))}
        </>
      );
    },
    ellipsis: true,
    width: 86,
    align: 'end'
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
    render: (_, record) => {
      if (!record.totalSales || !record.totalSales.length) {
        return ' - ';
      }

      return (
        <>
          {record.totalSales.map((item, index) => (
            <Tooltip key={index} mouseEnterDelay={1} title={getAmountValue(item.cost)}>
              <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
                {getAmountValue(item.cost) || ' - '}
              </DecoratedText>
            </Tooltip>
          ))}
        </>
      );
    },
    ellipsis: true,
    width: 86,
    align: 'end'
  },
  {
    title: 'Profit',
    dataIndex: 'profit',
    key: 'profit',
    render: (_, record) => {
      let displayColor = 'inherit';
      let fontWeight = '400';

      return (
        <Flex vertical justify="center">
          {record.totalSales?.map((item, index) => {
            if (item.profit > 0) {
              displayColor = '#52C41A';
              fontWeight = '600';
            } else if (item.profit < 0) {
              displayColor = red[5];
              fontWeight = '600';
            }

            return (
              <Tooltip key={index} mouseEnterDelay={1} title={getAmountValue(item.profit)}>
                <DecoratedText $isCancelled={record.status === OrderStatus.CNLD}>
                  <div
                    style={{
                      color: displayColor,
                      fontWeight: fontWeight
                    }}>
                    {getAmountValue(item.profit)}
                  </div>
                </DecoratedText>
              </Tooltip>
            );
          })}
        </Flex>
      );
    },
    ellipsis: true,
    width: 92,
    align: 'end'
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    render: (value, record) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden'
        }}>
        <Tooltip mouseEnterDelay={1} title={value}>
          <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {value}
          </span>
        </Tooltip>
        <div id="actions">
          <Flex gap={8} style={{ marginRight: '8px' }}>
            <Tooltip title="Edit" key="Edit">
              <Button
                data-testid="button-edit-program"
                size={CONTROL_SIZE}
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onEditProgram(record)}
              />
            </Tooltip>
            <Tooltip mouseEnterDelay={1} title="Open orders">
              <Button
                data-testid="button-open-orders"
                shape="circle"
                icon={<ExportOutlined />}
                size={CONTROL_SIZE}
                type="primary"
                className="action-btn"
                onClick={() => {
                  onSetOrdersFilter({ programId: record.id });
                  onSetWidgetMode(SettlementWidgetMode.Orders);
                }}
              />
            </Tooltip>
          </Flex>
        </div>
      </div>
    ),
    width: 60
  }
];
