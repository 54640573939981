import React from 'react';
import { Flex, Input, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { InvoiceFilter } from 'store/settlementDashboardStore';
import { InvoiceStatusMultipleInput } from 'components/InvoiceStatusMultipleInput/InvoiceStatusMultipleInput';

interface IProps {
  onChange: (name: string, value: string | string[] | boolean) => void;
  filter: InvoiceFilter;
}

export const InvoiceFilters = ({ onChange, filter }: IProps) => (
  <Flex gap={20} justify="flex-start" style={{ marginBottom: '12px', width: '1110px' }}>
    <Tooltip trigger={['focus', 'hover']} title="Invoice number">
      <Input
        size={CONTROL_SIZE}
        placeholder="All invoice numbers"
        name="invoiceNumbers"
        onChange={({ target: { name, value } }) => onChange(name, value)}
        value={filter.invoiceNumbers}
        style={{ width: '220px' }}
        allowClear
        autoComplete="off"
      />
    </Tooltip>
    <Tooltip trigger={['focus', 'hover']} title="Invoice status">
      <div style={{ width: '220px' }}>
        <InvoiceStatusMultipleInput
          values={{
            isPosted: filter.isPosted,
            invoiceStatuses: filter.invoiceStatuses || []
          }}
          onChange={(value) => {
            const isPosted = value.some((status) => status === 'POSTED');
            const invoiceStatuses = isPosted
              ? value.filter((status) => status !== 'POSTED')
              : value;
            onChange('invoiceStatuses', invoiceStatuses);
            onChange('isPosted', isPosted);
          }}
        />
      </div>
    </Tooltip>
  </Flex>
);
