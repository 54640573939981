import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { flightProgramsApi } from 'services/api';
import { InvoicingTerm, OrderStatus } from 'services/api/data-contracts';
import { ENTITY_NAME, PROGRAM_ORDERS_QUERY_KEY, QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';
import { WIDGET_QUERY_KEY } from '../orders/consts';

export interface ProgramUpdateInput {
  status?: OrderStatus;
  notes?: string | null;
  invoicingTerm?: InvoicingTerm;
}

const updateItem = async (programId: number, data: ProgramUpdateInput) =>
  await flightProgramsApi.updateProgram(programId, data);

export const useUpdateFlightProgram = (programId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ProgramUpdateInput) => await updateItem(programId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      await queryClient.invalidateQueries({ queryKey: [WIDGET_QUERY_KEY] });
      await queryClient.invalidateQueries({ queryKey: [PROGRAM_ORDERS_QUERY_KEY, programId] });

      message.success(`${ENTITY_NAME} has been successfully updated`, MESSAGE_DURATION);
    }
  });
};
