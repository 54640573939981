import { createContext } from 'react';

export enum DashboardType {
  'Dispatcher' = 'Dispatcher',
  'Settlement' = 'Settlement',
  'Balance' = 'Balance',
  'Rebate' = 'Rebate'
}

export const ROUTES = {
  DASHBOARD_SETTLEMENT: '/dashboard/settlement',
  DASHBOARD_DISPATCHER: '/dashboard/dispatcher',
  REPORT: '/report',
  REPORT_BALANCE: '/report/balance',
  REPORT_REBATE: '/report/rebate'
};

export const routesMap = {
  [DashboardType.Balance]: ROUTES.REPORT_BALANCE,
  [DashboardType.Rebate]: ROUTES.REPORT_REBATE,
  [DashboardType.Dispatcher]: ROUTES.DASHBOARD_DISPATCHER,
  [DashboardType.Settlement]: ROUTES.DASHBOARD_SETTLEMENT
};

export const DashboardContext = createContext<{ type: DashboardType }>({
  type: DashboardType.Dispatcher
});

export const DASHBOARD_TOP_PADDING = 169;
export const SCROLL_DISCREPANCY_THRESHOLD = 1;
export const LIST_FOOTER_BULK_BLOCK_HEIGHT = 57;
export const DEFAULT_DOCUMENT_TEMPLATE_NAME = 'default';

// https://www.sitepoint.com/mime-types-complete-list/
export enum MIME_Type {
  Pdf = 'application/pdf',
  PdfWithVariants = 'application/pdf, application/json, application/text',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Jpeg = 'image/jpeg',
  Gif = 'image/gif',
  Png = 'image/png'
}
