import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import {
  RowState,
  settlementViews,
  SettlementWidgetMode,
  useSettlementDashboardStore
} from 'store/settlementDashboardStore';
import { debounce } from 'lodash';
import { Footer } from '../components/Footer';
import { useGetSettlementWidget } from 'queries/settlement';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LinkedEntityTypes } from 'services/api/data-contracts';
import { OrderServicesBulkOperationsPopover } from '../components/ServicesBulkOperationsPopover/OrderServicesBulkOperationsPopover';
import { useInvoiceStore } from 'store/invoiceStore';
import { useOrderStore } from 'store/orderStore';
import { DASHBOARD_TOP_PADDING } from '../../consts';
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { HeaderWithRef } from '../components/Header';
import { InvoiceDrawer } from '../InvoicesWidget/components/InvoiceDetails/InvoiceDrawer';
import { OrderServiceDrawer } from '../components/OrderServiceDrawer';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import styled from 'styled-components';
import { ServicesDrawer } from '../components/ServicesDrawer/ServicesDrawer';
import { CalculatorDrawer } from '../components/CalculatorDrawer/CalculatorDrawer';
import { CreditNoteDrawer } from '../components/CreditNotesDrawer/CreditNoteDrawer';
import { PaymentPurposeDrawer } from '../components/PaymentPurposeDrawer/PaymentPurposeDrawer';
import { useInvoiceDrawerRouting, useOrderDrawerRouting, usePaymentDrawerRouting } from 'routing';
import { PaymentDrawer } from '../components/PaymentDrawer/PaymentDrawer';
import { useDrawerStore } from 'store/drawerStore';
import { OrderDrawer } from '../components/OrderDrawer/OrderDrawer';
import { DocumentsDrawer } from '../components/DocumentsDrawer/DocumentsDrawer';
import { RadioGroup } from '../components/RadioGroup/RadioGroup';
import { OrdersView } from './views/OrdersView/OrdersView';
import { ProgramsView } from '../components/ProgramsView/ProgramsView';
import { ProgramDrawer } from '../DispatcherOrdersWidget/components/ProgramDetails/ProgramDrawer';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { ProgramEditDrawer } from '../components/ProgramsView/ProgramEditDrawer/ProgramEditDrawer';
import { useMessageStore } from 'store/messageStore';
import { useDashboardStore } from 'store/dashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';

const ListWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  .ant-drawer-mask {
    border-radius: 8px;
  }

  .ant-drawer-right {
    overflow-x: clip;
  }

  .ant-drawer-content {
    border-radius: 0 8px 8px 0;

    &.invoice-drawer,
    &.documents-drawer,
    &.flight-program-drawer,
    &.services-drawer {
      border-radius: 8px;
      border: 1px solid #d9d9d9;
    }
  }
`;

interface IProps {
  onSetFullScreenMode: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreenMode: boolean;
}

export const SettlementOrdersWidget = ({ onSetFullScreenMode, isFullScreenMode }: IProps) => {
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - DASHBOARD_TOP_PADDING
  );
  const [headerHeight, setHeaderHeight] = useState(DASHBOARD_TOP_PADDING);
  const [isAddServiceDrawerOpen, setAddServiceDrawerOpen] = useState(false);

  const {
    ordersTotal,
    selectedOrders,
    clearOrderRowsState,
    setBillingsLevelRowsState,
    selectedServicesIds,
    setOrderRowsState,
    orderRowsState,
    widgetMode,
    setWidgetMode
  } = useSettlementDashboardStore(
    ({
      total,
      selectedOrders,
      clearOrderRowsState,
      setBillingsLevelRowsState,
      selectedServicesIds,
      setOrderRowsState,
      orderRowsState,
      widgetMode,
      setWidgetMode
    }) => ({
      ordersTotal: total,
      selectedOrders,
      clearOrderRowsState,
      setBillingsLevelRowsState,
      selectedServicesIds,
      setOrderRowsState,
      orderRowsState,
      widgetMode,
      setWidgetMode
    })
  );
  const { setCurrentCreditNote } = useInvoiceStore(({ setCurrentCreditNote }) => ({
    setCurrentCreditNote
  }));
  const {
    setDrawerClosed,
    setDrawerOpen,
    isCreditNoteDrawerOpen,
    isPaymentDrawerOpen,
    isInvoiceDrawerOpen,
    isPaymentPurposeDrawerOpen,
    isCalculatorDrawerOpen,
    isServiceDrawerOpen,
    isOrderDrawerOpen,
    isDocumentsDrawerOpen,
    isProgramDrawerOpen,
    isEditProgramDrawerOpen
  } = useDrawerStore(({ setDrawerClosed, setDrawerOpen, openDrawers }) => ({
    setDrawerClosed,
    setDrawerOpen,
    isCreditNoteDrawerOpen: openDrawers.includes('creditNote'),
    isPaymentDrawerOpen: openDrawers.includes('payment'),
    isInvoiceDrawerOpen: openDrawers.includes('invoice'),
    isPaymentPurposeDrawerOpen: openDrawers.includes('paymentPurpose'),
    isCalculatorDrawerOpen: openDrawers.includes('calculator'),
    isServiceDrawerOpen: openDrawers.includes('orderService'),
    isOrderDrawerOpen: openDrawers.includes('order'),
    isDocumentsDrawerOpen: openDrawers.includes('documents'),
    isProgramDrawerOpen: openDrawers.includes('flightProgram'),
    isEditProgramDrawerOpen: openDrawers.includes('editFlightProgram')
  }));
  const { setSelectedServices, service } = useOrderStore(({ setSelectedServices, service }) => ({
    setSelectedServices,
    service
  }));
  const { setCurrent: setCurrentPaymentPurpose, setEditModeBase } = usePaymentPurposeStore(
    ({ setCurrent, setEditModeBase }) => ({
      setCurrent,
      setEditModeBase
    })
  );
  const { selectedPrograms, setSelectedPrograms, programsTotal } = useFlightProgramStore(
    ({ selectedPrograms, setSelectedPrograms, list }) => ({
      selectedPrograms,
      setSelectedPrograms,
      programsTotal: list.length
    })
  );
  const { selectedMessages } = useMessageStore(({ selectedMessages }) => ({ selectedMessages }));
  const { isFilterByMessages } = useDashboardStore(({ linkFilters }) => ({
    isFilterByMessages: linkFilters.includes('message-order') && selectedMessages.length > 0
  }));
  const { setOrdersFilter } = useOrdersDashboardStore();

  useOrderDrawerRouting();
  useInvoiceDrawerRouting();
  usePaymentDrawerRouting();

  useEffect(() => () => setSelectedServices([]), []);

  const { isFetching } = useGetSettlementWidget();

  const headerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);

  const setListHeight = () => {
    const headerHeight = headerRef.current?.clientHeight ? headerRef.current?.clientHeight + 17 : 2;
    setHeaderHeight(headerHeight);
    setContainerHeight(window.innerHeight - DASHBOARD_TOP_PADDING - headerHeight);
  };

  useEffect(() => {
    const resize = debounce(setListHeight, 100);
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  useEffect(() => {
    setListHeight();
  }, [isFetching, headerRef.current?.clientHeight]);

  const handleSelectAll = ({ target: { checked } }: CheckboxChangeEvent) => {
    setOrderRowsState(
      orderRowsState.map((row) => ({
        ...row,
        state: checked ? RowState.SELECTED : RowState.EMPTY,
        services: row.services.map((service) => ({
          ...service,
          state: checked ? RowState.SELECTED : RowState.EMPTY
        }))
      }))
    );

    const selectedServices: number[] = [];

    if (checked) {
      orderRowsState.forEach((row) => selectedServices.push(...row.services.map(({ id }) => id)));
    }

    setSelectedServices(selectedServices);
  };

  const handleCloseCreditNote = () => {
    setDrawerClosed('creditNote');
    setCurrentCreditNote(undefined);
  };

  const handleClosePaymentPurpose = () => {
    setDrawerClosed('paymentPurpose');
    setCurrentPaymentPurpose(undefined);
    setEditModeBase(undefined);
  };

  const handleCloseDocuments = () => {
    setDrawerClosed('documents');
  };

  const handleChangeWidgetMode = (mode: SettlementWidgetMode) => {
    if (widgetMode === SettlementWidgetMode.Orders) {
      clearOrderRowsState();
      setBillingsLevelRowsState({});
      setOrdersFilter({});
    }

    if (widgetMode === SettlementWidgetMode.Programs && !!selectedPrograms.length) {
      setSelectedPrograms([]);
    }
    setWidgetMode(mode);
  };

  useEffect(() => {
    service && setDrawerOpen('orderService');
  }, [service]);

  const header = (
    <HeaderWithRef
      ref={headerRef}
      total={ordersTotal}
      showMessageTags={isFilterByMessages && widgetMode !== SettlementWidgetMode.Programs}
      slot={
        <Flex align="center" justify="flex-end" gap={8} style={{ marginTop: '8px' }}>
          <RadioGroup
            defaultValue={widgetMode}
            onOptionChange={handleChangeWidgetMode}
            options={settlementViews}
          />
          <Tooltip
            mouseEnterDelay={1}
            title={`${isFullScreenMode ? 'Close' : 'Open'} full screen mode`}>
            <Button
              size={CONTROL_SIZE}
              icon={
                isFullScreenMode ? (
                  <ShrinkOutlined style={{ transform: 'rotate(45deg)' }} />
                ) : (
                  <ArrowsAltOutlined style={{ transform: 'rotate(45deg)' }} />
                )
              }
              onClick={() => onSetFullScreenMode((prevState) => !prevState)}
            />
          </Tooltip>
        </Flex>
      }
    />
  );

  const footer = (
    <Footer
      showSelected
      showClearSelection={selectedServicesIds.length > 0}
      selectedItemsCount={
        widgetMode === SettlementWidgetMode.Orders ? selectedOrders.length : selectedPrograms.length
      }
      totalItemsCount={widgetMode === SettlementWidgetMode.Orders ? ordersTotal : programsTotal}
      onSelectAll={handleSelectAll}
      widgetType={LinkedEntityTypes.Order}
    />
  );

  return (
    <>
      {!isInvoiceDrawerOpen && (
        <OrderServicesBulkOperationsPopover
          placement="topLeft"
          align={{
            offset: [
              isFullScreenMode && listRef.current ? listRef.current?.clientWidth : -364,
              isFullScreenMode && listRef.current ? listRef.current?.clientHeight - 39 : 0
            ]
          }}
        />
      )}

      <ListWrapper ref={listRef}>
        {widgetMode === SettlementWidgetMode.Orders && (
          <OrdersView
            header={header}
            footer={footer}
            containerHeight={containerHeight}
            isFullScreenMode={isFullScreenMode}
          />
        )}
        {widgetMode === SettlementWidgetMode.Programs && (
          <div style={{ height: containerHeight + 81 }}>
            <ProgramsView header={header} footer={footer} headerHeight={headerHeight} />
          </div>
        )}

        {isInvoiceDrawerOpen && (
          <InvoiceDrawer
            isFullScreenMode={isFullScreenMode}
            setAddServiceDrawerOpen={setAddServiceDrawerOpen}
          />
        )}

        {isPaymentDrawerOpen && <PaymentDrawer isFullScreenMode={isFullScreenMode} />}

        {isOrderDrawerOpen && <OrderDrawer isFullScreenMode={isFullScreenMode} />}

        {isServiceDrawerOpen && <OrderServiceDrawer isFullScreenMode={isFullScreenMode} />}

        {isAddServiceDrawerOpen && (
          <ServicesDrawer
            isOpen={isAddServiceDrawerOpen}
            setOpen={setAddServiceDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}

        {isCalculatorDrawerOpen && <CalculatorDrawer isFullScreenMode={isFullScreenMode} />}

        {isCreditNoteDrawerOpen && (
          <CreditNoteDrawer
            onClose={handleCloseCreditNote}
            isOpen={isCreditNoteDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}

        {isPaymentPurposeDrawerOpen && (
          <PaymentPurposeDrawer
            onClose={handleClosePaymentPurpose}
            isOpen={isPaymentPurposeDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}

        {isDocumentsDrawerOpen && (
          <DocumentsDrawer
            onClose={handleCloseDocuments}
            isOpen={isDocumentsDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}

        {isProgramDrawerOpen && <ProgramDrawer />}

        {isEditProgramDrawerOpen && <ProgramEditDrawer />}
      </ListWrapper>
    </>
  );
};
