/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  LinksRequestBody,
  LinksResponse,
  UpdateLinksResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Links<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get links of entities
   *
   * @tags links
   * @name GetEntityLinks
   * @summary Get entity links
   * @request GET:/links
   * @secure
   */
  getEntityLinks = (
    query?: {
      /** Filter by a list of orderNumbers separated by comma. Example - orderNumbers=51,12,22 etc. */
      orderNumbers?: string;
      /** Filter by a list of flight ids separated by comma. Example - flightIds=1,2,3 */
      flightIds?: string;
      /** Filter by a list of invoice ids separated by comma. Example - invoiceIds=1,2,3 */
      invoiceIds?: string;
      /** Filter by a list of messageIds separated by comma. Example - messageIds=AAAAAQAAGwY,AAAAAQAAAC4 etc. */
      messageIds?: string;
      /** Filter by a list of program ids separated by comma. Example - programIds=1,2,3 */
      programIds?: string;
      /** Filter by a list of credit note ids separated by comma. Example - creditNoteIds=1,2,3 */
      creditNoteIds?: string;
      /** Filter by a list of rebate ids separated by comma. Example - rebateIds=1,2,3 */
      rebateIds?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<LinksResponse, Error>({
      path: `/links`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Link/Unlink entities list to another entity
   *
   * @tags links
   * @name LinkOrUnlinkEntities
   * @summary Link/Unlink entities
   * @request PUT:/links
   * @secure
   */
  linkOrUnlinkEntities = (data: LinksRequestBody, params: RequestParams = {}) =>
    this.http.request<UpdateLinksResponse, ValidationError | Error>({
      path: `/links`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
}
