import { CollapseProps, Descriptions, DescriptionsProps, Flex, Tabs, TabsProps } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React, { useContext } from 'react';
import { DataContext } from '../DictionaryDetails';
import { Counterparty } from 'services/api/data-contracts';
import { CounterpartyContracts } from './components/CounterpartyContracts/CounterpartyContracts';
import { CounterpartyAircrafts } from './components/CounterpartyAircrafts/CounterpartyAircrafts';
import { CounterpartyContacts } from './components/CounterpartyContacts/CounterpartyContacts';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { settingsStore } from 'services/settings/SettingsStore';
import { StyledCollapse } from 'components/common/StyledCollapse';

export const CounterpartyDictionaryView = () => {
  const data = useContext(DataContext) as Counterparty;

  const userRoles = settingsStore.getCurrentTenantRoles();

  const descriptions: DescriptionsProps['items'] = [
    {
      label: 'Name',
      children: data.name
    },
    {
      label: 'Active',
      children: data.isActive ? 'Yes' : 'No'
    },
    {
      label: 'Full name',
      span: 2,
      children: data.fullName
    },
    {
      label: 'Is tenant',
      children: data.linkedTenantId ? 'Yes' : 'No'
    },
    {
      label: 'Tenant name',
      children: data.linkedTenant?.name || '-'
    },
    {
      label: 'Legal form',
      span: 2,
      children: data.legalForm || '-'
    },
    {
      label: 'Country',
      children: data.country?.name || '-'
    },
    {
      label: 'Registration number',
      children: data.registrationNumber || '-'
    },
    {
      label: 'VAT number',
      children: data.vatNumber || '-'
    },
    {
      label: 'Operator',
      children: data.isOperator ? 'Yes' : 'No'
    },
    {
      label: 'ICAO',
      children: data.icao || '-'
    },
    {
      label: 'IATA',
      children: data.iata || '-'
    },
    {
      label: 'Legal address',
      span: 2,
      children: data.legalAddress || '-'
    },
    {
      label: 'Actual address',
      span: 2,
      children: data.actualAddress || '-'
    },
    ...(hasCurrentUserRole([UserRole.Admin])
      ? [
          {
            label: 'OPS notes',
            span: 2,
            children: <span style={{ whiteSpace: 'pre-line' }}>{data.opsNotes}</span>
          },
          {
            label: 'Settlement notes',
            span: 2,
            children: <span style={{ whiteSpace: 'pre-line' }}>{data.settlementNotes}</span>
          }
        ]
      : [
          {
            label: 'Notes',
            span: 2,
            children: (
              <span style={{ whiteSpace: 'pre-line' }}>
                {hasCurrentUserRole([UserRole.Dispatcher]) ? data.opsNotes : data.settlementNotes}
              </span>
            )
          }
        ])
  ];

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'extraNotes',
      label: userRoles.includes(UserRole.Dispatcher) ? 'Settlement notes' : 'OPS notes',
      children: userRoles.includes(UserRole.Dispatcher) ? data.settlementNotes : data.opsNotes
    }
  ];

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: 'Agreements',
      children: <CounterpartyContracts counterpartyId={data.id} />
    },
    ...(data.isOperator
      ? [
          {
            key: '2',
            label: 'Aircrafts',
            children: <CounterpartyAircrafts counterpartyId={data.id} />
          }
        ]
      : []),
    {
      key: '3',
      label: 'Emails',
      children: <CounterpartyContacts counterpartyId={data.id} />
    }
  ];

  if (!data) {
    return null;
  }

  return (
    <Flex vertical gap={20}>
      <Descriptions size={CONTROL_SIZE} items={descriptions} column={2} bordered />

      {(userRoles.includes(UserRole.Dispatcher) || userRoles.includes(UserRole.Settlement)) && (
        <StyledCollapse
          items={collapseContent}
          expandIconPosition="end"
          size={CONTROL_SIZE}
          ghost
        />
      )}

      <Tabs defaultActiveKey="1" items={tabs} style={{ marginBottom: 20 }} size={CONTROL_SIZE} />
    </Flex>
  );
};
