import { MessageListEntry, Type } from 'services/emailEngineApi/data-contracts';
import { AccountInfo } from 'store/messageStore';

export const getUniqueEmails = (data: MessageListEntry[], accountInfo?: AccountInfo): string[] => {
  const emails = new Set<string>();

  data.forEach((email) => {
    email.to?.forEach((to) => {
      const lowerCaseEmail = to.address?.toLowerCase();
      if (lowerCaseEmail && !lowerCaseEmail.startsWith('no-reply@')) {
        emails.add(lowerCaseEmail);
      }
    });

    const lowerCaseFromEmail = email.from?.address?.toLowerCase();

    if (lowerCaseFromEmail && !lowerCaseFromEmail.startsWith('no-reply@')) {
      emails.add(lowerCaseFromEmail);
    }

    if (accountInfo?.type && accountInfo.type === Type.Outlook) {
      emails.add(accountInfo?.email);
    }
  });

  return Array.from(emails);
};
