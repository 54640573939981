export const getFileExtensionFromHeader = (contentDisposition: string) => {
  const match = contentDisposition.match(/filename="(.+)"/);
  let extension = '';

  if (match) {
    const filename = match[1];
    const filenameParts = filename.split('.');
    if (filenameParts.length > 1) {
      extension = filenameParts.pop() as string;
    }
  }

  return extension;
};
