import React, { useCallback, useContext, useMemo } from 'react';
import { ExportPopover } from '../../../../components/OrderDrawer/OrderDetails/common/ExportPopover';
import { CloseButton } from 'components/Drawer';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { useDrawerStore } from 'store/drawerStore';
import { Space } from 'antd';
import { ChangeStatusPopover } from './ChangeStatusPopover';
import { DashboardContext, DashboardType } from 'pages/consts';

export const HeaderButtons = () => {
  const { type: dashboardType } = useContext(DashboardContext);

  const { setCurrent, setOpenProgramId, setOrdersCloneMode } = useFlightProgramStore();
  const { setDrawerClosed } = useDrawerStore(({ setDrawerClosed }) => ({
    setDrawerClosed
  }));

  const isDispatcherDashboard = useMemo(
    () => dashboardType === DashboardType.Dispatcher,
    [dashboardType]
  );

  const handleCloseDrawer = useCallback(() => {
    setDrawerClosed('flightProgram');

    setCurrent(undefined);
    setOrdersCloneMode(false);
    setOpenProgramId(undefined);
  }, [setCurrent, setDrawerClosed]);

  return (
    <Space>
      {isDispatcherDashboard && (
        <>
          <ExportPopover isProgramView />
          <ChangeStatusPopover />
        </>
      )}
      <CloseButton onClick={handleCloseDrawer} />
    </Space>
  );
};
