import React from 'react';
import { DatePicker, TimeRangePickerProps } from 'antd';
import { Dayjs } from 'dayjs';
import { getDateFromInput, isTransformableToDate, getUTCDate } from 'helpers';
import { CONTROL_SIZE } from 'consts/common';
import { RangeValue } from 'store/helpers/types';
import { InputStatus } from 'antd/es/_util/statusUtils';

interface IProps {
  onSetDate: (value: Dayjs) => void;
  onChange?: (value: Dayjs | null) => void;
  format: string;
  placeholder: string;
  showTime?: boolean | { defaultValue: Dayjs };
  name?: string;
  id: string;
  disabled?: boolean;
  autoFocus?: boolean;
  value?: Dayjs;
}

export const StyledDatePicker = ({ onSetDate, ...rest }: IProps) => (
  <DatePicker
    style={{ width: '100%' }}
    size={CONTROL_SIZE}
    data-testid={rest.id}
    onBlur={(e) => {
      const value = (e?.target as HTMLInputElement)?.value;

      if (isTransformableToDate(value)) {
        onSetDate(getDateFromInput(value));
      }
    }}
    {...rest}
  />
);

const rangePresets: TimeRangePickerProps['presets'] = [
  { label: 'Today', value: [getUTCDate(), getUTCDate()] },
  { label: 'This week', value: [getUTCDate(), getUTCDate().add(1, 'w')] },
  { label: 'Two weeks', value: [getUTCDate(), getUTCDate().add(2, 'w')] },
  { label: 'One month', value: [getUTCDate(), getUTCDate().add(1, 'month')] }
];

interface IRangePickerProps {
  value?: RangeValue;
  placeholder?: [string, string];
  status?: InputStatus;
  name?: string;
  onChange?: (value: RangeValue) => void;
  onSetDate: (value: RangeValue) => void;
  format: string;
  showTime?: boolean;
  id?: string;
  labelledby?: string;
  testid?: string;
}

export const StyledDateRangePicker = ({
  onSetDate,
  labelledby,
  testid,
  ...rest
}: IRangePickerProps) => (
  <DatePicker.RangePicker
    aria-labelledby={labelledby}
    data-testid={testid}
    presets={rangePresets}
    size={CONTROL_SIZE}
    style={{ width: '100%' }}
    onBlur={(e) => {
      if (e && e.target) {
        const [from, to] = (e.target as HTMLInputElement).value?.split('-');

        if (isTransformableToDate(from) && isTransformableToDate(to)) {
          onSetDate([getDateFromInput(from), getDateFromInput(to)]);
        }
      }
    }}
    {...rest}
  />
);
