import { groupBy } from 'lodash';
import { UpdateLinksResponse } from 'services/api/data-contracts';
import { settingsStore } from 'services/settings/SettingsStore';

const getFlagsAction = (isAddLinkAction: boolean, shouldSetDoneFlag: boolean) => {
  return {
    ...(isAddLinkAction || shouldSetDoneFlag
      ? {
          add: isAddLinkAction
            ? [
                settingsStore.getFlags().LINKED,
                ...(shouldSetDoneFlag ? [settingsStore.getFlags().DONE] : [])
              ]
            : [settingsStore.getFlags().DONE]
        }
      : {}),
    ...(!isAddLinkAction ? { delete: [settingsStore.getFlags().LINKED] } : {})
  };
};

export const getSingleMessageFlagsUpdateData = (
  responseData: { [key: string]: number },
  shouldSetDoneFlag: boolean
) => {
  const messageId = Object.keys(responseData)[0];
  const numberOfLinks = responseData[messageId];
  const isAddLinkAction = numberOfLinks > 0;
  const action = getFlagsAction(isAddLinkAction, shouldSetDoneFlag);

  return { messageId, data: { flags: action } };
};

export const getBulkMessagesFlagsUpdateData = (
  responseData: UpdateLinksResponse,
  shouldSetDoneFlag: boolean
) => {
  const messagesToUpdate = Object.keys(responseData).map((messageId) => {
    const numberOfLinks = responseData[messageId];
    const isAddLinkAction = numberOfLinks > 0;
    const action = getFlagsAction(isAddLinkAction, shouldSetDoneFlag);

    return { messageId, action };
  });

  const messagesGroupedByFlagUpdateData = groupBy(messagesToUpdate, ({ action }) =>
    JSON.stringify(action)
  );

  return messagesGroupedByFlagUpdateData;
};
