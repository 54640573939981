import React, { forwardRef, ReactNode, useState } from 'react';
import { Button, Flex, Tag } from 'antd';
import { DownOutlined, MailOutlined, SendOutlined, UpOutlined } from '@ant-design/icons';
import { getOrderTerm, getUTCDate, getOrderIcon } from 'helpers';
import { MessageTag, useMessageStore } from 'store/messageStore';
import styled from 'styled-components';
import {
  FlightWidgetItem,
  Order,
  Program,
  SettlementInvoice,
  SettlementOrder
} from 'services/api/data-contracts';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { BsCalendarWeek } from 'react-icons/bs';
import { IoLogoUsd as UsdIcon } from 'react-icons/io5';
import {
  RowState,
  SettlementTableCreditNote,
  useSettlementDashboardStore
} from 'store/settlementDashboardStore';

const StyledTag = styled(Tag)`
  margin-top: 8px;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  gap: 6px;

  span:has(.text) {
    flex-grow: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
`;

const LinkButton = styled(Button)`
  padding: 0 0 0 8px;
  display: flex;
  align-items: center;

  span {
    color: black;
    font-weight: 400;
  }
`;

interface IProps {
  slot?: ReactNode;
  showFlightTags?: boolean;
  showMessageTags?: boolean;
  showOrderTags?: boolean;
  showProgramTags?: boolean;
  showCreditNoteTags?: boolean;
  showInvoiceTags?: boolean;
  showSettlementOrdersTag?: boolean;
  total?: number;
  isSmallExpandButton?: boolean;
}

type TagItem =
  | { type: 'flight'; data: FlightWidgetItem }
  | { type: 'order'; data: Order }
  | { type: 'message'; data: MessageTag }
  | { type: 'program'; data: Program }
  | { type: 'invoice'; data: SettlementInvoice }
  | { type: 'creditNote'; data: SettlementTableCreditNote };

export const Header = ({
  slot,
  showFlightTags,
  showOrderTags,
  showMessageTags,
  showProgramTags,
  showCreditNoteTags,
  showInvoiceTags,
  showSettlementOrdersTag,
  total = 0,
  isSmallExpandButton = false
}: IProps) => {
  const [isTagsOpen, setTagsOpen] = useState(false);

  const { selectedOrders, setSelectedOrders } = useOrdersDashboardStore(
    ({ selectedOrders, setSelectedOrders }) => ({
      selectedOrders,
      setSelectedOrders
    })
  );
  const { selectedFlights, setSelectedFlights } = useFlightsDashboardStore(
    ({ selectedFlights, setSelectedFlights }) => ({
      selectedFlights,
      setSelectedFlights
    })
  );
  const { setSelectedMessages, selectedMessages } = useMessageStore(
    ({ selectedMessages, setSelectedMessages }) => ({
      selectedMessages,
      setSelectedMessages
    })
  );
  const { setSelectedPrograms, selectedPrograms } = useFlightProgramStore(
    ({ setSelectedPrograms, selectedPrograms }) => ({
      setSelectedPrograms,
      selectedPrograms
    })
  );
  const {
    selectedInvoices,
    selectedCreditNotes,
    selectedSettlementOrders,
    setBillingsLevelRowsState,
    billingsLevelRowsState,
    settlementOrders,
    setOrderRowsState,
    orderRowsState
  } = useSettlementDashboardStore(
    ({
      billingsLevelRowsState,
      selectedOrders,
      setBillingsLevelRowsState,
      list,
      setOrderRowsState,
      orderRowsState
    }) => ({
      selectedInvoices: billingsLevelRowsState?.selectedInvoices || [],
      selectedCreditNotes: billingsLevelRowsState?.selectedCreditNotes || [],
      selectedSettlementOrders: selectedOrders,
      setBillingsLevelRowsState,
      billingsLevelRowsState,
      settlementOrders: list,
      setOrderRowsState,
      orderRowsState
    })
  );

  const renderFlightTag = (flight: FlightWidgetItem) => {
    const props = [
      flight.flightNumber,
      flight.aircraft.tailNumber,
      flight.aircraft.type,
      flight.arrivalAirport.code
    ].join(' | ');

    return (
      <StyledTag
        icon={<SendOutlined />}
        closable
        onClose={() => setSelectedFlights(selectedFlights.filter(({ id }) => id !== flight.id))}
        key={flight.id}>
        <span className="text">{props}</span>
      </StyledTag>
    );
  };

  const renderOrderTag = (order: Order | SettlementOrder) => {
    const location = order.location
      ? 'code' in order.location
        ? order.location.code
        : order.location.name
      : undefined;

    const props = [
      order.type,
      location,
      getOrderTerm(getUTCDate(order.startDatetime), getUTCDate(order.endDatetime)),
      order.customer?.name
    ]
      .filter((i) => !!i)
      .join(' | ');

    return (
      <StyledTag
        icon={getOrderIcon(order.type, '#00000073')}
        closable
        onClose={() => {
          if (showSettlementOrdersTag) {
            setOrderRowsState(
              orderRowsState.map((row) => {
                if (row.number === order.number) {
                  return {
                    number: order.number,
                    state: RowState.EMPTY,
                    services: (order as SettlementOrder)?.orderServices?.map((service) => ({
                      id: service?.id,
                      state: RowState.EMPTY
                    }))
                  };
                } else {
                  return row;
                }
              })
            );
          } else {
            setSelectedOrders(selectedOrders.filter(({ number }) => number !== order.number));
          }
        }}
        key={order.number}>
        <span className="text">{props}</span>
      </StyledTag>
    );
  };

  const renderMessageTag = (message: MessageTag) => (
    <StyledTag
      icon={<MailOutlined />}
      closable
      onClose={() => setSelectedMessages(selectedMessages.filter(({ id }) => id !== message.id))}
      key={message.id}>
      <span className="text">{message.subject}</span>
    </StyledTag>
  );

  const renderProgramTag = (program: Program) => {
    const props = [
      program.programNumber,
      program.status,
      getOrderTerm(getUTCDate(program.startDatetime), getUTCDate(program.endDatetime))
    ]
      .filter((i) => !!i)
      .join(' | ');

    return (
      <StyledTag
        icon={<BsCalendarWeek />}
        closable
        onClose={() => setSelectedPrograms(selectedPrograms.filter(({ id }) => id !== program.id))}
        key={program.id}>
        <span className="text">{props}</span>
      </StyledTag>
    );
  };

  const renderCreditNoteTag = (creditNote: SettlementTableCreditNote) => {
    const props = ['CN', creditNote.creditNoteNumber, creditNote.status]
      .filter((i) => !!i)
      .join(' | ');

    return (
      <StyledTag
        icon={<UsdIcon />}
        closable
        onClose={() => {
          const updated = selectedCreditNotes.filter(({ id }) => id !== creditNote.id);

          setBillingsLevelRowsState({
            ...billingsLevelRowsState,
            selectedCreditNotes: updated
          });
        }}
        key={creditNote.id}>
        <span className="text">{props}</span>
      </StyledTag>
    );
  };

  const renderInvoiceTag = (invoice: SettlementInvoice) => {
    const props = ['Inv', invoice.invoiceNumber, invoice.currency].filter((i) => !!i).join(' | ');

    return (
      <StyledTag
        icon={<UsdIcon />}
        closable
        onClose={() => {
          const updated = selectedInvoices.filter(({ id }) => id !== invoice.id);

          setBillingsLevelRowsState({
            ...billingsLevelRowsState,
            selectedInvoices: updated
          });
        }}
        key={invoice.id}>
        <span className="text">{props}</span>
      </StyledTag>
    );
  };

  const orders = selectedSettlementOrders?.map((selected) => {
    const order = settlementOrders?.find((order) => order?.number === selected);
    return order;
  });

  const tags = [
    ...(showFlightTags ? [{ type: 'flight', data: selectedFlights }] : []),
    ...(showOrderTags ? [{ type: 'order', data: selectedOrders }] : []),
    ...(showMessageTags ? [{ type: 'message', data: selectedMessages }] : []),
    ...(showProgramTags ? [{ type: 'program', data: selectedPrograms }] : []),
    ...(showCreditNoteTags ? [{ type: 'creditNote', data: selectedCreditNotes }] : []),
    ...(showInvoiceTags ? [{ type: 'invoice', data: selectedInvoices }] : []),
    ...(showSettlementOrdersTag ? [{ type: 'order', data: orders }] : [])
  ];

  const totaltags = tags.reduce((total, tag) => total + tag.data.length, 0);

  const renderTag = (item: TagItem) => {
    switch (item.type) {
      case 'flight':
        return renderFlightTag(item.data);
      case 'order':
        return renderOrderTag(item.data);
      case 'message':
        return renderMessageTag(item.data);
      case 'program':
        return renderProgramTag(item.data);
      case 'creditNote':
        return renderCreditNoteTag(item.data);
      case 'invoice':
        return renderInvoiceTag(item.data);
      default:
        return null;
    }
  };

  return (
    <>
      {total > 0 && slot}
      {isTagsOpen ? (
        <>
          {tags.flatMap((tag) =>
            tag.data.map((data) => renderTag({ type: tag.type, data } as TagItem))
          )}
          {totaltags > 1 && (
            <LinkButton
              type="link"
              onClick={() => setTagsOpen((prev) => !prev)}
              data-testid="expand-tags">
              Hide <UpOutlined />
            </LinkButton>
          )}
        </>
      ) : (
        <Flex style={{ alignItems: 'baseline' }}>
          {totaltags > 0 && tags[0] && (
            <div style={{ maxWidth: '80%' }}>
              {renderTag({ type: tags[0].type, data: tags[0].data[0] } as TagItem)}
            </div>
          )}
          {totaltags > 1 && (
            <LinkButton
              type="link"
              onClick={() => setTagsOpen((prev) => !prev)}
              data-testid="expand-tags">
              {isSmallExpandButton ? `+${totaltags - 1}` : `${totaltags - 1} more`} <DownOutlined />
            </LinkButton>
          )}
        </Flex>
      )}
    </>
  );
};

export const HeaderWithRef = forwardRef<HTMLDivElement | null, IProps>((props, ref) => (
  <div ref={ref}>
    <Header {...props} />
  </div>
));

HeaderWithRef.displayName = 'HeaderWithRef';
