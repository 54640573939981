import { messagesApi } from 'services/emailEngineApi';
import { MessageUpdate, PageMessages } from 'services/emailEngineApi/data-contracts';
import { settingsStore } from 'services/settings/SettingsStore';
import { message } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';

/**
 * @description to be used for bulk update of messages flags (for example, when initially bulk linking (linksApi) is required)
 */

export const updateMessagesFlagsInBulk = async (
  data: { messageIds: string[]; update: MessageUpdate },
  list: PageMessages,
  singlePath: string
) => {
  const { messageIds, update } = data;

  const account = settingsStore.getMailboxSettings()?.mailbox_name || '';

  try {
    /* 
      putV1AccountAccountMessages does not accept array of emailIds or array of search params,
      range of uids is the only option to get relevant messages
      **/
    const uids = messageIds.map((messageId) => {
      const message = list.find((message) => message.id === messageId);
      if (!message) {
        console.error(`No message found with id ${messageId}`);
      } else {
        return message.uid;
      }
    });

    const concatenatedUids = uids.join(',');

    const response = await messagesApi.putV1AccountAccountMessages(
      account,
      { path: singlePath },
      { search: { uid: concatenatedUids }, update }
    );

    if (!response) {
      message.error('Failed to update messages flags.', MESSAGE_DURATION);
    }

    return response.data;
  } catch (error) {
    console.error('Error during bulk update of messages:', error);
  }
};
