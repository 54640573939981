import React from 'react';
import { InvoiceItemHydrated, InvoiceType } from 'services/api/data-contracts';
import { Button, Empty } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { SortableIssuedInvoiceItemsView } from './SortableIssuedInvoiceItemsView';
import { SortableReceivedInvoiceItemsView } from './SortableReceivedInvoiceItemsView';

export const SpinWrapper = styled('div')`
  height: 126px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  position: relative;

  &:hover .float-btn {
    display: block;
  }

  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 4px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .ant-table-summary .ant-table-cell {
    padding-left: 0 !important;
    border-bottom: none;
  }

  .highlighted {
    border: 1px solid #d9d9d9;
    background-color: #e6f7ff;
  }
`;

interface IProps {
  invoiceId: number;
  invoiceItems: InvoiceItemHydrated[];
  onSetEditMode: (isEditMode: boolean) => void;
}

export const InvoiceItemsView = ({ invoiceId, invoiceItems, onSetEditMode }: IProps) => {
  const { isIssuedInvoice } = useInvoiceStore(({ current }) => ({
    isIssuedInvoice: current?.type === InvoiceType.Issued
  }));

  if (!invoiceItems.length) {
    return (
      <Empty
        description="Invoice items have not been added yet"
        image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <Button size={CONTROL_SIZE} onClick={() => onSetEditMode(true)}>
          Add Invoice items
        </Button>
      </Empty>
    );
  }

  if (isIssuedInvoice) {
    return (
      <SortableIssuedInvoiceItemsView
        invoiceId={invoiceId}
        invoiceItems={invoiceItems}
        onSetEditMode={onSetEditMode}
      />
    );
  }

  return (
    <SortableReceivedInvoiceItemsView
      invoiceId={invoiceId}
      invoiceItems={invoiceItems}
      onSetEditMode={onSetEditMode}
    />
  );
};
