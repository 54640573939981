import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Flex, Input, Row } from 'antd';
import { IFilter } from 'queries/orderServices';
import { getSelectOptions, useCheckboxGroupAutoFocus } from 'helpers';
import { OrderType } from 'services/api/data-contracts';
import { isEqual, omit } from 'lodash';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { orderTypes } from 'consts/order';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { RangeValue } from 'store/helpers/types';
import { ServicesMultipleInput } from 'components/ServicesMultipleInput/ServicesMultipleInput';

interface IProps {
  appliedFilter: IFilter;
  applyFilter: (filter: IFilter) => void;
  isLoading: boolean;
}

export const Filters = ({ appliedFilter, applyFilter, isLoading }: IProps) => {
  const [filter, setFilter] = useState<IFilter>({});

  const checkboxRef = useCheckboxGroupAutoFocus();

  useEffect(() => {
    setFilter(appliedFilter);
  }, [appliedFilter]);

  const handleChangeFilter = (
    name: string,
    value: string | RangeValue | OrderType[] | boolean | { value: number; label: string }[]
  ) => {
    if (value) {
      setFilter((filter) => ({ ...filter, [name]: value }));
    } else {
      setFilter((filter) => omit(filter, name));
    }
  };

  return (
    <div data-testid="services-filters">
      <Row gutter={[8, 12]}>
        <Col span={24}>
          <Checkbox.Group
            options={getSelectOptions(orderTypes)}
            data-testid="order-types-select"
            value={filter.types}
            onChange={(value) => handleChangeFilter('types', value as OrderType[])}
            ref={checkboxRef}
          />
        </Col>

        <Col span={8}>
          <Input
            size={CONTROL_SIZE}
            placeholder="All customers"
            name="customerNames"
            onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
            value={filter.customerNames}
            allowClear
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <Input
            size={CONTROL_SIZE}
            placeholder="All suppliers"
            name="supplierNames"
            onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
            value={filter.supplierNames}
            allowClear
          />
        </Col>
        <Col span={8}>
          <Input
            size={CONTROL_SIZE}
            placeholder="All providers"
            name="providerNames"
            onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
            value={filter.providerNames}
            allowClear
          />
        </Col>

        <Col span={8}>
          <Input
            size={CONTROL_SIZE}
            placeholder="All locations"
            name="locationNames"
            onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
            value={filter.locationNames}
            allowClear
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <Input
            size={CONTROL_SIZE}
            placeholder="All order numbers"
            name="orderNumbers"
            onChange={({ target: { name, value } }) => handleChangeFilter(name, value)}
            value={filter.locationNames}
            allowClear
          />
        </Col>
        <Col span={8}>
          <ServicesMultipleInput
            value={filter.serviceNameIds}
            onChange={(value) => {
              handleChangeFilter('serviceNameIds', value);
            }}
          />
        </Col>
        <Col span={8}>
          <StyledDateRangePicker
            placeholder={['Start from', 'To']}
            value={filter.startDatetime}
            name="startDatetime"
            onChange={(value) => {
              handleChangeFilter('startDatetime', value);
            }}
            format={DATE_FORMAT}
            onSetDate={(value) => {
              handleChangeFilter('startDatetime', value);
            }}
          />
        </Col>

        <Col span={8}>
          <StyledDateRangePicker
            placeholder={['End from', 'To']}
            value={filter.endDatetime}
            name="endDatetime"
            onChange={(value) => {
              handleChangeFilter('endDatetime', value);
            }}
            format={DATE_FORMAT}
            onSetDate={(value) => {
              handleChangeFilter('endDatetime', value);
            }}
          />
        </Col>
      </Row>

      <Flex justify="end">
        <Button
          size={CONTROL_SIZE}
          type="primary"
          onClick={() => applyFilter(filter)}
          disabled={isEqual(appliedFilter, filter)}
          loading={isLoading}>
          Apply
        </Button>
      </Flex>
    </div>
  );
};
