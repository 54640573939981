import React, { useContext, useState } from 'react';
import { Space } from 'antd';
import { usePaymentStore } from 'store/paymentStore';
import { ButtonWithConfirm } from './ButtonWithConfirm';
import { CancelButtonWithConfirm, CloseButton, EditButton } from 'components/Drawer';
import { useMessageStore } from 'store/messageStore';
import { useCloseOnEsc, useCtrlEnterShortcut, useCtrlSShortcut } from 'helpers';
import { CreditNoteStatus, TransactionType } from 'services/api/data-contracts';
import { ChangeStatusButton } from './ChangeStatusButton';
import { DashboardContext, DashboardType } from 'pages/consts';
import { ExportDocumentsButton } from '../../ExportDocumentsButton';
import { ExportPopover } from './ExportPopover';
import { ExportByTemplatePopoverErrorBoundary } from '../../ExportByTemplatePopover/ExportByTemplatePopoverErrorBoundary';

interface IProps {
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  isFullScreenMode?: boolean;
  isWarning?: boolean;
  isFormTouched?: boolean;
}

export const HeaderButtons = ({
  isLoading,
  onSubmit,
  onClose,
  isFullScreenMode,
  isWarning,
  isFormTouched
}: IProps) => {
  const [isReadyForSendingInProgress, setReadyForSendingInProgress] = useState(false);

  const { current, setPaymentEditMode, isPaymentEditMode } = usePaymentStore(
    ({ current, setPaymentEditMode, isPaymentEditMode }) => ({
      current,
      setPaymentEditMode,
      isPaymentEditMode
    })
  );
  const { newMessageMode } = useMessageStore(({ newMessageMode }) => ({ newMessageMode }));

  const { type: dashboardType } = useContext(DashboardContext);

  useCtrlEnterShortcut(() => handleSubmit(true), !newMessageMode);
  useCtrlSShortcut(() => handleSubmit(false), !newMessageMode);
  useCloseOnEsc(onClose, !isPaymentEditMode || !isFormTouched);

  const handleSubmit = async (shouldClose?: boolean) => {
    try {
      await onSubmit();
      shouldClose && onClose();
    } catch (e) {
      console.log(e);
    }
  };

  if (isPaymentEditMode) {
    return (
      <Space>
        <CancelButtonWithConfirm
          onConfirm={onClose}
          isLoading={isLoading}
          isFormTouched={isFormTouched}
        />

        <ButtonWithConfirm
          onSubmit={() => handleSubmit(false)}
          isLoading={isLoading}
          isWarning={isWarning}
          title={current?.id ? 'Save' : 'Create'}
        />

        <ButtonWithConfirm
          onSubmit={() => handleSubmit(true)}
          isLoading={isLoading}
          isWarning={isWarning}
          title={`${current?.id ? 'Save' : 'Create'} and close`}
          isPrimaryButton
        />
      </Space>
    );
  }

  const shouldUsePaymentTemplate =
    current?.transactionType === TransactionType.CreditNote &&
    current?.creditNoteItems?.length === 0;

  const shouldAutoGenerateReport =
    (current?.transactionType === TransactionType.CreditNote && current?.creditNoteItems?.length) ||
    current?.transactionType === TransactionType.Rebate;

  return (
    <Space>
      {dashboardType === DashboardType.Settlement &&
        !isFullScreenMode &&
        current?.creditNoteNumber &&
        current?.creditNoteStatus === CreditNoteStatus.RFSN && (
          <ExportDocumentsButton isLoading={isReadyForSendingInProgress} />
        )}

      {current?.id &&
        (current?.transactionType === TransactionType.Payment || shouldUsePaymentTemplate) && (
          <ExportByTemplatePopoverErrorBoundary>
            <ExportPopover
              isLoading={isLoading}
              paymentId={current.id}
              transactionType={TransactionType.Payment}
            />
          </ExportByTemplatePopoverErrorBoundary>
        )}

      {current?.id && shouldAutoGenerateReport && (
        <ChangeStatusButton
          setReadyForSendingInProgress={setReadyForSendingInProgress}
          transactionType={current?.transactionType}
        />
      )}

      <EditButton
        onClick={() => setPaymentEditMode(true)}
        isLoading={isLoading}
        isDisabled={
          (current?.transactionType === TransactionType.CreditNote &&
            !!current.creditNoteItems?.length) ||
          current?.transactionType === TransactionType.Rebate
        }
      />

      <CloseButton onClick={onClose} />
    </Space>
  );
};
