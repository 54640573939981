import { idTokenStore } from 'services/auth/IdTokenStore';
import { Button, Dropdown, MenuProps, Popconfirm, Space, Spin } from 'antd';
import { getLogoutUrl } from 'services/auth/endpointHelpers';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { accessTokenStore } from 'services/auth/TokenStore';
import { settingsStore } from 'services/settings/SettingsStore';
import { useDrawerStore } from 'store/drawerStore';
import { ItemType } from 'antd/es/menu/interface';
import { useMessageStore } from 'store/messageStore';

interface IProps {
  tenantName: string;
  setTenantName: (name: string) => void;
  onGoHome: () => void;
}

const whiteStyle: React.CSSProperties = {
  color: 'white'
};

export const TenantSelectorAndLogout = ({ tenantName, setTenantName, onGoHome }: IProps) => {
  const currentTenantId = settingsStore.getCurrentTenantId();
  const availableTenants = settingsStore.getTenantSettings();
  const { userName } = idTokenStore.getUserData();
  const { setAccountInfo } = useMessageStore(({ setAccountInfo }) => ({
    setAccountInfo
  }));

  const { closeAllDrawers } = useDrawerStore(({ closeAllDrawers }) => ({ closeAllDrawers }));

  useEffect(() => {
    closeAllDrawers();
  }, [currentTenantId]);

  const handleLogout = () => {
    accessTokenStore.clearToken();
    idTokenStore.clearCookies();
  };

  if (!tenantName) {
    return <Spin />;
  }

  if (availableTenants.length === 1 || !tenantName) {
    return (
      <>
        {userName && <span style={whiteStyle}>{userName}</span>}

        <Popconfirm
          title="Confirm logout please"
          okText="Log out"
          cancelText="Cancel"
          onConfirm={handleLogout}
          okButtonProps={{ href: getLogoutUrl(), style: { marginLeft: 8 } }}>
          <Button shape="circle" icon={<LogoutOutlined />} ghost style={{ border: 'none' }} />
        </Popconfirm>
      </>
    );
  }

  const items: MenuProps['items'] = [
    ...availableTenants.map(
      (tenant) =>
        ({
          label: tenant.name,
          key: tenant.id,
          style: whiteStyle,
          onClick: () => {
            if (tenant.id === currentTenantId) return;
            setAccountInfo(undefined);
            settingsStore.setCurrentTenantId(tenant.id);
            setTenantName(tenant.name);
            onGoHome();
          }
        }) as ItemType
    ),
    {
      type: 'divider',
      style: { backgroundColor: 'rgba(256, 256, 256, 0.3)' }
    },
    {
      label: <a href={getLogoutUrl()}>Log out of the system</a>,
      style: whiteStyle,
      icon: <LogoutOutlined />,
      key: 'logout',
      onClick: handleLogout
    }
  ];

  return (
    <Dropdown
      menu={{ items }}
      dropdownRender={(menu) =>
        React.cloneElement(menu as React.ReactElement, {
          style: { backgroundColor: 'rgb(0, 21, 41)', ...whiteStyle }
        })
      }>
      <Space>
        <span style={whiteStyle}>{tenantName}</span>
        <DownOutlined style={whiteStyle} />
      </Space>
    </Dropdown>
  );
};
