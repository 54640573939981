import { useQuery } from '@tanstack/react-query';
import { settingsStore } from 'services/settings/SettingsStore';
import { useMessageStore } from 'store/messageStore';

import { messagesApi } from 'services/emailEngineApi';

const getAccountMailType = async (account: string) => {
  try {
    const response = await messagesApi.getV1AccountAccount(account);
    return response.data;
  } catch (error) {
    console.error('Error during getting account info:', error);
    throw error;
  }
};

export const useGetMailAccountInfo = () => {
  const account = settingsStore.getMailboxSettings()?.mailbox_name || '';

  const { setAccountInfo } = useMessageStore(({ setAccountInfo }) => ({
    setAccountInfo
  }));
  return useQuery({
    queryKey: ['account', account],
    queryFn: () => getAccountMailType(account),
    onSuccess: async (response) => {
      settingsStore.setFlags(response);

      /* sets outlook type for MSOutlook and imap type for GMail
        subsequent email widget logic depends on mail type
        */
      setAccountInfo({
        account: response.account,
        email: response.email as string,
        type: response.type
      });
    },
    enabled: !!account
  });
};
