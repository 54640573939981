import { DashboardContext, DashboardType } from 'pages/consts';
import { useContext, useMemo } from 'react';
import { getDispatcherColumns } from './dispatcherColumns';
import { getSettlementColumns } from './settlementColumns';
import { Program } from 'services/api/data-contracts';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { SettlementWidgetMode } from 'store/settlementDashboardStore';

export const useColumns = (
  handleEdit: (program: Program) => void,
  setOrdersFilter: (ordersFilter?: OrdersFilter) => void,
  setWidgetMode: (widgetMode?: SettlementWidgetMode) => void
) => {
  const { type: dashboardType } = useContext(DashboardContext);

  const isSettlementDashboard = useMemo(
    () => dashboardType === DashboardType.Settlement,
    [dashboardType]
  );

  const dispatcherColumns = getDispatcherColumns();
  const settlementColumns = getSettlementColumns(handleEdit, setOrdersFilter, setWidgetMode);

  return isSettlementDashboard ? settlementColumns : dispatcherColumns;
};
