import React, { Dispatch, SetStateAction, useState } from 'react';
import { invoicesApi } from 'services/api';
import { InvoiceTemplatesListResponse, MainReportFormat } from 'services/api/data-contracts';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { BalanceReportFormat } from 'pages/widgets/BalanceWidget/components/ExportPopover';
import { useGetInvoiceTemplates } from 'queries/invoice';
import { ExportByTemplatePopover } from 'pages/widgets/components/ExportByTemplatePopover/ExportByTemplatePopover';
import { getInvoiceReportAcceptHeader } from './DocumentDetails/helpers/getAcceptHeader';
import { getFileExtension } from 'pages/widgets/MessagesWidget/components/AttachmentsPreview/utils';
import { message } from 'antd';
import { ExportFormat } from 'pages/widgets/components/DocumentsDrawer/HeaderButtons';

interface IProps {
  invoiceId?: number;
}

export enum InvoiceExportType {
  Pdf = 'pdf',
  MSOffice = 'MSOffice'
}

export const InvoiceExportPopover = ({ invoiceId }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [invoiceExportType, setInvoiceExportType] = useState<InvoiceExportType>(
    InvoiceExportType.Pdf
  );
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);
  const [templateName, setTemplateName] = useState<string | undefined>(undefined);

  const templatesQueryResult = useGetInvoiceTemplates(!!invoiceId);

  const fetchReport = async () => {
    const mainReportFormat = getFileExtension(templateName as string) as MainReportFormat;

    if (!mainReportFormat || !Object.values(MainReportFormat).includes(mainReportFormat)) {
      message.error('Failed to get accepted template format.');
      return;
    }

    const acceptHeader = getInvoiceReportAcceptHeader(invoiceExportType, mainReportFormat);
    const response = await invoicesApi.generateInvoiceReport(
      invoiceId as number,
      {
        templateId: templateId as string,
        isPdf: invoiceExportType === InvoiceExportType.Pdf,
        mainReportFormat
      },
      {
        format: 'arraybuffer',
        headers: {
          Accept: acceptHeader
        }
      }
    );

    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });
    const contentDisposition = response.headers['content-disposition'] || '';
    const match = contentDisposition.match(/filename="(.+)"/);
    const filename = match ? match[1] : 'report.pdf';

    return { blob, filename, contentType };
  };

  return (
    <ExportByTemplatePopover
      entityName="invoice"
      templatesQueryResult={
        templatesQueryResult as UseQueryResult<
          AxiosResponse<InvoiceTemplatesListResponse, unknown>,
          Error
        >
      }
      reportFormats={Object.values(InvoiceExportType)}
      isOpen={isOpen}
      onSetOpen={setOpen}
      templateId={templateId}
      onSetTemplateId={setTemplateId}
      format={invoiceExportType}
      onSetFormat={
        setInvoiceExportType as Dispatch<
          SetStateAction<InvoiceExportType | ExportFormat | BalanceReportFormat>
        >
      }
      onSetTemplateName={setTemplateName}
      onFetchReport={fetchReport}
      isEntityLoading={!invoiceId}
      isGenerateNewFileButton
      tooltip="Generate new file"
    />
  );
};
