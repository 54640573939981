import { Country } from 'services/api/data-contracts';
import { faker } from '@faker-js/faker';

export const getMockCountry = (): Country => {
  const name = faker.location.county();

  return {
    id: faker.number.int(),
    name,
    fullName: name,
    code2: faker.string.sample(2),
    code3: faker.string.sample(3),
    createdAt: faker.date.anytime().toString(),
    updatedAt: faker.date.anytime().toString()
  };
};
