import React from 'react';
import { Flex, Result } from 'antd';
import { isAxiosError } from 'axios';
import { settingsStore } from 'services/settings/SettingsStore';

type IProps = {
  children: React.ReactElement;
};

type IState = {
  hasError: boolean;
  errorCode?: string;
};

export class MessagesErrorBoundary extends React.Component<IProps, IState> {
  static handledErrors = new Set();

  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, errorCode: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    if (isAxiosError(error) && !MessagesErrorBoundary.handledErrors.has(error)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return { hasError: true, errorCode: error.response?.headers?.['X-Amzn-Trace-Id'] };
    }

    if (settingsStore.getCurrentTenantId() && !settingsStore.getMailboxSettings()?.mailbox_name) {
      return { hasError: true, errorCode: 'Mailbox is not set in profile data' };
    }
  }

  componentDidCatch(error: Error) {
    MessagesErrorBoundary.handledErrors.add(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex
          align="center"
          justify="center"
          style={{
            flexGrow: 1,
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            backgroundColor: 'white',
            height: '100%'
          }}>
          <Result
            status="500"
            title="Application error"
            subTitle={
              <>
                <span>Email service is currently unavailable</span>
                <br />
                <span>{this.state.errorCode?.replace('Root=', '')}</span>
              </>
            }
          />
        </Flex>
      );
    }

    return this.props.children;
  }
}
