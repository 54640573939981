import React from 'react';
import { Drawer, Flex, Form, message } from 'antd';
import styled from 'styled-components';
import { useDrawerStore } from 'store/drawerStore';
import { HeaderButtons } from '../../HeaderButtons';
import { useFlightProgramStore } from 'store/flightProgramStore';
import { ProgramUpdateInput, useUpdateFlightProgram } from 'queries/flightProgram';
import { ProgramEditForm } from './ProgramEditForm';
import { BsCalendarWeek } from 'react-icons/bs';
import { MESSAGE_DURATION } from 'consts/common';
import { isEqual } from 'lodash';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    box-shadow:
      0 6px 6px 0 #00000014,
      0 3px 6px -4px #0000001f;
    padding: 8px;
    border: none;
  }

  .ant-drawer-body {
    padding: 24px 8px 8px;
  }
`;

export const ProgramEditDrawer = () => {
  const [form] = Form.useForm<ProgramUpdateInput>();

  const { isDrawerOpen, setDrawerClosed } = useDrawerStore(({ openDrawers, setDrawerClosed }) => ({
    isDrawerOpen: openDrawers.includes('editFlightProgram'),
    setDrawerClosed
  }));
  const { program, setCurrentProgram } = useFlightProgramStore(
    ({ currentToUpdate, setCurrentToUpdate }) => ({
      program: currentToUpdate,
      setCurrentProgram: setCurrentToUpdate
    })
  );

  const updateMutation = useUpdateFlightProgram(program?.id as number);

  const handleCloseDrawer = () => {
    setDrawerClosed('editFlightProgram');
    setCurrentProgram(undefined);
  };

  const handleSubmit = async () => {
    try {
      const data = form.getFieldsValue();
      const hasChanged = !isEqual(data, {
        status: program?.status,
        notes: program?.notes,
        invoicingTerm: program?.invoicingTerm
      });
      if (!hasChanged) {
        message.warning('No changes were made to current program.', MESSAGE_DURATION);
        return;
      }
      await updateMutation.mutateAsync(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        message.error(error.message, MESSAGE_DURATION);
      }
    } finally {
      handleCloseDrawer();
    }
  };

  return (
    <StyledDrawer
      open={isDrawerOpen}
      title={
        <Flex gap={8} align="center">
          <BsCalendarWeek style={{ color: '#00000073' }} />
          <span>Program editing</span>
        </Flex>
      }
      closable={false}
      width="500px"
      extra={
        <HeaderButtons
          isLoading={updateMutation.isLoading}
          onSubmit={handleSubmit}
          onCancel={handleCloseDrawer}
        />
      }
      getContainer={false}>
      {program && (
        <ProgramEditForm
          form={form}
          program={{
            status: program.status,
            invoicingTerm: program.invoicingTerm,
            notes: program.notes
          }}
        />
      )}
    </StyledDrawer>
  );
};
