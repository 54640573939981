import React, { useEffect, useState } from 'react';
import { DashboardContext, DashboardType, ROUTES } from '../consts';
import { settingsStore } from 'services/settings/SettingsStore';
import { Empty, Flex, Table } from 'antd';
import { useGetRebateReport } from 'queries/rebate/useGetRebateReport';
import { RebateReportFilters } from './filters/RebateReportFilters';
import { useRebateReportStore } from 'store/rebateReportStore';
import { CONTROL_SIZE } from 'consts/common';
import { CurrenciesExpandableTable } from './CurrenciesExpandableTable';
import { CreditNoteCreationPopover } from './CreditNoteCreationPopover';
import { InvoiceDrawer } from 'pages/widgets/InvoicesWidget/components/InvoiceDetails/InvoiceDrawer';
import { useDrawerStore } from 'store/drawerStore';
import { ServicesDrawer } from 'pages/widgets/components/ServicesDrawer/ServicesDrawer';
import { PaymentPurposeDrawer } from 'pages/widgets/components/PaymentPurposeDrawer/PaymentPurposeDrawer';
import { CreditNoteDrawer } from 'pages/widgets/components/CreditNotesDrawer/CreditNoteDrawer';
import { CreditNoteWithoutItemsDrawer } from 'pages/BalanceReport/components/CreditNoteWithoutItemsDrawer/CreditNoteWithoutItemsDrawer';
import { PaymentDrawer } from 'pages/widgets/components/PaymentDrawer/PaymentDrawer';
import { CalculatorDrawer } from 'pages/widgets/components/CalculatorDrawer/CalculatorDrawer';
import { DocumentsDrawer } from 'pages/widgets/components/DocumentsDrawer/DocumentsDrawer';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { useInvoiceStore } from 'store/invoiceStore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OrderServiceDrawer } from 'pages/widgets/components/OrderServiceDrawer';
import { useOrderStore } from 'store/orderStore';

const dashboardType = {
  type: DashboardType.Rebate
};

export const RebateReport = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isAddServiceDrawerOpen, setAddServiceDrawerOpen] = useState(false);

  const { setCurrentCreditNote } = useInvoiceStore(({ setCurrentCreditNote }) => ({
    setCurrentCreditNote
  }));
  const { setCurrent: setCurrentPaymentPurpose, setEditModeBase } = usePaymentPurposeStore(
    ({ setCurrent, setEditModeBase }) => ({
      setCurrent,
      setEditModeBase
    })
  );
  const { service } = useOrderStore(({ service }) => ({
    service
  }));
  const { data } = useRebateReportStore();

  const {
    setDrawerClosed,
    closeAllDrawers,
    isNoDrawersOpen,
    isBaseCreditNoteDrawerOpen,
    isCreditNoteDrawerOpen,
    isPaymentDrawerOpen,
    isInvoiceDrawerOpen,
    isPaymentPurposeDrawerOpen,
    isCalculatorDrawerOpen,
    isDocumentsDrawerOpen,
    isServiceDrawerOpen,
    setDrawerOpen
  } = useDrawerStore(({ setDrawerClosed, setDrawerOpen, closeAllDrawers, openDrawers }) => ({
    setDrawerClosed,
    closeAllDrawers,
    isNoDrawersOpen: openDrawers.length === 0,
    isBaseCreditNoteDrawerOpen: openDrawers.includes('creditNoteWithoutInvoiceItems'),
    isCreditNoteDrawerOpen: openDrawers.includes('creditNote'),
    isPaymentDrawerOpen: openDrawers.includes('payment'),
    isInvoiceDrawerOpen: openDrawers.includes('invoice'),
    isPaymentPurposeDrawerOpen: openDrawers.includes('paymentPurpose'),
    isCalculatorDrawerOpen: openDrawers.includes('calculator'),
    isDocumentsDrawerOpen: openDrawers.includes('documents'),
    isServiceDrawerOpen: openDrawers.includes('orderService'),
    setDrawerOpen
  }));

  useGetRebateReport();

  useEffect(() => {
    settingsStore.setCurrentDashboard(DashboardType.Rebate);
  }, []);

  useEffect(() => {
    if (isNoDrawersOpen) {
      navigate({
        pathname: ROUTES.REPORT_REBATE,
        search: searchParams.toString()
      });
    }
  }, [isNoDrawersOpen]);

  useEffect(() => {
    service && setDrawerOpen('orderService');
  }, [service]);

  useEffect(
    () => () => {
      closeAllDrawers();
    },
    []
  );

  const dataSource = (data?.items || []).map((item) => ({ ...item, key: item.id }));

  return (
    <DashboardContext.Provider value={dashboardType}>
      <CreditNoteCreationPopover />

      <Flex vertical gap={20}>
        <RebateReportFilters />

        {data ? (
          <Table
            dataSource={dataSource}
            columns={[{ key: 'customer', render: (_, record) => record.name }]}
            showHeader={false}
            pagination={false}
            key="rebate-report-table"
            expandable={{
              defaultExpandAllRows: true,
              expandedRowRender: (record) => (
                <CurrenciesExpandableTable
                  key={record.id}
                  data={record.currencies || []}
                  customerId={record.id}
                  customerName={record.name}
                />
              ),
              rowExpandable: (record) => !!record.currencies && record.currencies?.length > 0,
              columnWidth: 32
            }}
            size={CONTROL_SIZE}
            footer={
              !!data?.total && data.total > 0 ? () => <span>Total: {data.total}</span> : undefined
            }
          />
        ) : (
          <Flex align="center" justify="center" style={{ width: '100%', height: 500 }}>
            <Empty description="Select a customer to see the report" />
          </Flex>
        )}
      </Flex>
      {isServiceDrawerOpen && <OrderServiceDrawer />}

      {isInvoiceDrawerOpen && <InvoiceDrawer setAddServiceDrawerOpen={setAddServiceDrawerOpen} />}

      {isPaymentDrawerOpen && <PaymentDrawer />}

      {isAddServiceDrawerOpen && (
        <ServicesDrawer isOpen={isAddServiceDrawerOpen} setOpen={setAddServiceDrawerOpen} />
      )}

      {isCalculatorDrawerOpen && <CalculatorDrawer />}

      {isBaseCreditNoteDrawerOpen && (
        <CreditNoteWithoutItemsDrawer
          onClose={() => {
            setDrawerClosed('creditNoteWithoutInvoiceItems');
            setCurrentCreditNote(undefined);
          }}
          isOpen={isBaseCreditNoteDrawerOpen}
        />
      )}

      {isCreditNoteDrawerOpen && (
        <CreditNoteDrawer
          onClose={() => {
            setDrawerClosed('creditNote');
            setCurrentCreditNote(undefined);
          }}
          isOpen={isCreditNoteDrawerOpen}
        />
      )}

      {isPaymentPurposeDrawerOpen && (
        <PaymentPurposeDrawer
          onClose={() => {
            setDrawerClosed('paymentPurpose');
            setCurrentPaymentPurpose(undefined);
            setEditModeBase(undefined);
          }}
          isOpen={isPaymentPurposeDrawerOpen}
        />
      )}

      {isDocumentsDrawerOpen && (
        <DocumentsDrawer
          onClose={() => {
            setDrawerClosed('documents');
          }}
          isOpen={isDocumentsDrawerOpen}
        />
      )}
    </DashboardContext.Provider>
  );
};
