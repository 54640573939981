import React, { Dispatch, SetStateAction, useState } from 'react';
import { Upload, message, Progress } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { invoicesApi } from 'services/api';
import { RcFile, UploadProps } from 'antd/es/upload';
import { MESSAGE_DURATION } from 'consts/common';
import { ALLOWED_USER_UPLOAD_MIME_TYPES } from 'queries/documents/consts';
import { MIME_Type } from 'pages/consts';
import { DocumentActionResult } from 'pages/widgets/InvoicesWidget/components/InvoiceDetails/DocumentDetails/DocumentsDetails';

interface IProps {
  entityName: 'Invoices' | 'CreditNotes' | 'Payments';
  onSetFileUploadStatus: Dispatch<SetStateAction<DocumentActionResult>>;
  relatedId?: number;
  counterpartyId?: number;
}

type CustomRequestOptions = Parameters<NonNullable<UploadProps['customRequest']>>[0];

const { Dragger } = Upload;

export const DraggerFileUpload = ({
  relatedId,
  counterpartyId,
  entityName,
  onSetFileUploadStatus
}: IProps) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const customUpload = async ({ file }: CustomRequestOptions) => {
    const document = file as RcFile;
    setUploadPercentage(10);

    try {
      const {
        data: { url, headers }
      } = await invoicesApi.prepareUploadFile({
        relatedId,
        filename: document.name,
        counterpartyId,
        entityName
      });

      setUploadPercentage(30);

      if (!url || !headers) {
        throw new Error(
          `Upload of file  ${document.name} failed. Upload URL or headers not provided by server.`
        );
      }

      setUploadPercentage(50);

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': document.type
        },
        body: file
      });

      if (!response.ok) {
        throw new Error(`Upload of file ${document.name} failed.`);
      }

      onSetFileUploadStatus({
        isError: false,
        isSuccess: true,
        filename: document.name,
        isDeleteAction: false
      });
    } catch (error) {
      if (error instanceof Error) {
        onSetFileUploadStatus({
          isError: true,
          isSuccess: false,
          filename: document.name,
          isDeleteAction: false
        });
      }
      console.error(error);
    } finally {
      setUploadPercentage(0);
    }
  };

  const isTypeAllowed = (type: MIME_Type | string) =>
    Object.values(ALLOWED_USER_UPLOAD_MIME_TYPES).includes(type as MIME_Type);

  const validateFile = (file: RcFile) => {
    if (!isTypeAllowed(file.type)) {
      message.error(
        'Current format is not supported. Please upload pdf, docx, jpeg, png or gif file',
        MESSAGE_DURATION
      );
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <Dragger
      customRequest={customUpload}
      beforeUpload={validateFile}
      showUploadList={false}
      style={{ margin: 0 }}>
      <div
        style={{
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {!!uploadPercentage ? (
          <Progress percent={uploadPercentage} />
        ) : (
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
        )}
      </div>
      <p className="ant-upload-text">Upload one file at a time please</p>
      <p className="ant-upload-hint">Click or drag file to this area to upload</p>
    </Dragger>
  );
};
