import { Drawer, Form } from 'antd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { SERVER_DATE_FORMAT } from 'consts/common';
import styled from 'styled-components';
import {
  Payment,
  PaymentFullyHydrated,
  PaymentHydrated,
  PaymentStatus,
  TransactionType
} from 'services/api/data-contracts';
import { PaymentCreationMode, usePaymentStore } from 'store/paymentStore';
import { useCreatePayment, useUpdatePayment } from 'queries/payment';
import { HeaderButtons } from './HeaderButtons/HeaderButtons';
import { getUTCDate } from 'helpers';
import { PaymentWithDayjs } from 'consts/payment';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { PaymentDrawerContent } from './PaymentDrawerContent';
import { PaymentDrawerTitle } from './PaymentDrawerTitle';
import { useParams } from 'react-router-dom';
import { FormInstance } from 'antd/es/form';
import { DashboardContext, DashboardType } from '../../../consts';
import { DetailsErrorBoundary } from 'components/ErrorBoundary/DetailsErrorBoundary';
import { useDrawerStore } from 'store/drawerStore';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 8px;
  }

  .ant-drawer-header {
    border: none;
    padding: 8px;
  }
`;

interface IProps {
  isFullScreenMode?: boolean;
}

export const FormContext = createContext<{
  form: FormInstance<PaymentWithDayjs> | null;
  isTouched: boolean;
  setTouched?: React.Dispatch<boolean>;
}>({ form: null, isTouched: false });

export const PaymentDrawer = ({ isFullScreenMode }: IProps) => {
  const [form] = Form.useForm<PaymentWithDayjs>();
  const [isTouched, setTouched] = useState(form.isFieldsTouched());
  const isPosted = Form.useWatch('posted', form);

  const { entityId } = useParams();

  const { type: dashboardType } = useContext(DashboardContext);

  const { current, setCurrent, setPaymentEditMode, paymentCreationMode } = usePaymentStore(
    ({ current, setCurrent, setPaymentEditMode, paymentCreationMode }) => ({
      current: current as PaymentFullyHydrated,
      setCurrent,
      setPaymentEditMode,
      paymentCreationMode
    })
  );
  const { setCreationMode: setPaymentPurposeCreationMode } = usePaymentPurposeStore(
    ({ setCreationMode }) => ({
      setCreationMode
    })
  );
  const { isPaymentDrawerOpen, setDrawerClosed } = useDrawerStore(
    ({ openDrawers, setDrawerClosed }) => ({
      isPaymentDrawerOpen: openDrawers.includes('payment'),
      setDrawerClosed
    })
  );

  useEffect(() => {
    if (current) {
      form.setFieldsValue({
        ...current,
        sentDate: current.sentDate ? getUTCDate(current.sentDate) : undefined,
        receivedDate: current.receivedDate ? getUTCDate(current.receivedDate) : undefined,
        plannedDate: current.plannedDate ? getUTCDate(current.plannedDate) : undefined
      });
    }
  }, [current]);

  useEffect(() => {
    if (!isPaymentDrawerOpen) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [isPaymentDrawerOpen]);

  const handleAddPaymentPurpose = (payment?: PaymentHydrated) => {
    if (
      paymentCreationMode === PaymentCreationMode.FROM_INVOICE &&
      payment?.status === PaymentStatus.DONE &&
      payment.posted &&
      payment.transactionType !== TransactionType.CreditNote
    ) {
      setPaymentPurposeCreationMode(true);
      setCurrent(payment);
    }
  };

  const updateMutation = useUpdatePayment(() => setPaymentEditMode(false));
  const createMutation = useCreatePayment((payment) => {
    setPaymentEditMode(false);
    setCurrent(payment);
    handleAddPaymentPurpose();
  });

  const handleSubmit = async () => {
    await form.validateFields();

    const data = form.getFieldsValue();

    const requestData: Payment = {
      ...data,
      transactionType: current.transactionType,
      sentDate: data.sentDate ? data.sentDate.format(SERVER_DATE_FORMAT) : undefined,
      receivedDate: data.receivedDate ? data.receivedDate.format(SERVER_DATE_FORMAT) : undefined,
      plannedDate: data.plannedDate ? data.plannedDate.format(SERVER_DATE_FORMAT) : undefined
    };

    if (current?.id) {
      updateMutation.mutate({ ...requestData, id: current.id });
    } else {
      createMutation.mutate(requestData);
    }
  };

  const handleCloseDrawer = () => {
    form.setFieldsValue({});
    form.resetFields();

    setCurrent(undefined);
    setPaymentEditMode(false);
    setPaymentPurposeCreationMode(false);

    setDrawerClosed('payment');
  };

  const isLoading = createMutation.isLoading || updateMutation.isLoading;
  const isRenderInContainer = dashboardType === DashboardType.Settlement && !isFullScreenMode;

  return (
    <StyledDrawer
      open={isPaymentDrawerOpen}
      title={<PaymentDrawerTitle isLoading={isLoading} />}
      closable={false}
      width={740}
      extra={
        <HeaderButtons
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onClose={handleCloseDrawer}
          isFormTouched={isTouched}
          isWarning={!isPosted}
          isFullScreenMode={isFullScreenMode}
        />
      }
      {...(isRenderInContainer ? { getContainer: false } : {})}>
      <DetailsErrorBoundary
        message="Please check the provided URL or select the payment in a table"
        key={entityId}>
        <FormContext.Provider value={{ form, isTouched, setTouched }}>
          <PaymentDrawerContent isLoading={isLoading} />
        </FormContext.Provider>
      </DetailsErrorBoundary>
    </StyledDrawer>
  );
};
