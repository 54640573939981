import { useQuery } from '@tanstack/react-query';
import { MESSAGES_QUERY_KEY } from './consts';
import { settingsStore } from 'services/settings/SettingsStore';
import { MailboxSpecialUse, Type } from 'services/emailEngineApi/data-contracts';
import { useMessageStore } from 'store/messageStore';

import { messagesApi } from 'services/emailEngineApi';

const getAccountMailboxes = async (account: string) => {
  try {
    const response = await messagesApi.getV1AccountAccountMailboxes(account);

    return response.data;
  } catch (error) {
    console.error('Error during getting message account path:', error);
    throw error;
  }
};

export const useGetMailboxPath = () => {
  const account = settingsStore.getMailboxSettings()?.mailbox_name || '';

  const { setAllMailGmailPath, setInboxOutlookPath, setSentOutlookPath, accountInfo } =
    useMessageStore(
      ({ setAllMailGmailPath, setInboxOutlookPath, setSentOutlookPath, accountInfo }) => ({
        setAllMailGmailPath,
        setInboxOutlookPath,
        setSentOutlookPath,
        accountInfo
      })
    );
  return useQuery({
    queryKey: [MESSAGES_QUERY_KEY, account],
    queryFn: () => getAccountMailboxes(account),
    onSuccess: async (response) => {
      if (accountInfo?.type === Type.Imap) {
        const allMail = response?.mailboxes?.find(
          (mailbox) => mailbox.specialUse === (`\\${MailboxSpecialUse.ValueAll}` as string)
        );
        /* language gmail account settings specific All mail path */
        allMail?.path && setAllMailGmailPath(allMail?.path);
      }

      if (accountInfo?.type === Type.Outlook) {
        const inbox = response?.mailboxes?.find(
          (mailbox) => mailbox.specialUse === (`\\${MailboxSpecialUse.ValueInbox}` as string)
        );
        const sent = response?.mailboxes?.find(
          (mailbox) => mailbox.specialUse === (`\\${MailboxSpecialUse.ValueSent}` as string)
        );

        /* Outlook Inbox and Sent folders path */
        inbox?.path && setInboxOutlookPath(inbox?.path);
        sent?.path && setSentOutlookPath(sent?.path);
      }
    },
    enabled: !!account && !!accountInfo?.type
  });
};
