import React from 'react';
import { omit } from 'lodash';
import { InvoiceFilter } from 'store/settlementDashboardStore';
import { FilterTag } from '../../../components/FilterTag';
import { AuditOutlined } from '@ant-design/icons';

export const getAppliedInvoiceFilters = (
  filter: InvoiceFilter,
  setFilter: (filter: Record<string, unknown>) => void,
  setInvoiceFilter: (filter: InvoiceFilter) => void
) =>
  Object.keys(filter)
    .map((filterKey) => {
      const key = filterKey as keyof typeof filter;
      let value: string | boolean | undefined;

      switch (key) {
        case 'invoiceStatuses':
          value = filter[key]?.join(', ');
          break;
        case 'isPosted':
          if (filter[key]) {
            value = 'Is posted';
          } else {
            value = '';
          }
          break;
        default:
          value = filter[key];
          break;
      }

      const onClose = () => {
        setFilter(omit(filter, key));
        setInvoiceFilter(omit(filter, key));
      };

      const icon =
        key.toLowerCase().includes('time') || key.toLowerCase().includes('date') ? (
          <AuditOutlined style={{ color: '#8C8C8C' }} />
        ) : undefined;

      return value ? (
        <FilterTag name={key} value={value} onClose={onClose} key={key} icon={icon} />
      ) : null;
    })
    .filter(Boolean);
