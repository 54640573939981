import { Dropdown, FloatButton, MenuProps } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PercentageOutlined,
  PlusOutlined
} from '@ant-design/icons';
import React, { useMemo } from 'react';
import {
  ContractType,
  InvoiceType,
  PaymentHydrated,
  TransactionType
} from 'services/api/data-contracts';
import { getInvoiceData } from './helpers/getInvoiceData';
import { MdAttachMoney } from 'react-icons/md';
import { useBalanceReportStore } from 'store/balanceReportStore';
import { usePaymentStore } from 'store/paymentStore';
import { useInvoiceStore } from 'store/invoiceStore';
import { getPaymentData } from './helpers/getPaymentData';
import { useGetContractsByCounterparty } from 'queries/contract';
import { useDrawerStore } from 'store/drawerStore';

export const AddNewButton = () => {
  const { filter } = useBalanceReportStore(({ filter }) => ({ filter }));
  const { setPayment, setPaymentEditMode } = usePaymentStore(
    ({ setCurrent, setPaymentEditMode }) => ({
      setPayment: setCurrent,
      setPaymentEditMode
    })
  );
  const { setInvoiceEditMode, setInvoice, setCurrentCreditNote } = useInvoiceStore(
    ({ setInvoiceEditMode, setCurrent, setCurrentCreditNote }) => ({
      setInvoiceEditMode,
      setInvoice: setCurrent,
      setCurrentCreditNote
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({
    setDrawerOpen
  }));

  const { data } = useGetContractsByCounterparty(filter.counterpartyId);
  const contracts = useMemo(() => data?.data.items || [], [data]);

  const isMenuItemDisabled = (contractType: ContractType): boolean => {
    const isFilteredByCounterparty = !!filter.counterpartyId;
    const isEveryContractOfType = contracts.every((contract) => contract.type === contractType);
    const isEveryFilteredContractOfType = filter.contractIds
      ?.split(',')
      .every((id) => contracts.find((contract) => contract.id === +id)?.type === contractType);

    return isFilteredByCounterparty && (isEveryContractOfType || !!isEveryFilteredContractOfType);
  };

  const items: MenuProps['items'] = [
    {
      key: 'Invoice',
      type: 'group',
      label: 'Invoice',
      children: [
        {
          key: 'Invoice-Issued',
          label: 'Issued',
          icon: <ArrowLeftOutlined />,
          disabled: isMenuItemDisabled(ContractType.WithSupplier),
          onClick: () => {
            setInvoice(
              getInvoiceData({
                type: InvoiceType.Issued,
                filter,
                contracts
              })
            );
            setInvoiceEditMode(true);
            setDrawerOpen('invoice');
          }
        },
        {
          key: 'Invoice-Received',
          label: 'Received',
          icon: <ArrowRightOutlined />,
          disabled: isMenuItemDisabled(ContractType.WithCustomer),
          onClick: () => {
            setInvoice(
              getInvoiceData({
                type: InvoiceType.Received,
                filter,
                contracts
              })
            );
            setInvoiceEditMode(true);
            setDrawerOpen('invoice');
          }
        }
      ]
    },
    {
      type: 'divider'
    },
    {
      label: 'Payment',
      key: 'Payment',
      icon: <MdAttachMoney style={{ height: '16px', width: '16px' }} />,
      onClick: () => {
        setPayment(getPaymentData({ filter, contracts }));
        setPaymentEditMode(true);
        setDrawerOpen('payment');
      }
    },
    {
      label: 'Credit note',
      key: 'Credit note',
      icon: <PercentageOutlined />,
      onClick: () => {
        setCurrentCreditNote({
          ...(getPaymentData({ filter, contracts }) as PaymentHydrated),
          transactionType: TransactionType.CreditNote
        });
        setDrawerOpen('creditNoteWithoutInvoiceItems');
      }
    }
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <FloatButton
        type="primary"
        style={{ zIndex: 800, bottom: 70, right: 30 }}
        icon={<PlusOutlined />}
      />
    </Dropdown>
  );
};
