import React, { useEffect, useMemo } from 'react';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { CollapseProps, Flex } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { InvoiceItemsView } from './InvoiceItemsView/InvoiceItemsView';
import { InvoiceItemsForm } from './InvoiceItemsForm';
import { useInvoiceStore } from 'store/invoiceStore';
import { blue } from '@ant-design/colors';
import { StyledBadge } from 'components/common/StyledBadge';
import { sortBy } from 'lodash';

export const InvoiceItemsBlock = () => {
  const { current, isInvoiceItemsEditMode, setInvoiceItemsEditMode } = useInvoiceStore(
    ({ current, isInvoiceItemsEditMode, setInvoiceItemsEditMode }) => ({
      current,
      isInvoiceItemsEditMode,
      setInvoiceItemsEditMode
    })
  );

  useEffect(() => () => setInvoiceItemsEditMode(false), []);

  const invoiceItems = useMemo(
    () =>
      sortBy(current?.invoiceItems, 'agentFee').map((item) => ({
        ...item,
        unitOfMeasureId: item.unitOfMeasure.id
      })) || [],
    [current]
  );

  const collapseContent: CollapseProps['items'] = [
    {
      key: 'items',
      label: (
        <Flex gap={4} align="center">
          Invoice items
          <StyledBadge count={invoiceItems.length} color={blue[0]} />
        </Flex>
      ),
      children: isInvoiceItemsEditMode ? (
        <InvoiceItemsForm data={invoiceItems} onSetEditMode={setInvoiceItemsEditMode} />
      ) : (
        <InvoiceItemsView
          invoiceId={current?.id as number}
          invoiceItems={invoiceItems}
          onSetEditMode={setInvoiceItemsEditMode}
        />
      )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      defaultActiveKey="items"
      ghost
    />
  );
};
