import { useMutation, useQueryClient } from '@tanstack/react-query';
import { linksApi } from 'services/api';
import { LinkActionTypes, LinkedEntityTypes, LinksRequestBody } from 'services/api/data-contracts';
import { QUERY_KEY } from './consts';
import { message } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';
import { getEntityIdFromTarget, getEntityIdsFromSource } from './helpers/getEntityId';
import { updateMessagesFlagsInBulk } from 'queries/messages/updateMessagesFlagsInBulk';
import {
  getBulkMessagesFlagsUpdateData,
  getSingleMessageFlagsUpdateData
} from './helpers/getMessageFlagsUpdateData';
import { useMessageStore } from 'store/messageStore';
import { MESSAGES_UI_UPDATE_DELAY, MESSAGES_QUERY_KEY } from 'queries/messages/consts';
import { settingsStore } from 'services/settings/SettingsStore';
import { updateMessage } from 'queries/messages/useUpdateMessage';
import { Type } from 'services/emailEngineApi/data-contracts';

export const updateItem = async (data: LinksRequestBody) => {
  const isSourceValid =
    data.action === LinkActionTypes.UnlinkAll || (data.source && data.source.length > 0);

  const isTargetValid =
    data.action !== LinkActionTypes.UnlinkAll ||
    data.target.every((target) => target.entityType === LinkedEntityTypes.Message);

  if (!isSourceValid || !isTargetValid) {
    console.error('Source or target data is invalid. Skipping API call.');
    return;
  }

  try {
    return await linksApi.linkOrUnlinkEntities(data);
  } catch (error) {
    console.error('Failed to link or unlink entities:', error);
    throw error;
  }
};

/**
 * @description NOTE: Please send messages in a 'target' section
 */
export const useUpdateMessageLinks = ({
  isMessagesInBulkFlagsUpdate = false,
  onSuccess,
  shouldSetDoneFlag = false
}: {
  isMessagesInBulkFlagsUpdate?: boolean;
  onSuccess?: () => void;
  shouldSetDoneFlag?: boolean;
} = {}) => {
  const queryClient = useQueryClient();

  const mailboxConfig = settingsStore.getMailboxSettings();

  const {
    list,
    messagesFilter,
    linkedMessagesIdsFilter,
    allMailGmailPath,
    inboxOutlookPath,
    sentOutlookPath,
    accountInfo
  } = useMessageStore(
    ({
      list,
      messagesFilter,
      linkedMessagesIdsFilter,
      allMailGmailPath,
      inboxOutlookPath,
      sentOutlookPath,
      accountInfo
    }) => ({
      list,
      messagesFilter,
      linkedMessagesIdsFilter,
      allMailGmailPath,
      inboxOutlookPath,
      sentOutlookPath,
      accountInfo
    })
  );

  const path =
    accountInfo?.type === Type.Outlook ? [inboxOutlookPath, sentOutlookPath] : [allMailGmailPath];

  return useMutation({
    mutationFn: updateItem,
    onSuccess: async (response, variables) => {
      if (variables.action !== LinkActionTypes.UnlinkAll && variables.source) {
        const filterSource = getEntityIdsFromSource(variables.source);
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY, filterSource] });
      }

      for (const target of variables.target) {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEY, getEntityIdFromTarget(target)]
        });
      }

      const handleInvalidateQueries = async () => {
        await queryClient.invalidateQueries({
          queryKey: [MESSAGES_QUERY_KEY, messagesFilter, mailboxConfig, linkedMessagesIdsFilter]
        });
        onSuccess && onSuccess();
      };

      if (response?.data) {
        if (!isMessagesInBulkFlagsUpdate) {
          const flagsUpdate = getSingleMessageFlagsUpdateData(response.data, shouldSetDoneFlag);
          await updateMessage(flagsUpdate);
          setTimeout(handleInvalidateQueries, MESSAGES_UI_UPDATE_DELAY);
          return;
        }

        const groupedUpdateData = getBulkMessagesFlagsUpdateData(response.data, shouldSetDoneFlag);
        const keys = Object.keys(groupedUpdateData);
        const hasSingleFlagUpdateGroup = keys.length === 1;

        if (hasSingleFlagUpdateGroup) {
          const flagsString = keys[0];
          const messageIds = groupedUpdateData[flagsString].map(({ messageId }) => messageId);
          const update = { flags: JSON.parse(flagsString) };

          /* 
          Path is required for this request. For outlook have to make requests for inbox and sent
          */
          await Promise.all(
            path.map((singlePath) =>
              updateMessagesFlagsInBulk({ messageIds, update }, list, singlePath as string)
            )
          );
          setTimeout(handleInvalidateQueries, MESSAGES_UI_UPDATE_DELAY);
        } else {
          for (const flagsString in groupedUpdateData) {
            const messageIds = groupedUpdateData[flagsString].map(({ messageId }) => messageId);
            const update = { flags: JSON.parse(flagsString) };

            /* 
          Path is required for this request. For outlook have to make requests for inbox and sent
          */
            await Promise.all(
              path.map((singlePath) =>
                updateMessagesFlagsInBulk({ messageIds, update }, list, singlePath as string)
              )
            );
          }
          await handleInvalidateQueries();
        }
      }
    },
    onError: (error) => {
      message.error(
        `An error occurred during linked status update for message, reason of email service unavailability: ${error}`,
        MESSAGE_DURATION
      );
    }
  });
};
