import { MIME_Type } from 'pages/consts';

export const QUERY_KEY = 'documents';

export const ALLOWED_USER_UPLOAD_MIME_TYPES = {
  JPEG: MIME_Type.Jpeg,
  GIF: MIME_Type.Gif,
  PNG: MIME_Type.Png,
  DOCX: MIME_Type.Docx,
  PDF: MIME_Type.Pdf
};
