import React from 'react';
import { Spin, Tabs } from 'antd';
import { PaymentDetails } from './PaymentDetails/PaymentDetails';
import { useGetPayment } from 'queries/payment';
import styled from 'styled-components';
import { usePaymentStore } from 'store/paymentStore';
import {
  LinkedEntityTypes,
  PaymentFullyHydrated,
  TransactionType
} from 'services/api/data-contracts';
import { Links } from '../Links/Links';
import { useGetLinks } from 'queries/links/useGetLinks';
import { DocumentsErrorBoundary } from '../DocumentsDrawer/DocumentsErrorBoundary';
import { TabsProps } from 'antd/es/tabs';
import { CONTROL_SIZE } from '../../../../consts/common';
import { DocumentDetails } from './PaymentDetails/DocumentDetails/DocumentsDetails';

const SpinWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinksWrapper = styled('div')`
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
  margin: -12px -8px 8px;
  width: calc(100% + 16px);
  padding: 0 8px 4px;
`;

interface IProps {
  isLoading: boolean;
}

export const PaymentDrawerContent = ({ isLoading }: IProps) => {
  const { current, isPaymentEditMode } = usePaymentStore(({ current, isPaymentEditMode }) => ({
    current: current as PaymentFullyHydrated,
    isPaymentEditMode
  }));

  const { isFetching } = useGetPayment(current?.id);
  const linksQuery = useGetLinks(
    { creditNoteIds: current?.id?.toString() },
    !!current?.id && current?.transactionType === TransactionType.CreditNote
  );

  const hasLinks = (linksQuery.data?.data.messages || []).length > 0;

  if (isLoading || isFetching) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  const detailsTab = {
    key: '1',
    label: 'Details',
    children:
      isLoading || isFetching ? (
        <SpinWrapper>
          <Spin />
        </SpinWrapper>
      ) : (
        <>
          {current?.transactionType === TransactionType.CreditNote && hasLinks && (
            <LinksWrapper>
              <Links
                targetEntityType={LinkedEntityTypes.CreditNote}
                targetEntityId={current?.id}
                isLoading={linksQuery.isFetching}
                linkedMessages={linksQuery.data?.data.messages}
              />
            </LinksWrapper>
          )}

          <div style={{ marginTop: '16px' }}>
            <PaymentDetails payment={current} />
          </div>
        </>
      )
  };

  const documentsTab =
    !isPaymentEditMode &&
    (current.transactionType === TransactionType.CreditNote ||
      current.transactionType === TransactionType.Rebate)
      ? {
          key: '2',
          label: 'Documents',
          children: (
            <DocumentsErrorBoundary>
              <DocumentDetails />
            </DocumentsErrorBoundary>
          )
        }
      : null;

  const tabs: TabsProps['items'] = [detailsTab].concat(documentsTab ? [documentsTab] : []);

  if (isPaymentEditMode) {
    return (
      <>
        {isLoading || isFetching ? (
          <SpinWrapper>
            <Spin />
          </SpinWrapper>
        ) : (
          <PaymentDetails payment={current} />
        )}
      </>
    );
  } else {
    return (
      <>
        {hasLinks && (
          <LinksWrapper>
            <Links
              targetEntityType={LinkedEntityTypes.CreditNote}
              targetEntityId={current?.id}
              isLoading={linksQuery.isFetching}
              linkedMessages={linksQuery.data?.data.messages}
            />
          </LinksWrapper>
        )}
        <Tabs defaultActiveKey="1" items={tabs} style={{ marginBottom: 20 }} size={CONTROL_SIZE} />
      </>
    );
  }
};
