import React, { useEffect } from 'react';
import { ConfigProvider, Drawer, Form } from 'antd';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { useCreatePayment } from 'queries/payment';
import { queryClient } from 'queries';
import { BALANCE_WIDGET_QUERY_KEY } from 'queries/balance/consts';
import { HeaderButtons } from 'pages/widgets/components/HeaderButtons';
import { CreditNoteForm, CreditNoteWithoutInvoiceItems } from './CreditNoteForm';
import { transformForRequest } from './helpers/transformForRequest';
import { getUTCDate } from 'helpers';
import { useDrawerStore } from 'store/drawerStore';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    box-shadow:
      0 6px 6px 0 #00000014,
      0 3px 6px -4px #0000001f;
    padding: 8px;
  }

  .ant-drawer-body {
    padding: 24px 8px 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreditNoteWithoutItemsDrawer = ({ isOpen, onClose }: IProps) => {
  const [form] = Form.useForm<CreditNoteWithoutInvoiceItems>();

  const { currentCreditNote: creditNote } = useInvoiceStore(({ currentCreditNote }) => ({
    currentCreditNote
  }));

  const { isBaseCreditNoteDrawerOpen } = useDrawerStore(({ openDrawers }) => ({
    isBaseCreditNoteDrawerOpen: openDrawers.includes('creditNoteWithoutInvoiceItems')
  }));

  useEffect(() => {
    if (creditNote) {
      form.setFieldsValue({
        ...creditNote,
        receivedDate: creditNote.receivedDate ? getUTCDate(creditNote.receivedDate) : undefined
      });
    }
  }, [creditNote]);

  useEffect(() => {
    if (!isBaseCreditNoteDrawerOpen) {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [isBaseCreditNoteDrawerOpen]);

  const createMutation = useCreatePayment(onClose);

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      if (!creditNote?.id) {
        const data = transformForRequest(form.getFieldsValue());

        await createMutation.mutateAsync(data);
        await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <StyledDrawer
        open={isOpen}
        onClose={onClose}
        width={480}
        title="Credit note creation"
        closable={false}
        extra={
          <HeaderButtons
            onCancel={onClose}
            onSubmit={handleSubmit}
            isLoading={createMutation.isLoading}
          />
        }>
        <ConfigProvider form={{ validateMessages: { required: 'Is required' } }}>
          {!creditNote?.correctedInvoiceId && <CreditNoteForm form={form} />}
        </ConfigProvider>
      </StyledDrawer>
    </>
  );
};
