import { getUTCDate } from 'helpers';
import { CreditNoteDocument, DocumentItem, InvoiceDocument, isInvoice } from '../columns';
import { DATE_FORMAT_SHORT } from 'consts/common';
import { ExportFormat } from '../HeaderButtons';

export const getFilename = (file: DocumentItem, counterpartyName?: string) => {
  if (isInvoice(file)) {
    const invoice = file as InvoiceDocument;
    return `${invoice.invoiceNumber || ''}_${getUTCDate(invoice.createdAt)?.format(DATE_FORMAT_SHORT)}_${counterpartyName || ''}`;
  } else {
    const creditNote = file as CreditNoteDocument;
    return `${creditNote.creditNoteNumber || ''}_${getUTCDate(creditNote.createdAt)?.format(DATE_FORMAT_SHORT)}_${counterpartyName || ''}`;
  }
};

export const getFilenameWithExtension = (
  file: DocumentItem,
  format: ExportFormat,
  counterpartyName?: string
) => {
  const extension = format === ExportFormat.Pdf ? 'pdf' : 'docx';
  const name = getFilename(file, counterpartyName);
  return [name, extension].join('.');
};
