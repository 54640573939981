import { getUTCDate } from 'helpers';
import { ContractPaymentType, PaymentStatus, TransactionType } from 'services/api/data-contracts';
import { CreditNoteWithoutInvoiceItems } from '../CreditNoteForm';

export const transformForRequest = (formData: CreditNoteWithoutInvoiceItems) => {
  const today = getUTCDate().format();

  return {
    ...formData,
    status: PaymentStatus.DONE,
    receivedDate: getUTCDate(formData.receivedDate).format(),
    plannedDate: today,
    sentDate: today,
    transactionType: TransactionType.CreditNote,
    posted: true,
    supplierId: formData.type === ContractPaymentType.Outgoing ? formData.supplierId : undefined,
    payerId: formData.type === ContractPaymentType.Incoming ? formData.payerId : undefined
  };
};
