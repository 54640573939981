import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, Flex, message, Spin, Tooltip, Typography } from 'antd';
import {
  RiFileDownloadLine as DownloadFiles,
  RiReplyAllLine as ReplyAll,
  RiReplyLine as Reply
} from 'react-icons/ri';
import { useGetMessage } from 'queries/messages/useGetMessage';
import { ShadowRenderedMessage } from './ShadowRenderedMessage';
import { useGetLinks } from 'queries/links/useGetLinks';
import { Links } from '../../../components/Links/Links';
import { useMessageStore } from 'store/messageStore';
import { TagsDropdown } from '../TagsDropdown/TagsDropdown';
import { CONTROL_SIZE } from 'consts/common';
import { red } from '@ant-design/colors';
import { ATTACHMENT_NOTIFICATION } from '../../consts';
import { useDownloadFiles } from '../downloadHooks/useDownloadFiles';
import { AttachmentActionBar } from '../AttachmentActionBar/AttachmentActionBar';
import { SIGNATURE_NAME } from 'queries/messages/consts';
import { LinkedEntityTypes } from 'services/api/data-contracts';
import { settingsStore } from 'services/settings/SettingsStore';

const { Title } = Typography;

interface StyledSpin {
  $isLoading: boolean;
}

const StyledHeader = styled(Flex)`
  width: 100%;
  margin-top: 8px;
  padding: 8px 12px;
  background-color: white;
  z-index: 10;
  box-shadow:
    0 6px 6px 0 #00000014,
    0 3px 6px -4px #0000001f;
`;

const MessageBody = styled(Flex)`
  width: 100%;
  background-color: white;
  cursor: default;
  display: flex;
  padding: 12px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSpin = styled(Spin)<StyledSpin>`
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.$isLoading ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const StyledSpan = styled.span`
  margin: 0 12px;
  color: rgba(0, 0, 0, 0.45);

  &:first-of-type {
    margin-left: 0;
  }
`;

interface IProps {
  subject: string;
  id: string;
}

export const MessageView = ({ subject, id }: IProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  const {
    data,
    isLoading: isMessageLoading,
    error,
    isError: isMessageFetchError
  } = useGetMessage(id);
  const { data: linksResponse, isFetching: areLinksLoading } = useGetLinks(
    { messageIds: id },
    !!id
  );
  const { downloadFilesOrThrowError } = useDownloadFiles(data?.data?.attachments || []);

  const { setCurrentMessage, setNewMessageMode, signatures } = useMessageStore(
    ({ setCurrentMessage, setNewMessageMode, signatures }) => ({
      setCurrentMessage,
      setNewMessageMode,
      signatures
    })
  );

  const isError = isMessageFetchError && !!error && !isMessageLoading;

  const htmlString = data?.data?.text?.html || data?.data?.text?.plain || '';
  const messageAttachments = data?.data?.attachments;

  const handleEmailAction = (signatureType: string, mode: 'reply' | 'replyAll' | 'forward') => {
    const signature = signatures.find(({ name }) => name === signatureType);
    setCurrentMessage({ ...data?.data, body: signature?.text || '' });
    setNewMessageMode(mode);
  };

  const handleReply = () => handleEmailAction(SIGNATURE_NAME.RE, 'reply');
  const handleReplyAll = () => handleEmailAction(SIGNATURE_NAME.RE, 'replyAll');
  const handleForward = () => handleEmailAction(SIGNATURE_NAME.FW, 'forward');

  const handleDownloadAttachments = async () => {
    messageApi.open({
      key: 'loading-attachments',
      type: 'loading',
      content: ATTACHMENT_NOTIFICATION.IS_LOADING_MULTIPLE_ATTACHMENTS
    });
    try {
      await downloadFilesOrThrowError();
      message.destroy('loading-attachments');
    } catch (error) {
      console.error(ATTACHMENT_NOTIFICATION.IS_ERROR_MULTIPLE_ATTACHMENTS, error);
      messageApi.error(ATTACHMENT_NOTIFICATION.IS_ERROR_MULTIPLE_ATTACHMENTS);
    }
  };

  const hasLinks = useMemo(
    () =>
      [
        ...(linksResponse?.data.flights || []),
        ...(linksResponse?.data.orders || []),
        ...(linksResponse?.data.invoices || []),
        ...(linksResponse?.data.programs || []),
        ...(linksResponse?.data.creditNotes || [])
      ].length > 0,
    [linksResponse]
  );

  return (
    <>
      {contextHolder}
      <StyledHeader vertical gap="8">
        <Flex align="start" justify="space-between">
          <Title level={5} style={{ margin: '0' }}>
            {subject}
          </Title>
          {data?.data.flags?.includes(settingsStore.getFlags().DONE) && (
            <span style={{ color: '#00000073' }}>Done</span>
          )}
        </Flex>

        <div style={{ margin: '8px 0' }}>
          {data?.data.from && (
            <>
              <StyledSpan>From:</StyledSpan>
              <span>{data?.data.from.address}</span>
            </>
          )}

          {data?.data.to && (
            <>
              <StyledSpan>To:</StyledSpan>
              <span>{data?.data.to.map((i) => i.address).join('; ')}</span>
            </>
          )}

          {data?.data.cc && (
            <>
              <StyledSpan>CC: </StyledSpan>
              <span>{data?.data.cc.map((i) => i.address).join('; ')}</span>
            </>
          )}
        </div>

        <Flex justify="space-between" align="top">
          {hasLinks ? (
            <Links
              targetEntityType={LinkedEntityTypes.Message}
              targetEntityId={id}
              linkedFlights={linksResponse?.data.flights}
              linkedOrders={linksResponse?.data.orders}
              linkedInvoices={linksResponse?.data.invoices}
              linkedPrograms={linksResponse?.data.programs}
              linkedCreditNotes={linksResponse?.data.creditNotes}
              isLoading={areLinksLoading}
            />
          ) : (
            <span />
          )}

          <Flex justify="end" gap="middle" align="center">
            <Tooltip mouseEnterDelay={1} title="Reply">
              <StyledButton
                icon={<Reply style={{ fontSize: 16 }} />}
                onClick={handleReply}
                size={CONTROL_SIZE}
                shape="circle"
                type="text"
              />
            </Tooltip>

            <Tooltip mouseEnterDelay={1} title="Reply All">
              <StyledButton
                icon={<ReplyAll style={{ fontSize: 16 }} />}
                onClick={handleReplyAll}
                size={CONTROL_SIZE}
                shape="circle"
                type="text"
              />
            </Tooltip>

            <Tooltip mouseEnterDelay={1} title="Forward">
              <StyledButton
                icon={<Reply style={{ transform: 'scaleX(-1)', fontSize: 16 }} />}
                onClick={handleForward}
                size={CONTROL_SIZE}
                shape="circle"
                type="text"
              />
            </Tooltip>

            <TagsDropdown id={id} flags={data?.data.flags} />
          </Flex>
        </Flex>
      </StyledHeader>

      {messageAttachments && (
        <Flex
          gap={12}
          style={{
            backgroundColor: 'white',
            width: '100%',
            padding: '12px',
            justifyContent: 'space-between'
          }}>
          <Flex gap={12} style={{ width: '100%' }} wrap="wrap" justify="space-between">
            {messageAttachments?.map((attachment) => (
              <AttachmentActionBar
                key={attachment.id}
                attachment={attachment}
                messageApi={messageApi}
              />
            ))}
          </Flex>
          {messageAttachments?.length > 1 && (
            <Tooltip mouseEnterDelay={1} title="Download all">
              <Button
                icon={<DownloadFiles />}
                size={CONTROL_SIZE}
                onClick={handleDownloadAttachments}
              />
            </Tooltip>
          )}
        </Flex>
      )}

      <MessageBody vertical>
        {isError && (
          <Flex>
            <Title level={5} style={{ color: red[4] }}>
              Error fetching message...
            </Title>
          </Flex>
        )}
        {isMessageLoading ? (
          <StyledSpin $isLoading={isMessageLoading} data-testid="loader" />
        ) : (
          <ShadowRenderedMessage dangerousHTML={htmlString} isLoading={isMessageLoading} />
        )}
      </MessageBody>
    </>
  );
};
