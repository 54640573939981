import React from 'react';
import { Flex, Form, Input, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { FormInstance } from 'antd/es/form';
import { InvoicingTerm, OrderStatus } from 'services/api/data-contracts';
import { getSelectOptions } from 'helpers';
import { ProgramUpdateInput } from 'queries/flightProgram';

interface IProps {
  form: FormInstance<ProgramUpdateInput>;
  program: ProgramUpdateInput;
}

export const ProgramEditForm = ({ form, program }: IProps) => {
  if (!form) {
    return null;
  }

  return (
    <Form
      form={form}
      initialValues={program}
      colon={false}
      size={CONTROL_SIZE}
      requiredMark={false}
      layout="vertical"
      autoComplete="off"
      className="styled-form">
      <Flex gap={8} style={{ width: '50%' }}>
        <Form.Item name="status" style={{ flexBasis: '50%' }}>
          <Select
            size={CONTROL_SIZE}
            options={getSelectOptions(Object.values(OrderStatus))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder="Select status"
          />
        </Form.Item>

        <Form.Item name="invoicingTerm" style={{ flexBasis: '50%' }}>
          <Select
            size={CONTROL_SIZE}
            options={getSelectOptions(Object.values(InvoicingTerm))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder="Select invoicing term"
          />
        </Form.Item>
      </Flex>

      <Flex gap={8}>
        <Form.Item label="Note" name="notes" style={{ flexGrow: 1 }}>
          <Input.TextArea size={CONTROL_SIZE} autoSize={{ minRows: 3, maxRows: 30 }} />
        </Form.Item>
      </Flex>
    </Form>
  );
};
