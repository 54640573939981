import React from 'react';
import { Radio } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { OrdersWidgetMode } from 'store/ordersDashboardStore';
import { SettlementWidgetMode } from 'store/settlementDashboardStore';

interface IProps<T> {
  defaultValue: string;
  onOptionChange: (value: T) => void;
  options: T[];
}

export const RadioGroup = <T extends OrdersWidgetMode | SettlementWidgetMode>({
  defaultValue,
  onOptionChange,
  options
}: IProps<T>) => {
  return (
    <Radio.Group
      optionType="button"
      buttonStyle="solid"
      defaultValue={defaultValue}
      onChange={(e) => {
        onOptionChange(e.target.value as T);
      }}
      size={CONTROL_SIZE}>
      {options.map((option) => (
        <Radio.Button key={option} value={option} id={`${option}-view-selector`}>
          {option}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
