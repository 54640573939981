import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { MESSAGES_QUERY_KEY, REFETCH_INTERVAL } from './consts';
import { useMessageStore } from 'store/messageStore';
import { messagesApi } from 'services/emailEngineApi';
import {
  MessageList,
  MessageListEntry,
  PageMessages,
  Type
} from 'services/emailEngineApi/data-contracts';
import { PAGE_SIZE_35 } from 'consts/common';
import { getUniqueEmails } from './helpers/getUniqueEmails';
import { settingsStore } from 'services/settings/SettingsStore';
import {
  getQueuedMessagesFromStorage,
  removeLinkedMessagesFromStorage
} from './helpers/localStorage';
import {
  InvoiceHydrated,
  InvoiceStatus,
  LinkActionTypes,
  LinkEntityItem,
  LinkedEntityTypes
} from 'services/api/data-contracts';
import { getEntityIdsFromSource } from 'queries/links/helpers/getEntityId';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';
import { invoicesApi } from 'services/api';
import { INVOICE_QUERY_KEY } from 'queries/invoice/consts';
import { buildDocumentQuery } from './helpers/outlook/buildDocumentQuery';

export const useGetOutlookMessages = (shouldMessagesUpdate: boolean) => {
  const queryClient = useQueryClient();
  const tenantId = settingsStore.getCurrentTenantId();

  const {
    setList,
    setMessagesListItemsCount,
    optimisticUpdateMessage,
    messagesFilter,
    setEmailAddressOptions,
    linkedMessagesIdsFilter,
    accountInfo,
    inboxOutlookPath,
    sentOutlookPath
  } = useMessageStore(
    ({
      setList,
      setMessagesListItemsCount,
      optimisticUpdateMessage,
      messagesFilter,
      setEmailAddressOptions,
      linkedMessagesIdsFilter,
      accountInfo,
      inboxOutlookPath,
      sentOutlookPath
    }) => ({
      setList,
      setMessagesListItemsCount,
      optimisticUpdateMessage,
      messagesFilter,
      setEmailAddressOptions,
      linkedMessagesIdsFilter,
      accountInfo,
      inboxOutlookPath,
      sentOutlookPath
    })
  );

  const mailboxConfig = settingsStore.getMailboxSettings();
  const filterQuery = buildDocumentQuery(
    messagesFilter,
    linkedMessagesIdsFilter,
    inboxOutlookPath,
    sentOutlookPath
  );

  const linksMutation = useUpdateMessageLinks();

  const requestBody = {
    search: {},
    ...filterQuery
  };

  const fetchMessages = async ({ pageParam = 0 }) => {
    const queryParams = {
      page: pageParam,
      pageSize: PAGE_SIZE_35,
      documentStore: true,
      exposeQuery: true
    };

    try {
      const response = await messagesApi.postV1AccountAccountSearch(
        mailboxConfig?.mailbox_name as string,
        requestBody,
        queryParams
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching messages:', error);
      throw error;
    }
  };

  return useInfiniteQuery<MessageList, unknown, MessageList>({
    queryKey: [
      tenantId,
      MESSAGES_QUERY_KEY,
      messagesFilter,
      accountInfo?.type,
      linkedMessagesIdsFilter,
      inboxOutlookPath,
      sentOutlookPath
    ],
    queryFn: fetchMessages,
    getNextPageParam: (lastPage, allPages) => {
      const lastFetchedPage = allPages[allPages.length - 1];
      const { page, pages } = lastFetchedPage;
      return (page as number) < (pages as number) - 1 ? (page as number) + 1 : undefined;
    },
    onSuccess: async (data) => {
      let messages: PageMessages = [];

      if (data) {
        const queued = getQueuedMessagesFromStorage();
        const arrived = data.pages
          .flatMap((page) => page.messages ?? [])
          .filter((message) => message !== undefined);

        const newMessagesToLink = arrived.filter((message) =>
          queued.some((queuedMessage) => queuedMessage.messageId === message.messageId)
        );

        const newMessagesToLinkWithParentRef = newMessagesToLink.map((message) => {
          const queuedMessage = queued.find(
            (queuedMessage) => queuedMessage.messageId === message.messageId
          );
          return {
            ...message,
            referenceId: queuedMessage?.referenceId,
            source: queuedMessage?.source
          };
        });

        const invoicesToUpdate: InvoiceHydrated[] = [];

        try {
          await Promise.all(
            /**
             * @description do not use bulk source update for referenced messages as source may differ
             */
            newMessagesToLinkWithParentRef.map(async ({ id, source }) => {
              if (id) {
                await linksMutation.mutateAsync({
                  action: LinkActionTypes.Link,
                  target: [
                    {
                      entityType: LinkedEntityTypes.Message,
                      entityId: id
                    }
                  ],
                  source: source as LinkEntityItem[]
                });

                const filterSource = getEntityIdsFromSource(source as LinkEntityItem[]);
                await queryClient.invalidateQueries({ queryKey: ['links', filterSource] });

                for (const linkedEntity of source as LinkEntityItem[]) {
                  if (linkedEntity.entityType === 'invoice' && linkedEntity.entityId) {
                    // todo: remove when PATCH update implemented
                    const invoice = await invoicesApi.getInvoiceById(
                      linkedEntity.entityId as number
                    );

                    if (invoice.data && invoice.data.status !== InvoiceStatus.SENT) {
                      invoicesToUpdate.push(invoice.data);
                    }
                  }
                }
              }
            })
          );

          if (invoicesToUpdate.length) {
            for (const invoice of invoicesToUpdate) {
              await invoicesApi.updateInvoice(invoice.id, {
                ...invoice,
                status: InvoiceStatus.SENT
              });
              await queryClient.refetchQueries([INVOICE_QUERY_KEY, invoice.id]);
            }
          }

          removeLinkedMessagesFromStorage(newMessagesToLink);

          messages = arrived.map((message) => {
            const updated = newMessagesToLink.find((m) => m.messageId === message.messageId);

            return message.id === optimisticUpdateMessage?.id
              ? (optimisticUpdateMessage as MessageListEntry)
              : updated
                ? {
                    ...updated,
                    flags: updated?.flags ? [...updated?.flags, '\\Draft'] : ['\\Draft']
                  }
                : (message as MessageListEntry);
          });
          setList(messages);
          setMessagesListItemsCount(data.pages[data.pages.length - 1]?.total);
          const emailAddresses = getUniqueEmails(messages);
          setEmailAddressOptions(emailAddresses);
        } catch (error) {
          console.log(error);
        }
      }
    },
    onError: (error) => {
      console.log(error);
    },
    enabled: accountInfo?.type === Type.Outlook && !!inboxOutlookPath && !!sentOutlookPath,
    refetchInterval: shouldMessagesUpdate ? REFETCH_INTERVAL : false,
    refetchOnWindowFocus: false
  });
};
