import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { invoiceItemsApi } from 'services/api';
import { InvoiceItemBaseInput, InvoiceItemUpdateInput } from 'services/api/data-contracts';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { INVOICE_QUERY_KEY } from '../invoice/consts';
import { SETTLEMENT_WIDGET_QUERY_KEY } from '../settlement/consts';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { MESSAGE_DURATION } from 'consts/common';
import { DashboardType } from 'pages/consts';
import { REBATE_REPORT_QUERY_KEY } from 'queries/rebate/consts';
import { useRebateReportStore } from 'store/rebateReportStore';

const updateItems = async (invoiceId: number, data: InvoiceItemUpdateInput[]) =>
  await invoiceItemsApi.updateInvoiceItems(invoiceId, data);

export const useUpdateInvoiceItems = ({
  orderNumber,
  invoiceId,
  serviceId,
  dashboardType
}: {
  orderNumber?: string;
  serviceId?: number;
  invoiceId?: number;
  dashboardType?: DashboardType;
}) => {
  const queryClient = useQueryClient();

  const { updateInvoiceItem } = useSettlementDashboardStore(({ updateInvoiceItem }) => ({
    updateInvoiceItem
  }));
  const { rebateFilter } = useRebateReportStore(({ filter }) => ({
    rebateFilter: filter
  }));

  return useMutation({
    mutationFn: async ({ id, data }: { id: number; data: InvoiceItemBaseInput[] }) =>
      updateItems(id, data),
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries([QUERY_KEY]);
        await queryClient.refetchQueries([INVOICE_QUERY_KEY, invoiceId]);

        if (orderNumber && serviceId && response.data.items.length > 0) {
          updateInvoiceItem(response.data.items, orderNumber, serviceId);
        } else {
          await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]);
        }

        dashboardType === DashboardType.Rebate &&
          (await queryClient.refetchQueries([REBATE_REPORT_QUERY_KEY, rebateFilter]));

        message.success(`${ENTITY_NAME}s have been successfully updated`, MESSAGE_DURATION);
      }
    }
  });
};
