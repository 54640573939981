/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AirportInput {
  /**
   * ICAO code of the airport
   * @minLength 4
   * @maxLength 4
   */
  icao?: string;
  /**
   * IATA code of the airport
   * @minLength 3
   * @maxLength 3
   */
  iata?: string;
  /**
   * Local code of the airport
   * @maxLength 6
   */
  localCode?: string;
  /**
   * Name of the airport
   * @maxLength 255
   */
  name: string;
  /** ID reference to the Cities table */
  cityId: number;
  /**
   * Longitude coordinate of the airport
   * @format double
   */
  longitude?: number;
  /**
   * Latitude coordinate of the airport
   * @format double
   */
  latitude?: number;
  /** Working hours of the airport */
  workingHours?: string;
}

export interface CityInput {
  /** @maxLength 255 */
  name: string;
  countryId: number;
  /**
   * @minLength 3
   * @maxLength 3
   */
  iata?: string;
  /** @format float */
  longitude?: number;
  /** @format float */
  latitude?: number;
}

export interface CountryInput {
  /** @maxLength 255 */
  name: string;
  /** @maxLength 255 */
  fullName: string;
  /**
   * @minLength 3
   * @maxLength 3
   */
  code3?: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  code2?: string;
}

export type Country = CountryInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type City = CityInput & {
  /** @format int64 */
  id: number;
  /** @maxLength 255 */
  timeZone: string;
  country?: Country;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type Airport = AirportInput & {
  /**
   * Unique identifier for the airport
   * @format int64
   */
  id: number;
  /** Code derived from ICAO or localCode */
  code: string;
  /** Automatically generated unique number with prefix "APT" */
  number: string;
  city?: City;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export interface AirportListResponse {
  items: Airport[];
  /** @format int64 */
  total: number;
}

export interface Error {
  /**
   * Error code
   * @example "DatabaseValidationError"
   */
  code: string;
  /**
   * Human readable description of error
   * @example "Key (\"shortName\")=(TN) already exists."
   */
  message: string;
  /** Used to rapidly identify and trace distributed request processing */
  correlationId: string;
}

export type ValidationError = Error & {
  /** Array of error objects each related to specific field */
  errors?: {
    /**
     * Array containing path to the field with issue
     * @example ["countryId"]
     */
    path: string[];
    /**
     * Error message related to current field
     * @example "Expected number, received string"
     */
    message: string;
  }[];
};

export interface CityListResponse {
  items: City[];
  /** @format int64 */
  total: number;
}

export interface CountryListResponse {
  items: Country[];
  /** @format int64 */
  total: number;
}

export interface AircraftInput {
  /**
   * @format string
   * @maxLength 255
   */
  manufacturer: string;
  /** @format int64 */
  maxTakeoffWeight: number;
  /** @maxLength 255 */
  modification: string;
  /** @maxLength 255 */
  serialNumber: string;
  /** @maxLength 12 */
  tailNumber: string;
  /** @maxLength 7 */
  type: string;
  countryId?: number;
  operatorId?: number;
}

export interface CounterpartyInput {
  isActive?: boolean;
  isOperator?: boolean;
  /** @maxLength 255 */
  name: string;
  icao?: string;
  iata?: string;
  /** @maxLength 255 */
  fullName: string;
  /** @maxLength 255 */
  legalForm?: string;
  /** @maxLength 255 */
  legalAddress?: string;
  /** @maxLength 255 */
  actualAddress?: string;
  opsNotes?: string;
  settlementNotes?: string;
  /** @maxLength 255 */
  contract?: string;
  countryId?: number;
  /** @maxLength 64 */
  registrationNumber?: string;
  /** @maxLength 64 */
  vatNumber?: string;
}

export type Counterparty = CounterpartyInput & {
  /** @format int64 */
  id: number;
  isSelf?: boolean;
  country?: Country;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  linkedTenantId?: string | null;
  linkedTenant?: {
    id?: number;
    name?: string;
  } | null;
};

export type Aircraft = AircraftInput & {
  /** @format int64 */
  id: number;
  country?: Country;
  operator?: Counterparty;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export interface AircraftListResponse {
  items: Aircraft[];
}

export enum OrderStatus {
  ESTD = 'ESTD',
  PRCG = 'PRCG',
  CNFD = 'CNFD',
  DONE = 'DONE',
  RINV = 'RINV',
  INVD = 'INVD',
  CNLD = 'CNLD',
  PART = 'PART'
}

export enum OrderType {
  GH = 'GH',
  FUEL = 'FUEL',
  PMT = 'PMT',
  NAV = 'NAV',
  ADD = 'ADD'
}

export interface BaseOrder {
  /** @format int64 */
  id: number;
  number: string;
  status: OrderStatus;
  type: OrderType;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export type AirportWithFullName = Airport & {
  /** Fullname of airport */
  searchName: string;
};

export type CountryWithFullName = Country & {
  /** Fullname of country */
  searchName: string;
};

export type CityWithFullName = City & {
  /** Fullname of city */
  searchName: string;
};

export enum FlightStatus {
  ESTD = 'ESTD',
  PLND = 'PLND',
  AIR = 'AIR',
  DONE = 'DONE',
  CNLD = 'CNLD'
}

export interface FlightInput {
  status: FlightStatus;
  operatorId?: number;
  flightNumber: string;
  aircraftId: number;
  departureAirportId: number;
  /** @format date-time */
  estimatedTimeDeparture: string;
  /** @format date-time */
  actualTimeDeparture?: string;
  arrivalAirportId: number;
  /** @format date-time */
  estimatedTimeArrival: string;
  /** @format date-time */
  actualTimeArrival?: string;
  route?: string;
  alternativeAirportIds?: number[];
  overflightCountryIds?: number[];
  flightTypeId?: number;
  flightPurposeId?: number;
  crew?: string;
  load?: string;
  pax?: string;
}

export type BaseFlight = FlightInput & {
  /** @format int64 */
  id: number;
  number: string;
  /** @format int32 */
  version: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type BaseFlightWithAirportCodes = BaseFlight & {
  arrivalAirport: {
    /** @format int64 */
    id: number;
    code: string;
  };
  departureAirport: {
    /** @format int64 */
    id: number;
    code: string;
  };
};

export type Order = BaseOrder & {
  customer: Counterparty;
  payer?: Counterparty;
  location: AirportWithFullName | CountryWithFullName | CityWithFullName;
  flightIds: number[];
  aircraftTailNumber: string;
  arrivalFlight?: BaseFlightWithAirportCodes;
  departureFlight?: BaseFlightWithAirportCodes;
  flights?: BaseFlightWithAirportCodes[];
  /** @format int64 */
  programId?: number;
  isMainInProgram?: boolean;
  operatorId?: number;
};

export interface GroundHandlingOrderInput {
  customerId: number;
  payerId: number;
  locationId: number;
  isAlternateAirport?: boolean;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  aircraftId: number;
  operatorId: number;
  arrivalFlightId?: number;
  departureFlightId?: number;
  status: OrderStatus;
}

export interface FlightTypeInput {
  isActive?: boolean;
  /** @maxLength 255 */
  name: string;
}

export type FlightType = FlightTypeInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export interface FlightPurposeInput {
  isActive?: boolean;
  /** @maxLength 255 */
  name: string;
}

export type FlightPurpose = FlightPurposeInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type Flight = FlightInput & {
  /** @format int64 */
  id: number;
  number: string;
  /** @format int32 */
  version: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  flightType?: FlightType;
  flightPurpose?: FlightPurpose;
  operator?: Counterparty;
  aircraft?: Aircraft;
  arrivalAirport?: Airport;
  departureAirport?: Airport;
  alternativeAirports?: Airport[];
  overflightCountries?: Country[];
};

export type GroundHandlingOrder = GroundHandlingOrderInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format int32 */
  version: number;
  number: string;
  arrivalFlight?: Flight;
  departureFlight?: Flight;
  customer?: Counterparty;
  payer?: Counterparty;
  operator?: Counterparty;
  aircraft?: Aircraft;
  location?: Airport;
  /** @format int64 */
  programId?: number;
  isMainInProgram?: boolean;
};

export interface GroundHandlingOrderListResponse {
  items: GroundHandlingOrder[];
}

export interface FuelOrderInput {
  customerId: number;
  payerId: number;
  locationId: number;
  isAlternateAirport?: boolean;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  aircraftId: number;
  operatorId: number;
  arrivalFlightId?: number;
  departureFlightId?: number;
  status: OrderStatus;
}

export type FuelOrder = FuelOrderInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format int32 */
  version: number;
  number: string;
  customer: Counterparty;
  payer: Counterparty;
  arrivalFlight?: Flight;
  departureFlight?: Flight;
  operator?: Counterparty;
  aircraft: Aircraft;
  location?: Airport;
  /** @format int64 */
  programId?: number;
  isMainInProgram?: boolean;
};

export interface FuelOrderListResponse {
  items: FuelOrder[];
}

/** The types of locations available */
export enum LocationType {
  Airport = 'airport',
  City = 'city',
  Country = 'country',
  Everywhere = 'everywhere'
}

export interface AdditionalServicesOrderInput {
  customerId: number;
  payerId: number;
  locationId: number;
  /** The types of locations available */
  locationType: LocationType;
  isAlternateAirport?: boolean;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  aircraftId?: number;
  operatorId?: number;
  arrivalFlightId?: number;
  departureFlightId?: number;
  status: OrderStatus;
}

export type AdditionalServicesOrder = AdditionalServicesOrderInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format int32 */
  version: number;
  number: string;
  customer: Counterparty;
  payer: Counterparty;
  aircraft?: Aircraft;
  arrivalFlight?: Flight;
  departureFlight?: Flight;
  operator?: Counterparty;
  location?: Airport | Country | City;
  /** @format int64 */
  programId?: number;
  isMainInProgram?: boolean;
};

export interface AdditionalServicesOrderListResponse {
  items: AdditionalServicesOrder[];
}

export enum PermitType {
  OVERFLIGHT = 'OVERFLIGHT',
  LANDING = 'LANDING'
}

export enum ValidityUnits {
  Hrs = 'hrs',
  Days = 'days'
}

export interface PermitOrderInput {
  status: OrderStatus;
  customerId: number;
  payerId: number;
  operatorId: number;
  /** @maxLength 255 */
  operatorCountry?: string;
  aircraftId: number;
  flightIds?: number[];
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  locationId: number;
  permitType?: PermitType;
  /** @maxLength 255 */
  permitNumber?: string;
  validity?: string;
  validityUnit?: ValidityUnits;
  /** @format date-time */
  validFrom?: string;
  /** @format date-time */
  validTill?: string;
  route?: string;
}

export type PermitOrder = PermitOrderInput & {
  /** @format int64 */
  id: number;
  number: string;
  /** @format int32 */
  version: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  flights?: Flight[];
  aircraft?: Aircraft;
  customer?: Counterparty;
  payer?: Counterparty;
  operator?: Counterparty;
  location?: Country;
  /** @format int64 */
  programId?: number;
  isMainInProgram?: boolean;
};

export interface PermitOrderListResponse {
  items: PermitOrder[];
}

/** Customer communication status */
export enum CustomerStatus {
  PRE = 'PRE',
  NN = 'NN',
  NRQS = 'N.RQS',
  CUST = 'CUST',
  RQSR = 'RQS.R',
  AINF = 'A.INF',
  CHG = 'CHG',
  CNLR = 'CNL.R',
  CNFD = 'CNFD',
  DONE = 'DONE',
  SCND = 'S.CND',
  MIST = 'MIST',
  INVD = 'INVD',
  NFI = 'NFI',
  IAS = 'IAS'
}

/** @format json */
export type AdditionalData = {
  /**
   * The density value.
   * @format float
   */
  density?: number;
} | null;

/** Supplier communication status */
export enum SupplierStatus {
  PRE = 'PRE',
  NRQS = 'N.RQS',
  RQSS = 'RQS.S',
  AINF = 'A.INF',
  RJCT = 'RJCT',
  CNFD = 'CNFD',
  CNLS = 'CNL.S',
  INVR = 'INV.R',
  NFI = 'NFI',
  IAS = 'IAS'
}

/** Type of the payment */
export enum PaymentType {
  PREPN = 'PREP.N',
  PREPD = 'PREP.D',
  PREPP = 'PREP.P',
  CREDIT = 'CREDIT',
  NN = 'NN'
}

export interface OrderServiceInput {
  /** Customer communication status */
  customerStatus?: CustomerStatus;
  /** @default false */
  isOwnService?: boolean;
  additionalData?: AdditionalData;
  /** @default 0 */
  quantity?: number;
  serviceId: number;
  supplierId?: number;
  providerId?: number;
  /** Supplier communication status */
  supplierStatus?: SupplierStatus;
  /** Type of the payment */
  paymentType: PaymentType;
  notes?: string;
  /**
   * @format float
   * @default 0
   */
  forecastSalesAmount?: number;
  /**
   * @format float
   * @default 0
   */
  forecastCostAmount?: number;
  forecastSalesCurrency?: string;
  price?: number;
  currency?: string;
}

export interface ServiceInput {
  /** @maxLength 255 */
  name: string;
  /** The types of locations available */
  locationType: LocationType;
  isActive?: boolean;
  isDefault?: boolean;
  orderTypes: OrderType[];
  /** @format int64 */
  serviceNameId: number;
}

export interface ServiceNameInput {
  /** @maxLength 255 */
  name: string;
  notes?: string;
  unitOfMeasureId: number;
  isActive: boolean;
}

export interface UnitOfMeasureInput {
  /** @maxLength 255 */
  name: string;
  /** @maxLength 255 */
  shortName: string;
  notes?: string;
  isActive: boolean;
}

export type UnitOfMeasure = UnitOfMeasureInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type ServiceName = ServiceNameInput & {
  /** @format int64 */
  id: number;
  unitOfMeasure?: UnitOfMeasure;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type Service = ServiceInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  serviceName: ServiceName;
};

export type OrderService = OrderServiceInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format float */
  forecastProfit?: number;
  /** @format float */
  salesAmount?: number;
  salesCurrency?: string;
  /** @format float */
  costAmount?: number;
  costCurrency?: string;
  /** @format float */
  costInSalesCurrency?: number;
  profit?: number;
  /** @format date-time */
  updatedAt: string;
  service: Service;
  supplier?: Counterparty;
  provider?: Counterparty;
  order: BaseOrder & {
    customer?: Counterparty;
    payer?: Counterparty;
    location?: AirportWithFullName | CountryWithFullName | CityWithFullName;
    flightIds?: number[];
  };
};

export interface OrderServicesByOrderListResponse {
  items: OrderService[];
}

export interface OrderServicesListResponse {
  items: (BaseOrder & {
    aircraftTailNumber: string | null;
    customer: Counterparty;
    payer: Counterparty;
    location: AirportWithFullName | CountryWithFullName | CityWithFullName;
    orderServices: (OrderService & {
      isAvailableToLink?: boolean;
    })[];
  })[];
  total: number;
}

export interface OrderServicesWidgetResponse {
  items: {
    type: OrderType;
    /** @format date-time */
    startDatetime: string;
    /** @format date-time */
    endDatetime: string;
    location: AirportWithFullName | CountryWithFullName | CityWithFullName;
    orderServices: OrderService[];
  }[];
}

export enum ContractType {
  WithCustomer = 'With Customer',
  WithSupplier = 'With Supplier'
}

export interface CounterpartyListResponse {
  items: Counterparty[];
}

export enum RecipientTagType {
  OPSAddress = 'OPS address',
  BillingAddress = 'Billing address'
}

export interface ContactInput {
  emailAddress: string;
  contactName: string;
  recipientTag: RecipientTagType[];
}

export type Contact = ContactInput & {
  /** @format int64 */
  id: number;
  /** @format int64 */
  counterpartyId: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export interface ContactListResponse {
  items: Contact[];
}

export enum PaymentTerm {
  Prepayment = 'Prepayment',
  Postpayment = 'Postpayment'
}

export enum DueDateCondition {
  InvoiceDate = 'Invoice date',
  ReceivingSendingDate = 'Receiving/sending date'
}

export interface ContractInput {
  type: ContractType;
  /** @maxLength 255 */
  number?: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate?: string;
  /** Agent fee %. Used for type = withCustomer. */
  agentFee?: number | null;
  customerId: number;
  supplierId: number;
  currencies: string[];
  description: string;
  serviceIds?: string[];
  paymentTerm: PaymentTerm;
  /** Required if paymentTerm is "Postpayment" */
  dueDateCount?: number;
  dueDateCondition?: DueDateCondition;
}

export type Contract = ContractInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer?: Counterparty;
  supplier?: Counterparty;
};

export interface ContractListResponse {
  items: Contract[];
}

export interface SupplierServiceInput {
  serviceId: number;
  locationIds?: number[];
  priority: number;
}

export type SupplierService = SupplierServiceInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  service?: Service;
  locations?: (Airport | Country | City)[];
  /** @format int64 */
  contractId: number;
};

export interface SupplierServiceListResponse {
  items: SupplierService[];
}

export interface FlightListResponse {
  items: Flight[];
}

export type FlightWidgetItem = {
  /** @format int64 */
  id: number;
  status: FlightStatus;
  flightNumber: string;
  /** @format date-time */
  estimatedTimeArrival: string;
  /** @format date-time */
  estimatedTimeDeparture: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  arrivalAirport: Airport;
  departureAirport: Airport;
  aircraft: Aircraft;
  operatorId: number;
};

export interface FlightPurposeListResponse {
  items: FlightPurpose[];
}

export interface FlightTypeListResponse {
  items: FlightType[];
}

export interface ServiceListResponse {
  items: Service[];
}

export interface ServiceNameListResponse {
  items: ServiceName[];
}

export interface UnitOfMeasureListResponse {
  items: UnitOfMeasure[];
}

export interface VatRateInput {
  /** @maxLength 255 */
  name: string;
  /** @default 0 */
  rate?: number;
  notes?: string;
  isActive: boolean;
}

export type VatRate = UtilRequiredKeys<VatRateInput, 'rate'> & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export interface VatRateListResponse {
  items: VatRate[];
}

export interface InvoiceItemBaseInput {
  /** @format int64 */
  invoiceId: number;
  /** @format int64 */
  serviceNameId: number;
  /** @format int64 */
  unitOfMeasureId: number;
  /** @format int64 */
  outNameId?: number;
  /** @format int64 */
  ownPriceId?: number;
  /** @format int64 */
  rebateId?: number;
  isOwnPrice?: boolean;
  /** @default 1 */
  quantity: number;
  price: number;
  amount?: number;
  /** @format int64 */
  vatRateId?: number;
  /** @format int64 */
  orderServiceId?: number;
  notes?: string;
  extra?: number;
  /** @format date-time */
  rateDate?: string;
  /** Agent fee %. Used for type = withCustomer. */
  agentFee?: number | null;
  /** @format int64 */
  sortOrder?: number;
}

export type InvoiceItem = UtilRequiredKeys<InvoiceItemBaseInput, 'amount'> & {
  /** @format int64 */
  id: number;
  /** @format int64 */
  linkId?: number;
  amount: number;
  vatAmount: number;
  total: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  rolledUpTotal?: number;
  rolledUpTotalInCurrency?: number;
  extraAmountInCurrency?: number;
  rolledUpTotalExtraInCurrency?: number;
  /**
   * Agent Fee Amount
   * @format float
   */
  agentFeeAmount?: number | null;
  /**
   * Rebate Amount without VAT
   * @format float
   */
  rebateAmount?: number | null;
  /**
   * Rebate Amount taking VAT into account
   * @format float
   */
  rebateTotal?: number | null;
};

/** Type of the rebate */
export enum RebateType {
  InvoiceItemExtra = 'InvoiceItemExtra',
  InvoiceItemDiscount = 'InvoiceItemDiscount',
  AgentFeeExtra = 'AgentFeeExtra',
  AgentFeeDiscount = 'AgentFeeDiscount'
}

export enum SpecialParameterAlias {
  Order = 'Order',
  Aircraft = 'Aircraft',
  Location = 'Location',
  Customer = 'Customer',
  Contract = 'Contract',
  FlightType = 'Flight Type',
  FlightPurpose = 'Flight Purpose',
  Operator = 'Operator'
}

export interface SpecialParameterInput {
  alias: SpecialParameterAlias;
  entityId: number;
  notes?: string | null;
}

export interface RebateInput {
  /**
   * @format string
   * @maxLength 255
   */
  name: string;
  /** Type of the rebate */
  type: RebateType;
  /** @format int64 */
  customerId: number;
  /** @format int64 */
  serviceNameId: number;
  isActive: boolean;
  /** Special parameters for the rebate. Special parameters that can be added: Location, Aircraft and Operator */
  specialParameters?: SpecialParameterInput[];
  value: number;
  /** @format int64 */
  unitOfMeasureId?: number;
  currency?: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate?: string;
  notes?: string;
}

export type Rebate = RebateInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type InvoiceItemHydrated = InvoiceItem & {
  linkedInvoiceId?: number;
  serviceName: ServiceName;
  outName?: ServiceName;
  unitOfMeasure: UnitOfMeasure;
  vatRate?: VatRate;
  orderService?: OrderService;
  rebate?: Rebate & {
    unitOfMeasure?: UnitOfMeasure;
  };
};

export interface InvoiceItemHydratedListResponse {
  items: InvoiceItemHydrated[];
}

export type InvoiceItemUpdateInput = InvoiceItemBaseInput & {
  /** @format int64 */
  id?: number;
};

export interface InvoiceItemFromServiceInput {
  /** @format int64 */
  invoiceId: number;
  /** @format int64 */
  orderServiceId: number;
}

export type InvoiceItemHydratedWithRolledUp = InvoiceItemHydrated & {
  rolledUpInvoiceCurrency?: string | null;
  rolledUpItems?: InvoiceItemHydrated[];
};

export enum InvoiceType {
  Issued = 'Issued',
  Received = 'Received'
}

export enum InvoiceStatus {
  RFSN = 'RFSN',
  SENT = 'SENT',
  CNLD = 'CNLD',
  RINV = 'RINV'
}

export interface InvoiceInput {
  type: InvoiceType;
  /** @maxLength 255 */
  invoiceNumber?: string;
  status?: InvoiceStatus | null;
  isPosted?: boolean;
  isDisputed?: boolean;
  /** @format date-time */
  invoiceDate: string;
  /** @format date-time */
  supplyDate?: string;
  /** @format date-time */
  receivingOrSendingDate: string;
  /** @format date-time */
  dueDate: string;
  supplierId?: number;
  payerId?: number;
  contractId: number;
  currency: string;
  notes?: string;
  orderServiceIds?: number[];
}

export type Invoice = InvoiceInput & {
  /** @format int64 */
  id: number;
  invoiceTotal: number;
  overdueDays: number;
  amountDue: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type InvoiceHydrated = Invoice & {
  supplier: Counterparty;
  payer: Counterparty;
  contract: Contract;
  invoiceItems: InvoiceItemHydrated[];
  orderServices: OrderService[];
};

export interface InvoiceHydratedListResponse {
  items: InvoiceHydrated[];
}

export type InvoiceListResponse = Invoice[];

export interface InvoiceTemplatesListResponse {
  items: {
    id?: string;
    name?: string;
  }[];
}

export enum MainReportFormat {
  Docx = 'docx',
  Xlsx = 'xlsx'
}

export interface InvoiceReportsListResponse {
  items: {
    fileId: string;
    /** bytes */
    fileSize: number;
    isUserFile: boolean;
    invoiceId: number;
    filename: string;
    invoiceNumber: string;
    currency: string;
    total: number;
    /** @format date-time */
    createdAt: string;
  }[];
}

export interface UploadFileRequestBody {
  relatedId?: number;
  filename?: string;
  counterpartyId?: number;
  entityName?: 'Invoices' | 'CreditNotes' | 'Payments';
}

export interface UploadFileResponse {
  headers?: {
    Authorization?: string;
    [key: string]: any;
  };
  fileId?: string;
  filename?: string;
  url?: string;
}

export interface InvoicesReportsListResponse {
  items: (
    | {
        fileId: string;
        /** bytes */
        fileSize: number;
        isUserFile: boolean;
        invoiceId: number;
        filename: string;
        invoiceNumber: string;
        currency: string;
        total: number;
        /** @format date-time */
        createdAt: string;
      }
    | {
        fileId: string;
        /** bytes */
        fileSize: number;
        isUserFile: boolean;
        creditNoteId: number;
        creditNoteNumber: string;
        currency: string;
        amount?: number;
        /** @format date-time */
        createdAt: string;
      }
  )[];
}

export enum ContractPaymentType {
  Outgoing = 'Outgoing',
  Incoming = 'Incoming'
}

export enum PaymentStatus {
  PLAN = 'PLAN',
  TRAN = 'TRAN',
  DONE = 'DONE'
}

export enum CreditNoteStatus {
  RFSN = 'RFSN',
  SENT = 'SENT'
}

/** Payment transaction type */
export enum TransactionType {
  Payment = 'Payment',
  CreditNote = 'CreditNote',
  Rebate = 'Rebate'
}

export interface PaymentInput {
  type?: ContractPaymentType;
  status: PaymentStatus;
  creditNoteStatus?: CreditNoteStatus;
  posted?: boolean;
  /** @format date-time */
  plannedDate?: string | null;
  /** @format date-time */
  sentDate?: string | null;
  /** @format date-time */
  receivedDate?: string | null;
  /** @format int64 */
  payerId?: number;
  /** @maxLength 255 */
  payerBankAccount?: string | null;
  /** @format int64 */
  supplierId?: number;
  /** @maxLength 255 */
  supplierBankAccount?: string | null;
  /** @maxLength 255 */
  currency: string;
  /** @format double */
  amount: number;
  notes?: string | null;
  /** Payment transaction type */
  transactionType: TransactionType;
  /** @format int64 */
  correctedInvoiceId?: number;
  /**
   * Can be required depending on payment type
   * @format int64
   */
  contractId?: number;
  /** @format double */
  correction?: number;
}

export type Payment = PaymentInput & {
  /** @format int64 */
  id: number;
  creditNoteNumber?: string;
  /** @format date-time */
  date?: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export interface CreditNoteItemInput {
  /** @format int64 */
  creditNoteId?: number;
  /** @format int64 */
  invoiceItemId: number;
  /** @format double */
  quantity: number;
  /** @format double */
  price: number;
  /** @format double */
  amount: number;
  notes?: string | null;
}

export type CreditNoteItem = CreditNoteItemInput & {
  /** @format int64 */
  id: number;
  /** @format double */
  totalAmount: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type CreditNoteItemHydrated = CreditNoteItem & {
  invoiceItem: InvoiceItem & {
    serviceName: ServiceName;
    unitOfMeasure: UnitOfMeasure;
    invoice?: {
      /** @format int64 */
      id: number;
      invoiceNumber: string;
      isPosted: boolean;
      /** @format date-time */
      createdAt: string;
      currency: string;
    };
  };
};

export type PaymentHydrated = Payment & {
  supplier: Counterparty;
  payer: Counterparty;
  correctedInvoice?: Invoice;
  creditNoteItems?: CreditNoteItemHydrated[];
  contract?: Contract;
  /** @format double */
  advance: number;
};

export interface PaymentHydratedListResponse {
  items: PaymentHydrated[];
  /** @format int64 */
  total: number;
}

export type CreditNoteInput = PaymentInput & {
  items?: CreditNoteItemInput[];
};

export type RebateCreditNoteItemInput = CreditNoteItemInput & {
  /** @format double */
  totalAmount: number;
};

export type RebateCreditNoteInput = PaymentInput & {
  items: RebateCreditNoteItemInput[];
};

export interface PaymentPurposeInput {
  /**
   * @format int32
   * @min 1
   * @max 1000000
   */
  paymentId: number;
  /**
   * @format int32
   * @min 1
   * @max 1000000
   */
  invoiceId: number;
  /** @format double */
  paidAmount: number;
  /** @format double */
  paidAmountInInvoiceCurrency?: number | null;
}

export type PaymentPurpose = PaymentPurposeInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type PaymentFullyHydrated = PaymentHydrated & {
  paymentPurposes: (PaymentPurpose & {
    invoice?: Invoice & {
      contract?: {
        id?: number;
        number?: string;
      };
    };
  })[];
};

export interface PaymentReportsListResponse {
  items: {
    fileId: string;
    /** bytes */
    fileSize: number;
    isUserFile: boolean;
    creditNoteId: number;
    filename: string;
    creditNoteNumber: string;
    currency: string;
    amount: number;
    /** @format date-time */
    createdAt: string;
  }[];
}

export enum ReportEntityNames {
  CreditNotes = 'CreditNotes',
  Payments = 'Payments',
  RebateCreditNotes = 'RebateCreditNotes'
}

export interface PaymentTemplatesListResponse {
  items: {
    id?: string;
    name?: string;
  }[];
}

export interface Location {
  /** @format int64 */
  id: number;
  /** The types of locations available */
  type: LocationType;
  name: string;
  /** @format int64 */
  number: number;
}

export interface LinkedOrder {
  id: number;
  number: string;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  orderType: OrderType;
  location: Airport | Country | City;
  messageIds?: string[];
  flightIds?: number[];
}

export interface LinkedFlight {
  id: number;
  number: string;
  flightNumber: string;
  messageIds?: string[];
  orderNumbers?: string[];
}

export interface LinkedInvoice {
  id: number;
  type: string;
  invoiceNumber: string;
  /** @format date-time */
  invoiceDate: string;
  /** @format date-time */
  receivingOrSendingDate: string;
  /** @format date-time */
  dueDate: string;
  messageIds?: string[];
}

export interface LinkedProgram {
  id: number;
  status: string;
  programNumber: string;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  messageIds?: string[];
}

export interface LinkedMessage {
  messageId: string;
  orderNumbers?: string[];
  flightIds?: number[];
  invoiceIds?: number[];
  programIds?: number[];
}

export interface LinksResponse {
  orders?: LinkedOrder[];
  flights?: LinkedFlight[];
  invoices?: LinkedInvoice[];
  programs?: LinkedProgram[];
  creditNotes?: Payment[];
  rebates?: Rebate[];
  messages?: LinkedMessage[];
}

export enum LinkActionTypes {
  Link = 'link',
  Unlink = 'unlink',
  UnlinkAll = 'unlinkAll'
}

/** The types of entities that can be linked to each other */
export enum LinkedEntityTypes {
  Flight = 'flight',
  Order = 'order',
  Invoice = 'invoice',
  Message = 'message',
  Program = 'program',
  CreditNote = 'creditNote',
  Rebate = 'rebate'
}

/** Id of entity. In case of order, this field should provide order number */
export type EntityId = string | number;

export interface LinkEntityItem {
  /** The types of entities that can be linked to each other */
  entityType?: LinkedEntityTypes;
  /** Id of entity. In case of order, this field should provide order number */
  entityId?: EntityId;
}

export interface LinksRequestBody {
  action: LinkActionTypes;
  /** @minItems 1 */
  target: LinkEntityItem[];
  source?: LinkEntityItem[];
}

/** @example {"message1":2,"message2":3} */
export type UpdateLinksResponse = Record<string, number>;

export interface CurrencyRateInput {
  /** @format date-time */
  date: string;
  sourceCurrency: string;
  /** @example {"USD":1.1,"AED":0.25} */
  exchangeRates: Record<string, number>;
}

export type CurrencyRate = UtilRequiredKeys<CurrencyRateInput, 'sourceCurrency'> & {
  sourceCurrency: string;
};

export interface CurrencyRateListResponse {
  items: CurrencyRate[];
  /** @format int64 */
  total: number;
}

export type PaymentPurposeHydrated = PaymentPurpose & {
  payment: Payment & {
    /** @format double */
    advance: number;
  };
  invoice: Invoice;
};

export interface PaymentPurposeListResponse {
  items: PaymentPurposeHydrated[];
  /** @format int64 */
  total: number;
}

/** @minItems 1 */
export type PaymentPurposeUpdateInput = (PaymentPurposeInput & {
  /** @format int64 */
  id?: number;
})[];

export enum InvoicingTerm {
  PERMONTH = 'PERMONTH',
  PERDECADE = 'PERDECADE',
  POSTFUELING = 'POSTFUELING'
}

export interface Program {
  /** @format int64 */
  id: number;
  invoicingTerm?: InvoicingTerm;
  programNumber: string;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  /** @format date-time */
  createdAt: string;
  /** @format float */
  salesAmount: number;
  /** @format float */
  costAmount: number;
  /** Difference between sale and cost */
  profit: number;
  /** @format date-time */
  updatedAt: string;
  notes?: string | null;
  status?: OrderStatus;
}

export type TotalSales = {
  currency: string;
  sale: number;
  cost: number;
  profit: number;
}[];

export interface ProgramInput {
  invoicingTerm?: InvoicingTerm;
  status: OrderStatus;
  orderNumber: string;
  notes: string | null;
}

export interface ProgramCloneInput {
  programId: number;
  dates: string[];
}

export interface ProgramById {
  /** @format int64 */
  id: number;
  programNumber: string;
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  /** @format date-time */
  createdAt: string;
  /** @format float */
  salesAmount: number;
  /** @format float */
  costAmount: number;
  /** Difference between sale and cost */
  profit: number;
  /** @format date-time */
  updatedAt: string;
  mainOrder: Order & {
    operator: Counterparty;
    orderServices: (OrderService & {
      isAvailableToLink?: boolean;
    })[];
  };
  nearestOrder: Order & {
    orderServices: (OrderService & {
      isAvailableToLink?: boolean;
    })[];
  };
  notes?: string | null;
  status?: OrderStatus;
}

export interface BulkProgramOrderServices {
  serviceIds: number[];
  dataToUpdate?: {
    /** Customer communication status */
    customerStatus?: CustomerStatus;
    isOwnService?: boolean;
    supplierId?: number;
    /** Supplier communication status */
    supplierStatus?: SupplierStatus;
    /** Type of the payment */
    paymentType?: PaymentType;
    providerId?: number;
  };
}

export interface OwnPriceInput {
  /**
   * @format string
   * @maxLength 255
   */
  name: string;
  /** @format int64 */
  serviceNameId: number;
  isSpecialPrice?: boolean;
  specialParameters?: SpecialParameterInput[];
  price: number;
  /** @format int64 */
  unitOfMeasureId: number;
  currency: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate?: string;
  notes?: string;
}

export type OwnPrice = OwnPriceInput & {
  /** @format int64 */
  id: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export enum SpecialParameterNames {
  GroundHandlingOrder = 'ground handling order',
  FuelOrder = 'fuel order',
  PermitOrder = 'permit order',
  AdditionalServicesOrder = 'additional services order',
  Aircraft = 'aircraft',
  City = 'city',
  Airport = 'airport',
  Country = 'country',
  Counterparty = 'counterparty',
  Contract = 'contract',
  FlightType = 'flight type',
  FlightPurpose = 'flight purpose',
  Operator = 'operator'
}

export type SpecialParameter = SpecialParameterInput & {
  /** @format int64 */
  id: number;
  priority: number;
  entity:
    | Airport
    | Country
    | City
    | AdditionalServicesOrder
    | Aircraft
    | Contract
    | Counterparty
    | FlightPurpose
    | FlightType
    | FuelOrder
    | GroundHandlingOrder
    | PermitOrder;
  entityName: SpecialParameterNames;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
};

export type OwnPriceHydrated = OwnPrice & {
  specialParameters?: SpecialParameter[];
  serviceName: ServiceName;
  unitOfMeasure: UnitOfMeasure;
};

export interface OwnPriceListResponse {
  items: OwnPriceHydrated[];
  /** @format int64 */
  total: number;
}

export type OwnPriceHydratedExtended = OwnPriceHydrated & {
  convertedPrice?: {
    price?: number;
    uomShortName?: string;
    priceInInvoiceCurrency?: number;
  };
  priceInInvoiceCurrency?: number;
};

export type RebateHydrated = Rebate & {
  specialParameters?: SpecialParameter[];
  customer: Counterparty;
  serviceName: ServiceName;
  unitOfMeasure: UnitOfMeasure;
  priceInInvoiceCurrency?: number;
};

export interface RebateListResponse {
  items: RebateHydrated[];
  /** @format int64 */
  total: number;
}

export interface RebateReportInvoiceItem {
  /** @format int64 */
  id: number;
  /** @format int64 */
  invoiceId: number;
  /** @format int64 */
  serviceNameId?: number;
  /** @format int64 */
  rebateId: number;
  quantity: number;
  price: number;
  /**
   * Rebate Amount without VAT
   * @format float
   */
  rebateAmount: number | null;
  /**
   * Rebate Amount taking VAT into account
   * @format float
   */
  rebateTotal: number | null;
  notes?: string;
  invoice: {
    /** @format int64 */
    id: number;
    invoiceNumber: string;
    /** @format date-time */
    supplyDate: string;
  };
  serviceName?: {
    /** @format int64 */
    id: number;
    name: string;
  };
}

export interface RebateReportCurrencyGroup {
  /** @example "USD" */
  currency: string;
  total: number;
  invoiceItems: RebateReportInvoiceItem[];
}

export interface RebateReportResponse {
  items: {
    id?: number;
    name?: string;
    currencies?: RebateReportCurrencyGroup[];
  }[];
  total: number;
}

export type Balance = Record<
  string,
  {
    /** current balance for today */
    current: number;
    /** initial balance calculated from the very first document in db till the startDate */
    opening: number;
    /** balance for the last date of the selected period (endDate) */
    closing: number;
  }
>;

export interface BalanceViewBaseItem {
  /** @format double */
  balance: number;
  location?: Location & {
    /** Fullname of location */
    searchName?: string;
  };
  flights?: (BaseFlightWithAirportCodes & {
    aircraft: {
      id: number;
      tailNumber: string;
    };
  })[];
}

export type BalanceViewItems = (
  | (Invoice & BalanceViewBaseItem)
  | (Payment &
      BalanceViewBaseItem & {
        /** @format double */
        advance: number;
      })
)[];

export interface BalanceViewContract {
  balance: Balance;
  contractId?: number;
  contractNumber?: string;
  items: BalanceViewItems;
  total: number;
}

export type BalanceViewResponse = {
  balance: Balance;
  items?: BalanceViewItems;
  contracts?: BalanceViewContract[];
};

export interface UnpaidInvoicesRequestBody {
  /** @maxLength 3 */
  currency: string;
  /** @format int64 */
  contractId: number;
  /** @format int64 */
  counterpartyId: number;
  templateId: string;
  /** @default "xlsx" */
  format?: 'pdf' | 'xlsx';
}

export interface ReconciliationReportRequestBody {
  /** @maxLength 3 */
  currency: string;
  /** @format int64 */
  counterpartyId: number;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  templateId: string;
  /** @default "xlsx" */
  format?: 'pdf' | 'xlsx';
}

export enum BalanceReportName {
  UnpaidInvoicesReports = 'UnpaidInvoicesReports',
  ReconciliationReports = 'ReconciliationReports'
}

export type SettlementInvoiceItem = InvoiceItemHydrated & {
  creditNoteItems?: (CreditNoteItem & {
    payment?: Payment;
  })[];
  rebate?: {
    /** Type of the rebate */
    type: RebateType;
  };
};

export interface SettlementInvoice {
  id: number;
  currency: string;
  contractId: number;
  invoiceNumber: string;
  isPosted: boolean;
  status: InvoiceStatus;
  type: InvoiceType;
  /** @format date-time */
  invoiceDate: string;
  total: number;
  totalByOrderService: number;
  agentFeeAmount: number;
  agentFeeAmountByOrderService: number;
  creditNotes: {
    creditNote: Payment;
    messageId?: string;
    totalByOrderService: number;
  }[];
  invoiceItems: SettlementInvoiceItem[];
  messageId?: string;
}

export type OrderServiceWithInvoices = OrderService & {
  invoices?: SettlementInvoice[];
  sale: number;
  cost: number;
  /** Difference between sale and cost */
  profit: number;
};

export interface SettlementOrder {
  id: number;
  number: string;
  status: OrderStatus;
  type: OrderType;
  customer: Counterparty;
  payer: Counterparty;
  location: AirportWithFullName | CountryWithFullName | CityWithFullName;
  aircraftTailNumber: string;
  arrivalFlight?: BaseFlightWithAirportCodes;
  departureFlight?: BaseFlightWithAirportCodes;
  flights?: BaseFlightWithAirportCodes[];
  /** @format date-time */
  startDatetime: string;
  /** @format date-time */
  endDatetime: string;
  programId?: number;
  orderServices: OrderServiceWithInvoices[];
  totalSales: TotalSales;
  messageId?: string;
}

export interface MailboxSettings {
  mailbox_name: string;
  mailbox_api_url: string;
  mailbox_api_key: string;
  mailbox_flags: Record<string, string>;
}

export interface TenantSettings {
  id: string;
  name: string;
  mainCurrency: string;
  allowedCurrencies: string[];
  organizationId: number;
  /**
   * A color in HEX format
   * @pattern ^#[0-9A-Fa-f]{6}$
   * @example "#FF5733"
   */
  color?: string | null;
  widgets: {
    dispatcherMessages?: MailboxSettings;
    settlementMessages?: MailboxSettings;
  };
}

export interface ProfileResponse {
  profile: {
    settings: {
      tenants: TenantSettings[];
    };
  };
  apiVersion?: {
    gitTag: string;
    gitSha: string;
  };
  alfrescoApiVersion: string;
}
