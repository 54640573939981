import { useQuery } from '@tanstack/react-query';
import { paymentsApi } from 'services/api';
import { QUERY_KEY } from './consts';

const fetchList = async (paymentId: number) => await paymentsApi.paymentReportsList(paymentId);

export const useGetPaymentDocumentsList = (id?: number) =>
  useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchList(id);
      }
    },
    enabled: !!id
  });
